<template>
  <div class="copyright-container">
    <nuxt-img
      key="logo"
      class="footer__copyright-logo"
      :src="addBasePath('/logoW.svg')"
      :placeholder="addBasePath('/logoW.svg')"
      alt="Divo"
      height="16"
      width="120"
      :loading="`lazy`"
    />
    <div class="footer__copyright-text">
      {{ $t('Copyright Text') }}
    </div>
    <div class="footer__copyright-links">
      <SfLink
        :link="fixUrlForCurrentLocale('/privacy-policy')"
        class="footer__copyright-links__link"
      >
        {{ $t('Privacy Policy') }}
      </SfLink>
      <span class="divider desktop-only">|</span>
      <nuxt-link
        :to="fixUrlForCurrentLocale('/cookie-policy')"
        class="footer__copyright-links__link"
      >
        {{ $t('Cookie policy') }}
      </nuxt-link>
      <span class="divider desktop-only">|</span>
      <div class="footer__copyright-links__link" @click="handleCookiebotRenew">
        {{ $t('Cookie preferences') }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { SfImage, SfLink } from '@storefront-ui/vue';
import { addBasePath } from '~/helpers/addBasePath';
import { useCurrentLocale } from '~/composables/useCurrentLocale';

export default defineComponent({
  name: 'FooterCopyright',
  components: {
    SfImage,
    SfLink,
  },
  setup() {
    const { fixUrlForCurrentLocale } = useCurrentLocale();

    const handleCookiebotRenew = () => {
      if (typeof window !== 'undefined' && window.Cookiebot) {
        window.Cookiebot.renew();
      }
    };
    return { addBasePath, fixUrlForCurrentLocale, handleCookiebotRenew };
  },
});
</script>

<style lang="scss">
.copyright-container {
  @include for-mobile {
    padding: 1.5625rem;
    text-align: center;
  }
  @include for-desktop {
    @include desktop-boxed;
    margin: 0 auto;
    padding-top: 2rem;
    display: flex;
  }
  .footer__copyright {
    &-text,
    &-links > a {
      @include paragraph-extra-small;
      text-transform: uppercase;
      color: var(--c-dark-grey);
    }
    &-text {
      @include for-mobile {
        padding-bottom: 1.6875rem;
      }
      @include for-desktop {
        padding-left: 1.25rem;
      }
      width: 100%;
    }
    &-links {
      @include for-desktop {
        display: flex;
        justify-content: flex-end;
        padding-left: 1rem;
        padding-right: 9rem;
      }
      text-align: center;
      width: 100%;
      &__link {
        color: var(--c-dark-grey);
        text-transform: uppercase;
        @include pointer;
        @include paragraph-extra-small;
        @include for-mobile {
          display: block;
          margin-bottom: 1rem;
        }
      }
    }
    &-logo {
      @include for-mobile {
        padding-bottom: 1.25rem;
      }
    }
  }
  .sf-image {
    object-fit: contain;
  }
  .divider {
    color: var(--c-dark-grey);
    height: 1rem;
    padding: 0 1rem;
  }
}
</style>
