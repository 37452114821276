<template>
  <div
    :class="[
      `custom-input custom-input-${inputType}`,
      fullWidth && 'custom-input-full-width',
      disabled && 'custom-input-disabled',
    ]"
  >
    <ValidationProvider
      :rules="validateRule"
      v-slot="{ errors }"
      class="custom-input__container"
      :mode="interactionMode"
      :ref="inputId"
      :vid="inputId"
    >
      <CustomSfInput
        :id="inputId"
        :name="inputName"
        :label="`${$t(inputLabel)} ${
          validateRule && validateRule.includes('required') ? '*' : ''
        }`"
        :type="inputType"
        :has-show-password="hasShowPassword"
        :placeholder="inputPlaceholder"
        v-model="value"
        :disabled="disabled"
        class="custom-input__input"
        :class="errors[0] && 'has-errors'"
        @input="handleChange()"
      />
      <component v-if="icon" class="custom-input__icon" :is="icon" />
      <div v-if="errors[0]" class="custom-input__error">
        {{ $t(errors[0]) }}
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onMounted,
} from '@nuxtjs/composition-api';
import { ValidationProvider, extend } from 'vee-validate';
import {
  email,
  numeric,
  required,
  alpha_num,
  confirmed,
  alpha_spaces,
} from 'vee-validate/dist/rules';
import { useI18n } from '~/helpers/hooks/usei18n';
import CustomSfInput from '~/components/Override/CustomSfInput.vue';
import { useMaps } from '~/composables';
import {
  customerPasswordRegExp,
  invalidPasswordMsg,
} from '~/helpers/customer/regex';

export default defineComponent({
  name: 'CustomInput',
  components: {
    ValidationProvider,
    CustomSfInput,
  },
  model: {
    prop: 'inputValue',
  },
  props: {
    inputType: {
      type: String,
      default: 'text',
    },
    inputPlaceholder: {
      type: String,
      default: '',
    },
    inputName: {
      type: String,
      required: true,
    },
    inputValue: {
      type: [String, Object, null],
      default: null,
    },
    inputLabel: {
      type: String,
      required: false,
    },
    inputId: {
      type: String,
      required: true,
    },
    validateRule: {
      type: [Boolean, String],
      default: '',
    },
    interactionMode: {
      type: String,
      default: 'aggressive',
    },
    icon: {
      type: [Boolean, String],
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autocompleteAddress: {
      type: Boolean,
      default: false,
    },
    hasShowPassword: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const trans = useI18n();

    const value = ref(props.inputValue);
    extend('email', {
      ...email,
      message: trans.t('The email format is not correct'),
    });
    extend('alpha_num', {
      ...alpha_num,
      message: trans.t('May only contain alpha-numeric characters'),
    });
    extend('numeric', {
      ...numeric,
      message: trans.t('May only contain numeric characters'),
    });
    extend('required', {
      ...required,
      message: trans.t('Required field'),
    });
    extend('confirmed', {
      ...confirmed,
      message: trans.t('Field does not match'),
    });
    extend('password', {
      message: invalidPasswordMsg,
      validate: (value) => customerPasswordRegExp.test(value),
    });
    const { initGoogleAutocomplete, addressData, autocompleteIsAvailable } =
      useMaps();

    const handleAutocompletedAddress = () => {
      emit('autocomplete', addressData.value);
    };

    onMounted(() => {
      if (!props.autocompleteAddress) return;
      initGoogleAutocomplete(props.inputId, handleAutocompletedAddress);
    });

    const isEmail = computed(
      () =>
        (props.validateRule && props.validateRule.includes('email')) ||
        props.inputType === 'email'
    );

    const handleChange = () => {
      emit(
        'inputChange',
        value.value && isEmail.value ? value.value.toLowerCase() : value.value
      );
      emit(
        'input',
        value.value && isEmail.value ? value.value.toLowerCase() : value.value
      );
    };

    extend('min', {
      validate(v, arguments_) {
        return v.length >= arguments_.length;
      },
      params: ['length'],
      message: (_, arguments_) => {
        return trans.t(`Must be longer than {0} characters`, [
          arguments_.length,
        ]);
      },
    });
    extend('max', {
      validate(v, arguments_) {
        return v.length < arguments_.length;
      },
      params: ['length'],
      message: (_, arguments_) => {
        return trans.t(`Must be shorter than {0} characters`, [
          arguments_.length,
        ]);
      },
    });

    extend('alpha_spaces', {
      ...alpha_spaces,
      message: trans.t('May only contain alphabetic characters'),
    });

    return {
      value,
      handleChange,
      autocompleteIsAvailable,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-input {
  width: var(--input-width);
  &-full-width {
    width: 100%;
  }
  &__container {
    position: relative;
  }
  &__error {
    position: absolute;
    top: 4.3rem;
    right: 1.25rem;
    @include inline-cta;
    text-transform: uppercase;
    color: var(--c-red-error);
  }
  &-hidden {
    display: none;
  }
}
</style>

<style lang="scss">
.custom-input__input {
  .sf-input {
    &__wrapper {
      input {
        border-radius: var(--input-border-radius);
        @include paragraph-regular;
        &:focus-visible {
          outline: none;
        }
        &:focus {
          border: var(--input-border-width) solid var(--input-border-color);
        }
        &::placeholder {
          text-transform: uppercase;
        }
      }
    }
    &__label {
      position: unset;
      transform: none;
      transition: none;
      padding-left: var(--input-label-padding-left);
      padding-bottom: var(--input-label-padding-bottom);
      @include input-label;
    }
    &__error-message {
      display: none;
    }
  }
  &.has-errors {
    --input-border-color: var(--c-red-error);
  }
}
.custom-input-full-width {
  .custom-input__input.sf-input {
    width: 100%;
  }
}
</style>
