











import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { useVisitor } from '~/composables';
import { useContext, useRouter } from '@nuxtjs/composition-api';
import criteoHelper from '~/helpers/criteo';

export default defineComponent({
  name: 'BasketTracking',
  props: {
    isAuthenticated: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
    },
    cartItems: {
      type: Array,
      required: true,
    },
    trigger: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const isClient = typeof window !== 'undefined'; // Ensure this runs only on the client side
    const router = useRouter();
    const currentPath = ref(router.currentRoute.path);
    const { app } = useContext();
    const { visitorId } = useVisitor();
    const criteo = criteoHelper({
      app,
      user: props.user,
      isAuthenticated: props.isAuthenticated,
      visitorId,
    });

    const trackBasket = () => {
      const { visitorId } = useVisitor();
      const deviceType = criteo.getDeviceType();
      const data: any = {
        currency: criteo.getCurrentCurrency(),
        visitorId: visitorId.value, // Optional: visitor ID, if available
        deviceType: deviceType,
        cartItems: props.cartItems,
      };

      if (props.isAuthenticated) {
        data.customerId = criteo.encryptValue(props.user.email);
      }

      // Make sure Criteo is loaded before calling the tracking function
      if (app.$criteo !== undefined) {
        app.$criteo.basketTag(data);
      }
    };

    onMounted(() => {
      if (isClient) {
        trackBasket(); // Perform the initial tracking
      }
    });

    // Watch for changes in the trigger prop to track the basket
    watch(
      () => props.trigger,
      (newVal) => {
        if (newVal && isClient) {
          trackBasket();
        }
      }
    );

    return {
      isClient,
      currentPath,
    };
  },
});
