import Vue from 'vue'
import Zendesk from '@dansmaculotte/vue-zendesk'

const KEY = '4e0bf5da-6c2c-437a-b2b9-1e61c63f748c'
const DISABLED = false
const HIDE_ON_LOAD = false
const SETTINGS = {"webWidget":{"chat":{"connectOnPageLoad":false}}}

export default function (context) {
  Vue.use(Zendesk, {
    key: KEY,
    disabled: DISABLED,
    hideOnLoad: HIDE_ON_LOAD,
    settings: SETTINGS
  })
}
