<template>
  <div class="footer__socials">
    <div class="footer__socials__heading">{{ $t('Social') }}</div>
    <div class="footer_socials_icons">
      <SfLink link="https://www.instagram.com/divoboutique/">
        <SfImage
          class="footer__social-image"
          :placeholder="addBasePath(`/icons/socials/instagram.svg`)"
          :src="
            addBasePath(
              `/icons/socials/${
                !instagramHovered ? 'instagram' : 'instagramH'
              }.svg`
            )
          "
          @mouseover="
            {
              instagramHovered = true;
            }
          "
          @mouseleave="
            {
              instagramHovered = false;
            }
          "
          alt="instagram"
          width="20"
          height="20"
        />
      </SfLink>
      <span class="divider">|</span>
      <SfLink link="https://it-it.facebook.com/boutiquedivo/">
        <SfImage
          class="footer__social-image"
          :placeholder="addBasePath(`/icons/socials/facebook.svg`)"
          :src="
            addBasePath(
              `/icons/socials/${
                !facebookHovered ? 'facebook' : 'facebookH'
              }.svg`
            )
          "
          @mouseover="
            {
              facebookHovered = true;
            }
          "
          @mouseleave="
            {
              facebookHovered = false;
            }
          "
          alt="facebook"
          width="20"
          height="20"
        />
      </SfLink>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { SfImage } from '@storefront-ui/vue';
import SfLink from '@storefront-ui/vue/src/components/atoms/SfLink/SfLink.vue';
import { addBasePath } from '~/helpers/addBasePath';

export default defineComponent({
  name: 'Socials',
  components: {
    SfImage,
    SfLink,
  },
  setup() {
    const facebookHovered = ref(false);
    const instagramHovered = ref(false);
    const tumblrHovered = ref(false);
    return {
      facebookHovered,
      instagramHovered,
      tumblrHovered,
      addBasePath,
    };
  },
});
</script>
<style lang="scss" scoped>
.footer_socials_icons {
  display: flex;
}
.divider {
  color: var(--c-dark-grey);
  height: 1rem;
  padding: 0 1.25rem;
}
</style>
