<template>
  <div class="grouped-payments">
    <div class="payment" v-for="(title, index) in payments" :key="title">
      <nuxt-img
        :src="addBasePath(`/assets/${title}-logo.svg`)"
        class="payment-logo"
        :placeholder="addBasePath(`/assets/${title}-logo.svg`)"
        :alt="title"
        :class="`payment-logo-${title}`"
        :height="getImgHeight(title)"
        :width="getImgWidth(title)"
        loading="lazy"
      />
      <span class="divider" v-if="index != payments.length - 1"> | </span>
    </div>
  </div>
</template>

<script>
import {
  ref,
  computed,
  onMounted,
  useContext,
  defineComponent,
} from '@nuxtjs/composition-api';
import SfImage from '@storefront-ui/vue/src/components/atoms/SfImage/SfImage.vue';
import { addBasePath } from '~/helpers/addBasePath';

export default defineComponent({
  name: 'GroupedPayments',
  components: { SfImage },
  setup() {
    const isScalapayCountry = ref(false);
    const payments = computed(() =>
      ['Visa', 'Mastercard', 'Paypal', 'AmericanExpress', 'Scalapay'].filter(
        (p) => p !== 'Scalapay' || isScalapayCountry.value
      )
    );
    const {
      app: {
        $vsf: {
          $gemini: { config },
        },
      },
    } = useContext();
    const getImgWidth = (payment) => {
      switch (payment) {
        case 'Visa': {
          return 40;
        }
        case 'Mastercard': {
          return 33;
        }
        case 'Paypal': {
          return 64;
        }
        case 'AmericanExpress': {
          return 34;
        }
        default: {
          return 60;
        }
      }
    };
    const getImgHeight = (payment) => {
      switch (payment) {
        case 'Visa': {
          return 16;
        }
        case 'Paypal': {
          return 17;
        }
        case 'AmericanExpress': {
          return 23;
        }
        default: {
          return 20;
        }
      }
    };
    onMounted(() => {
      if (process && process.client && localStorage) {
        const storedCountry = localStorage.getItem('vsf-country-selected');
        if (storedCountry) {
          isScalapayCountry.value = config.scalapayCountries?.includes(
            JSON.parse(storedCountry)?.countryIso?.toUpperCase()
          );
        }
      }
    });
    return {
      payments,
      getImgWidth,
      addBasePath,
      getImgHeight,
      isScalapayCountry,
    };
  },
});
</script>

<style lang="scss">
.grouped-payments {
  display: flex;
  @include for-mobile {
    padding: 1.25rem 0;
    justify-content: center;
  }
  @include for-desktop {
    padding-top: 3rem;
    align-items: flex-end;
    height: 1rem;
  }
  .payment {
    display: inline-flex;
    @include for-mobile {
      align-items: center;
    }
    &-logo {
      img {
        object-fit: contain;
      }
    }
  }
  .divider {
    color: var(--c-dark-grey);
    height: 1rem;
    padding: 0 0.3125rem;
    @include for-desktop {
      padding: 0 0.625rem;
    }
  }
}
</style>
