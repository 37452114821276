<template>
  <div class="geolocator-modal-container">
    <SfModal
      class="geolocator-modal"
      :persistent="true"
      :visible="isGeoLocatorModalOpen"
      @close="
        () => {
          updateLocalData(true);
          toggleGeoLocatorModal();
        }
      "
    >
      <template #close>
        <CrossIcon />
      </template>
      <template #modal-bar> </template>
      <div class="geolocator-modal-content">
        <div class="geolocator-modal-body">
          <div class="geolocator-modal-text-top">
            <p>
              <span class="geolocator-modal-text__text">
                {{ $t('You seem to be connected from ') }}
              </span>
              <span class="geolocator-modal-text__text--bold">
                {{ $t(`${countryLabel}`) }}
              </span>
            </p>
            <nuxt-img
              v-if="countryFlag"
              :src="countryFlag"
              format="webp"
              width="20px"
              height="13px"
              alt="flag"
              loading="lazy"
            />
          </div>
          <p class="geolocator-modal-text__text">
            {{ $t('Do you wish to switch country?') }}
          </p>
        </div>
        <div class="geolocator-modal-bottom">
          <CustomButton
            v-if="countryCode === locale"
            @click="
              () => {
                updateLocalData(true);
                toggleGeoLocatorModal();
              }
            "
          />
          <a
            v-else
            class="geolocator-modal-cta-accept"
            :href="`/${localePathString}`"
            @click="
              () => {
                updateLocalData(true);
                toggleGeoLocatorModal();
              }
            "
          >
            {{ $t('Accept suggestion') }}
          </a>
          <CustomButton
            layout="white"
            @click="
              () => {
                updateLocalData(false);
                toggleGeoLocatorModal();
                toggleCountryModal();
              }
            "
          >
            {{ $t('Choose manually') }}
          </CustomButton>
        </div>
      </div>
    </SfModal>
  </div>
</template>

<script>
import { SfModal } from '@storefront-ui/vue';
import { useUiState } from '~/composables';
import {
  defineComponent,
  ref,
  computed,
  useContext,
  reactive,
  toRefs,
  onMounted,
} from '@nuxtjs/composition-api';
import CustomButton from '~/components/General/FormElements/CustomButton.vue';
import { CrossIcon } from '~/components/General/icons';
import { useI18n } from '~/helpers/hooks/usei18n';
import { marketToLocaleMapper } from '~/helpers/util';

export default defineComponent({
  name: 'GeoLocatorModal',
  components: { SfModal, CrossIcon, CustomButton },
  props: {
    localizedCountry: {
      type: Object,
      default: null,
    },
  },

  setup(props) {
    const { isGeoLocatorModalOpen, toggleGeoLocatorModal, toggleCountryModal } =
      useUiState();
    const { locale } = useI18n();
    const {
      app: { i18n },
    } = useContext();
    const countryData = reactive({
      countryLabel: props.localizedCountry?.label,
      countryFlag: props.localizedCountry?.flag,
      countryCode: props.localizedCountry?.code,
    });
    const VSF_COUNTRY_SELECTED = 'vsf-country-selected';
    const language = computed(() => (locale === 'it' ? 'it' : 'en'));
    const mappedMarkets = ref([]);
    const localeToMarketObject = ref({});
    const VSF_LOCALIZED_COUNTRY = 'vsf-localized-country';

    mappedMarkets.value = marketToLocaleMapper(i18n, language.value);

    /* Saves mapped markets in a js object to access them easily using only the
    locale code */
    mappedMarkets.value.forEach((market) => {
      localeToMarketObject.value[market?.countryIso] = market;
    });

    /* Sets a key if the modal was interacted with, also sets the default
    key for selected country if user closes modal */
    const updateLocalData = (setSecondKey) => {
      if (process && process.client && localStorage) {
        /* Safety check if vsf-country-selected is not set correctly or in case
        of unhandled error, interacting with modal will hide it */
        if (localStorage.getItem(VSF_LOCALIZED_COUNTRY) === null) {
          localStorage.setItem(
            VSF_LOCALIZED_COUNTRY,
            JSON.stringify({ interacted: true })
          );
        } else {
          const localData = JSON.parse(
            localStorage.getItem(VSF_LOCALIZED_COUNTRY)
          );
          localStorage.setItem(
            VSF_LOCALIZED_COUNTRY,
            JSON.stringify({ ...localData, interacted: true })
          );
        }
        if (
          setSecondKey &&
          localeToMarketObject.value?.[props.localizedCountry?.code]
        ) {
          localStorage.setItem(
            'vsf-country-selected',
            JSON.stringify(
              localeToMarketObject.value?.[props.localizedCountry?.code]
            )
          );
        }
      }
    };

    const localePathString = computed(
      () => localeToMarketObject.value?.[props.localizedCountry?.code]?.value
    );

    onMounted(async () => {
      if (
        localStorage.getItem(VSF_COUNTRY_SELECTED) === null &&
        !isGeoLocatorModalOpen.value
      ) {
        updateLocalData(true);
      }
    });

    return {
      isGeoLocatorModalOpen,
      toggleGeoLocatorModal,
      toggleCountryModal,
      ...toRefs(countryData),
      locale,
      updateLocalData,
      localeToMarketObject,
      localePathString,
    };
  },
});
</script>

<style lang="scss">
.geolocator-modal-container {
  .geolocator-modal {
    .sf-modal {
      &__container {
        --modal-index: 110;
        --overlay-z-index: 110;
        .geolocator-modal-top {
          p {
            display: none;
          }
        }
        .sf-modal__close {
          display: none;
        }
        .sf-modal__content {
          .geolocator-modal {
            &-body {
              text-align: center;
              .geolocator-modal-text {
                &-top {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 0.5rem;
                }
                &__text {
                  font-family: var(--barlow-regular);
                  font-size: var(--font-size-16);
                  margin-block-start: 0;
                  &--bold {
                    font-family: var(--barlow-semibold);
                  }
                }
              }
            }
            &-bottom {
              display: flex;
              flex-direction: column;
              gap: 1.5rem;
              margin: 1rem 1rem 0;
              .geolocator-modal-cta-accept {
                @include paragraph-small;
                background-color: var(--c-black);
                color: var(--c-white);
                border: none;
                text-transform: uppercase;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: var(--font-weight-medium);
                width: 100%;
                height: var(--button-height);
                border-radius: var(--border-radius);
              }
            }
          }
        }
      }
      &__bar {
        display: none;
      }
    }
  }
}
@include from-desktop-min {
  .geolocator-modal-container {
    .geolocator-modal {
      --modal-width: 50vw;
      --modal-max-height: 25rem;
      p {
        @include paragraph-regular;
      }

      .sf-modal {
        &__container {
          z-index: 101 !important;
          min-height: 15rem;
          .geolocator-modal-top {
            display: flex;
            justify-content: space-between;
            margin: 2rem 4rem;
            p {
              margin: 0;
            }
          }
          .sf-modal__content {
            .geolocator-modal {
              &-body {
                margin-top: 2rem;
                text-align: center;
              }
              &-bottom {
                flex-direction: row;
                margin: 2.5rem 1rem;
              }
            }
          }
        }
      }
    }
  }
}
@include to-tablet-max {
  .geolocator-modal-container {
    .geolocator-modal {
      .sf-modal {
        &__container {
          max-height: 22rem;
          height: fit-content;
          top: var(--modal-top, 0);
        }
      }
    }
  }
}
@media ((min-width: 768px) and (max-width: 1023px)) {
  .geolocator-modal-container {
    .geolocator-modal {
      --modal-width: 80vw;
      --modal-left: calc(50% - (80vw / 2));
      .sf-modal {
        &__container {
          top: 6.875rem;
        }
      }
    }
  }
}
</style>
