var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomMegaMenu',{staticClass:"mega-menu-mobile",attrs:{"visible":""}},[_vm._l((_vm.firtsLevelItems(_vm.menu)),function(item){return _c('div',{key:item.uid},[_c('CustomMegaMenuColumn',{attrs:{"title":item.label,"link":item.link}},[_c('div',{staticClass:"menu-lv2"},[(
            _vm.menuImagesContent &&
            _vm.menuImagesContent.active == true &&
            _vm.menuImagesContent.data
          )?_c('div',{staticClass:"menu-lv2__slider"},_vm._l((_vm.menuImagesContent.data),function(slide,index){return _c('div',{key:("menu-slider-" + index),staticClass:"menu-slider__item"},[(
                _vm.validItemAndSlideCarouselData(
                  item.label,
                  index,
                  _vm.menuImagesContent.data
                ) &&
                slide.data &&
                slide.data.length > 0 &&
                _vm.getValidImages(slide.data).length > 0
              )?_c('div',[_c('VueSlickCarousel',_vm._b({staticClass:"slick-home-collection",on:{"init":function($event){_vm.carouselIndex = 0},"beforeChange":function (oldIdx, newIdx) { return (_vm.carouselIndex = newIdx); }}},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.getValidImages(slide.data)),function(imageData,idx){return _c('div',{key:("menu-img-" + idx)},[_c('nuxt-link',{attrs:{"to":_vm.fixUrlForCurrentLocale(imageData.link.url)}},[_c('div',{staticClass:"menu-slider__item__image"},[_c('nuxt-img',{attrs:{"src":imageData.image.mobile,"preload":"","loading":"lazy","width":"230","height":"230"}}),_vm._v(" "),_c('div',{staticClass:"menu-slider__item__image__text"},[_vm._v("\n                        "+_vm._s(imageData.title)+"\n                      ")])],1)])],1)}),0),_vm._v(" "),_c('div',{staticClass:"slick-home-collection-bullets"},[_c('SfBullets',{attrs:{"total":_vm.getValidImages(slide.data).length,"current":_vm.carouselIndex},scopedSlots:_vm._u([{key:"active",fn:function(){return [_c('ProductBullets',{attrs:{"active":true}})]},proxy:true},{key:"inactive",fn:function(){return [_c('ProductBullets',{attrs:{"active":false}})]},proxy:true}],null,true)})],1)],1):_vm._e()])}),0):_vm._e(),_vm._v(" "),_vm._l((_vm.secondLevelItems(item)),function(child){return _c('div',{key:child.uid},[_c('CustomMegaMenuColumn',{attrs:{"title":child.label,"link":child.link}},[_c('div',{staticClass:"menu-lv3"},_vm._l((child.children),function(childLv2,childLv2Index){return _c('div',{key:childLv2.uid,staticClass:"menu-lv3__links"},[(_vm.hasDivider(childLv2.label))?_c('div',{staticClass:"menu-lv3__links__divider"}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"sf-menu-item__label",on:{"click":function($event){return _vm.goTo(childLv2.link)}}},[_vm._v("\n                  "+_vm._s(childLv2.label)+"\n                ")]),_vm._v(" "),(
                    _vm.hasDivider(childLv2.label, true) &&
                    !_vm.hasDivider(child.children[childLv2Index + 1].label)
                  )?_c('div',{staticClass:"menu-lv3__links__divider"}):_vm._e()])}),0)])],1)})],2),_vm._v(" "),_vm._l((_vm.secondLevelItems(item, false)),function(child){return _c('SfMenuItem',{key:child.uid,staticClass:"sf-mega-menu-column__header user-links",attrs:{"label":child.label,"icon":"","link":_vm.fixUrlForCurrentLocale(child.link)}})})],2)],1)}),_vm._v(" "),_vm._l((_vm.firtsLevelItems(_vm.menu, false)),function(item){return _c('SfMenuItem',{key:item.uid,staticClass:"sf-mega-menu-column__header user-links",attrs:{"label":item.label,"icon":"","link":_vm.fixUrlForCurrentLocale(item.link)}})}),_vm._v(" "),_c('div',{staticClass:"mega-menu-mobile__bottom-links"},[_c('div',{staticClass:"my-account link",on:{"click":function($event){_vm.handleAccountClick();
        _vm.openMenuLink();}}},[_c('div',{staticClass:"icon"},[_c("MyAccountIcon",{tag:"component"})],1),_vm._v(" "),_c('div',{staticClass:"label"},[_vm._v("\n        "+_vm._s(_vm.userName ? ((_vm.$t('Hello, ')) + " " + _vm.userName) : _vm.$t('Login'))+"\n      ")])]),_vm._v(" "),_c('nuxt-link',{staticClass:"m-link my-cart link",attrs:{"to":_vm.fixUrlForCurrentLocale('/cart')}},[_c('div',{staticClass:"icon"},[_c("BagIcon",{tag:"component"})],1),_vm._v(" "),_c('div',{staticClass:"label"},[_vm._v("\n        "+_vm._s(_vm.$t('Cart'))+"\n      ")])]),_vm._v(" "),_c('nuxt-link',{staticClass:"m-link wishlist link",attrs:{"to":_vm.fixUrlForCurrentLocale('/wishlist')},on:{"click":function($event){_vm.handleWishlistClick();
        _vm.openMenuLink();}}},[_c('div',{staticClass:"icon"},[_c("HearthIcon",{tag:"component"})],1),_vm._v(" "),_c('div',{staticClass:"label"},[_vm._v("\n        "+_vm._s(_vm.$t('Wishlist'))+"\n      ")])]),_vm._v(" "),(_vm.supportLink)?_c('nuxt-link',{staticClass:"support link m-link",attrs:{"to":_vm.fixUrlForCurrentLocale(_vm.supportLink['link-url'])}},[_c('div',{staticClass:"icon"},[_c("PhoneIcon",{tag:"component"})],1),_vm._v(" "),_c('div',{staticClass:"label"},[_vm._v("\n        "+_vm._s(_vm.supportLink['link-label'])+"\n      ")])]):_c('nuxt-link',{staticClass:"m-link support link",attrs:{"to":_vm.fixUrlForCurrentLocale(
          _vm.locale.includes('it') ? '/contatti' : '/contacts'
        )}},[_c('div',{staticClass:"icon"},[_c("PhoneIcon",{tag:"component"})],1),_vm._v(" "),_c('div',{staticClass:"label"},[_vm._v("\n        "+_vm._s(_vm.$t('Support'))+"\n      ")])])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }