// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Context } from '@nuxt/types';
import cookieNames from '~/enums/cookieNameEnum';
import { hasAuthorizationError } from '~/helpers/util';
import { magentoStoreviewCodesMapping } from '~/helpers/magentoConfig/storeviewMapping';
import { getEnglishUrlFromItalianUrl } from '~/helpers/urlManager';
import { getListUrlRewritesByTargetPaths } from '~/serverMiddleware/getGeminiData';
import { getProductByCode } from '~/serverMiddleware/getProductByCode';
import { geoLangMapping } from '~/helpers/geoRedirect/geoLangMapping';
import { botsRegExp } from '~/helpers/util';
import axios from 'axios';

const PLACEHOLDER_IP = '78.111.224.244';

// Utility to check if an IP address is local
const isLocalAddress = (ip: string): boolean => {
  return <boolean>(ip.startsWith('127.') || // Loopback IPv4
    ip === '::1' || // Loopback IPv6
    ip.startsWith('10.') || // Private IPv4
    ip.startsWith('192.168.') || // Private IPv4
    ip.match(/^172\.(1[6-9]|2\d|3[01])\./)); // Private IPv4 (172.16.0.0 - 172.31.255.255)
};

// Get client IP, falling back to placeholder if local
const getClientIp = (req: any): string => {
  const forwardedHeader = req?.headers?.['x-forwarded-for'] as string | undefined;
  const ip = forwardedHeader?.split(',')[0]?.split(':')?.[0] || req?.socket?.remoteAddress?.split(':')?.pop() || '';
  return isLocalAddress(ip) ? PLACEHOLDER_IP : ip;
};

// Handle geolocation-based redirects
const handleGeolocationRedirect = async (route: any, req: any, redirect: any): Promise<void> => {
  // Skip redirect for bots
  if (req?.headers && botsRegExp.test(req.headers['user-agent'])) {
    return;
  }

  // call geolocation endpoint only if the route contains /g/ or /p/ or /c/
  if (route.fullPath.startsWith('/g/') || route.fullPath.includes('/p/') || route.fullPath.includes('/c/')) {
    const clientIp = getClientIp(req);
    try {
      const response = await axios({
        method: 'get',
        url: `${process.env.GEOLOCATION_ENDPOINT}/${clientIp}`,
        params: {
          access_key: process.env.GEOLOCATION_ACCESS_KEY,
          output: 'json',
          fields: 'country_code,location',
        },
        headers: {
          Accept: 'application/json',
        },
      });

      if (response.data.error) {
        console.error('Error during geolocation data retrieval');
        return;
      }

      const {
        country_code: countryCode,
        location: { languages },
      } = response.data;

      const languageCode = languages[0].code === 'it' ? 'it' : 'en';
      const hreflang = geoLangMapping.find((element) => element.lang === languageCode && element.defaultCountry === countryCode);
      if (!hreflang) {
        console.error('No hreflang found for this country and language');
        return;
      }

      if (route.fullPath.startsWith('/g/')) {
        const remainingPath = route.fullPath.slice(3); // Remove "/g/" prefix
        redirect(301, `/${hreflang.locale}/${remainingPath}`);
      } else if ((route.fullPath.includes('/p/') || route.fullPath.includes('/c/')) && Object.keys(route.params).length > 0) {
        let productGrn;
        let productId;
        let categoryId;

        if (route.fullPath.includes('/p/')) {
          productId = route.params.productId;
          // Check if productId is not in UUID format
          const uuidRegex = /^[\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}$/i;
          if (!uuidRegex.test(productId)) {
            const product = await getProductByCode(productId);
            productGrn = product?.grn;
          }
        } else if (route.fullPath.includes('/c/')) {
          categoryId = route.params.categoryId;
        }

        const grnFromUrlPath = productGrn
          ? productGrn
          : productId
            ? `grn:product:product::${productId}`
            : `grn:product-list:product-list::${categoryId}`;

        const urlRewrites = await getListUrlRewritesByTargetPaths(grnFromUrlPath);
        if (urlRewrites.length === 0) {
          console.error('No URL rewrite found for this country and language');
          return;
        }

        const urlRewrite = urlRewrites.find((element) => element.context === (languageCode === 'it' ? 'it_IT' : 'en_US'));
        if (urlRewrite) {
          redirect(301, `/${hreflang.locale}/${urlRewrite.requestPath}`);
        }
      }
    } catch (error) {
      console.error('Error during geolocation handling:', error.message);
    }
  }
};

// Handle redirects for specific paths
const handleSpecificRedirects = async (app: any, route: any, redirect: any) => {
  const path = route.fullPath?.split('/')[1];
  const page = route.fullPath?.split('/')[2] ?? '';

  if (path === 'it-it' && page === 'journal') {
    redirect(301, `${route.fullPath.replace('journal', 'magazine')}`);
    return;
  }

  if (path === 'bh-it') {
    const englishUrl = await getEnglishUrlFromItalianUrl(route);
    redirect(301, `/bh-en/${englishUrl}`);
    return;
  }

  if (path && route.fullPath?.at(-1) === '/') {
    redirect(301, route.fullPath.slice(0, -1));
    return;
  }

  if (route.name?.toLowerCase().includes('homepage')) {
    const regex = /homepage\/?/gi;
    let newRoute = route.fullPath.replace(regex, '');
    if (newRoute.at(-1) === '/') {
      newRoute = newRoute.slice(0, Math.max(0, route.fullPath.length - 1));
    }
    if (newRoute !== route.fullPath) {
      redirect(301, newRoute);
    }
  }
};

// Handle Magento storeview code redirects
const handleMagentoRedirects = (route: any, redirect: any) => {
  const path = route.fullPath?.split('/')[1];
  magentoStoreviewCodesMapping.forEach((element) => {
    if (path === element.magentoStoreViewCode) {
      const mCode = `/${element.magentoStoreViewCode}`;
      const gCode = `/${element.geminiCode}`;
      redirect(301, `${route.fullPath.replace(mCode, gCode)}`);
    }
  });
};

// Handle authorization errors
const handleAuthorizationErrors = (app: any) => {
  if (hasAuthorizationError(app.$cookies.get(cookieNames.customerCookieName))) {
    app.$cookies.remove(cookieNames.customerCookieName);
    app.$cookies.remove(cookieNames.cartCookieName);
    app.$cookies.remove(cookieNames.segmentsCookieName);
    app.$cookies.set(cookieNames.messageCookieName, {
      message: app.i18n.t('You are not authorized, please log in.'),
      type: 'warning',
      icon: null,
      persist: false,
      title: null,
    });
  }
};

export default async ({ app, route, redirect, req }: Context) => {
  await handleMagentoRedirects(route, redirect);
  await handleGeolocationRedirect(route, req, redirect);
  await handleSpecificRedirects(app, route, redirect);
  handleAuthorizationErrors(app);

  const orderCookie = app.$cookies.get('order-number');
  const path = route.fullPath?.split('/')[1];

  if (route.fullPath.includes('thank-you') && (!orderCookie || (orderCookie && orderCookie !== route.query?.order))) {
    redirect(302, `/${path}/not-found`);
  }
};
