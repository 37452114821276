/* eslint-disable no-await-in-loop */
import axios from 'axios';

export const getProductByCode = async (code) => {

  try {
    const geminiData = await axios({
      method: 'post',
      url: `${process.env.GEMINI_PRODUCT_URL_ENDOPOINT}/product.Product/GetProductByCode`,
      data: {
        code: code,
        tenantId: process.env.TENANT_ID,
      },
      headers: {
        Authorization: `Bearer ${process.env.GEMINI_RESOURCE_JWT}`,
      },
    });
    return geminiData?.data?.product;
  } catch (error) {
    throw new Error(error);
  }
};

export default {
  path: '/api/getProductByCode',
  async handler(req, res) {
    try {
      const product = await getProductByCode(req.query.code);
      res.send(product);
    } catch (error) {
      res.send(error);
    }
  },
};
