/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */ // ToDo: handle types
import { ref } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import axios, { AxiosResponse } from 'axios';

interface Ipify extends AxiosResponse {
  data: {
    ip: string;
  };
}

/* Response is available in these languages, not in use now since there's only
IT and EN in this project. To enable it just pass the locale in params
using language as key. */
/* const availableLanguages = [
  'en', // English
  'de', // German
  'ed', // Spanish
  'fr', // French
  'ja', // Japanese
  'pt-br', // Portuguese (Brazil)
  'ru', // Russian
  'zh', // Chinese
]; */

/**
 * A Vue.js composable that provides functionality for retrieving the client's geolocation data.
 * The composable uses the Ipify API to retrieve the client's IP address, and then uses the '/api/get-geo-location' endpoint to retrieve the geolocation data.
 * The composable exposes reactive refs for the location data, loading state, error messages, and IP address.
 * @returns An object containing the reactive refs and functions for retrieving the geolocation data.
 */
const useGeoLocation = () => {
  const location: Ref<string> = ref(null);
  const loading: Ref<boolean> = ref(false);
  const error: Ref<string> = ref(null);
  const ip: Ref<string> = ref(null);

  /**
   * Retrieves the client's IP address using the Ipify API and updates the `ip` ref with the result.
   * If there is an error retrieving the IP address, the `error` ref is updated with the error message.
   * @async
   * @function getClientIp
   * @throws {Error} If there is an error retrieving the IP address.
   */
  const getClientIp = async () => {
    loading.value = true;
    ip.value = null;
    error.value = null;

    try {
      const ipResponse: Ipify = await axios({
        method: 'get',
        url: 'https://api.ipify.org',
        params: {
          format: 'json',
        },
      });
      console.debug(`getClientIp composable response: ${ipResponse.data.ip}`);
      ip.value = ipResponse.data.ip;
      loading.value = false;
    } catch (error_) {
      /* Error would be lost when function is called in getLocation,
      saving value in error ref only works if this is called in FE */
      // error.value = err;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      throw new Error(error_);
    }
  };

  /**
   * Retrieves the geolocation data for the client's IP address using the '/api/get-geo-location' endpoint.
   * @async
   * @function getLocation
   * @throws {Error} If there is an error retrieving the geolocation data.
   */
  const getLocation = async () => {
    loading.value = true;
    location.value = null;
    error.value = null;
    try {
      await getClientIp();
      const response = await axios.get(`/api/get-geo-location`, {
        params: {
          ip: ip.value,
        },
        headers: {
          Accept: 'text/plain, json/application',
        },
      });
      console.debug('getLocation composable response:', response);
      if (response.data?.error) {
        error.value = response.data.error;
      } else {
        location.value = response.data;
      }
      loading.value = false;
    } catch (error_) {
      error.value = error_;
      loading.value = false;
    }
  };

  return {
    location,
    loading,
    error,
    ip,
    getLocation,
    getClientIp,
  };
};

export default useGeoLocation;
