/* eslint-disable no-secrets/no-secrets */
export default `
query productDetails($filter: ProductDetailFilterInput!, $configurations: [ID!]) {
  productDetail(filter: $filter) {
    vsf_typename
    uid
    atelier_id
    sku
    merchant_sku
    name
    brand
    season_code
    super_group
    urls {
      linkRel
      urlPath
    }
    group_attribute {
      label
      value
    }
    super_group_attribute {
      label
      value
    }
    stock_status
    only_x_left_in_stock
    sizes_typology
    model
    variant
    composition
    brand_attribute {
      label
    }
    gender
    gender_attribute {
      label
    }
    made_in
    size_and_fit
    thumbnail {
      url
      label
    }
    url_key
    url_rewrites {
      url
    }
    price_range {
      maximum_price {
        final_price {
          currency
          value
        }
        regular_price {
          currency
          value
        }
      }
      minimum_price {
        final_price {
          currency
          value
        }
        regular_price {
          currency
          value
        }
      }
    }
    categories {
      uid
      name
      url_path
      urls {
        redirectCode
        linkRel
        urlPath
      }
    }
    small_image {
      url
      label
    }
    image {
      url
      label
    }
    media_gallery {
      url
      position
      disabled
      label
    }
    url_key
    url_rewrites {
      url
    }
    meta_description
    meta_keyword
    meta_title
    description {
      html
    }
    short_description {
      html
    }
    season_code_attribute {
      label
    }
    configurable_options {
      attribute_code
      attribute_uid
      label
      position
      uid
      use_default
      values {
        label
        uid
      }
    }
    configurable_product_options_selection(configurableOptionValueUids: $configurations) {
      options_available_for_selection {
        attribute_code
        option_value_uids
      }
      configurable_options {
        attribute_uid
        attribute_code
        label
        uid
        values {
          label
          uid
        }
      }
      media_gallery {
        disabled
        label
        position
        url
        video_content {
          video_description
          video_provider
          video_title
          video_url
        }
      }
      variant {
        uid
        sku
        merchant_sku
        name
        stock_status
        only_x_left_in_stock
        price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
      }
    }
    variants {
      product {
        uid
        only_x_left_in_stock
        size_attribute {
          label
          attribute_code
          value
        }
      }
    }
    list_sku_variants
  }
}
`;
