<template>
  <div class="header-navigation-mobile">
    <div id="open-menu-button">
      <SfButton
        v-e2e="'app-header-menu'"
        class="sf-button--pure sf-header__action"
        data-testid="accountIcon"
        aria-label="Account"
        @click="toggleMenu"
      >
        <component
          :is="
            isMobileMenuOpen
              ? 'CrossIcon'
              : headerColor === 'white'
              ? 'BlackHamburger'
              : 'WhiteHamburger'
          "
          class="sf-header_menu-image"
          alt="Menu"
          :width="isMobileMenuOpen ? '17' : '22'"
          :height="isMobileMenuOpen ? '17' : '16'"
        />
      </SfButton>
    </div>
    <transition name="sf-slide-left">
      <div v-if="isMobileMenuOpen" id="mega_menu">
        <MegaMenuMobile :menu="menu" :menu-images-content="menuImagesContent" />
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent, ref, useRoute, watch } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import { useUiState, useWindow } from '~/composables';
import MegaMenuMobile from './MegaMenuMobile.vue';
import {
  BlackHamburger,
  WhiteHamburger,
  CrossIcon,
} from '~/components/General/icons';

export default defineComponent({
  name: 'HeaderNavigationMobile',
  components: {
    SfButton,
    MegaMenuMobile,
    BlackHamburger,
    WhiteHamburger,
    CrossIcon,
  },
  props: {
    menu: {
      type: Object,
      default: () => ({
        items: [],
      }),
    },
    headerColor: {
      type: String,
      default: 'white',
    },
    menuImagesContent: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { isMobileMenuOpen, toggleMobileMenu } = useUiState();
    const { handleHtmlClass } = useWindow();
    const route = useRoute();

    const headerIcons = ref({
      hamburger: '../static/icons/components/hamburger.svg',
      cross: '../static/icons/components/cross.svg',
    });

    const toggleMenu = () => {
      toggleMobileMenu();
      handleHtmlClass(
        isMobileMenuOpen.value ? 'addClass' : 'removeClass',
        'open_menu_html'
      );
    };

    watch(
      () => route.value.fullPath,
      (newValue, oldValue) => {
        if (newValue !== oldValue && isMobileMenuOpen.value) {
          toggleMenu();
        }
      }
    );

    return {
      headerIcons,
      isMobileMenuOpen,
      toggleMenu,
    };
  },
});
</script>

<style lang="scss" scoped>
.header-navigation-mobile {
  position: relative;
  height: 100%;
  width: 100%;
  #open-menu-button {
    display: flex;
    height: 100%;
  }
  #mega_menu {
    position: relative;
    background: #ffffff;
    left: -1.25rem;
    width: 100vw;
    overflow: auto;
    padding-top: 1.875rem;
    &.sf-slide-left {
      &-enter,
      &-leave {
        &-active,
        &-to {
          transition: all ease-out 300ms;
        }
      }
    }
  }
  .sf-header_menu-image {
    margin-top: 5px;
  }
}
</style>
