<template>
  <div
    class="custom-textArea"
    :class="fullWidth && 'custom-textArea-full-width'"
  >
    <ValidationProvider
      :rules="validateRule"
      v-slot="{ errors }"
      class="custom-textArea__container"
      :mode="interactionMode"
      :ref="textAreaId"
      :vid="textAreaId"
    >
      <SfTextarea
        :label="`${$t(textAreaLabel)} ${
          validateRule && validateRule.includes('required') ? '*' : ''
        }`"
        :name="textAreaName"
        :disabled="disabled"
        :placeholder="placeholder"
        :value="textAreaValue"
        :cols="textAreaCols"
        :rows="textAreaRows"
        maxlength="400"
        wrap="soft"
        @input="handleChange"
        @blur="(e) => handleChange(e, true)"
      ></SfTextarea>
      <component v-if="icon" class="custom-textArea__icon" :is="icon" />
      <div v-if="errors[0]" class="custom-textArea__error">
        {{ $t(errors[0]) }}
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { ValidationProvider, extend } from 'vee-validate';
import { email, required, alpha_num, confirmed } from 'vee-validate/dist/rules';
import { useI18n } from '~/helpers/hooks/usei18n';
import { SfTextarea } from '@storefront-ui/vue';

export default defineComponent({
  name: 'CustomTextArea',
  components: {
    ValidationProvider,
    SfTextarea,
  },
  model: {
    prop: 'textAreaValue',
  },
  props: {
    textAreaName: {
      type: String,
      required: true,
    },
    textAreaValue: {
      type: [String, Object, null],
      default: null,
    },
    textAreaLabel: {
      type: String,
      required: true,
    },
    textAreaId: {
      type: String,
      required: true,
    },
    textAreaCols: {
      type: String,
      default: '80',
    },
    textAreaRows: {
      type: String,
      default: '25',
    },
    validateRule: {
      type: [Boolean, String],
      default: '',
    },
    interactionMode: {
      type: String,
      default: 'aggressive',
    },
    icon: {
      type: [Boolean, String],
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const handleChange = (value, blur = false) => {
      if (blur) {
        emit('blur', value.target.value);
        return;
      }
      emit('input', value);
    };

    const trans = useI18n();

    extend('email', {
      ...email,
      message: trans.t('The email format is not correct'),
    });
    extend('alpha_num', {
      ...alpha_num,
      message: trans.t('May only contain alpha-numeric characters'),
    });
    extend('required', {
      ...required,
      message: trans.t('Required field'),
    });
    extend('confirmed', {
      ...confirmed,
      message: trans.t('Field does not match'),
    });

    extend('min', {
      validate(v, arguments_) {
        return v.length >= arguments_.length;
      },
      params: ['length'],
      message: (_, arguments_) => {
        return trans.t(`Must be longer than than {0} characters`, [
          arguments_.length,
        ]);
      },
    });
    extend('max', {
      validate(v, arguments_) {
        return v.length < arguments_.length;
      },
      params: ['length'],
      message: (_, arguments_) => {
        return trans.t(`Must be shorter than {0} characters`, [
          arguments_.length,
        ]);
      },
    });

    return {
      handleChange,
    };
  },
});
</script>

<style lang="scss">
.custom-textArea {
  &-full-width {
    textarea {
      width: 100%;
      border-radius: var(--input-border-radius);
      border: var(--input-border-width) solid var(--input-border-color);
      font-family: var(--barlow-regular);
      text-transform: uppercase;
      outline: none;
    }
  }
  .sf-textarea {
    &__label {
      padding-left: var(--input-label-padding-left);
      padding-bottom: var(--input-label-padding-bottom);
      @include input-label;
      top: 0;
    }
  }
}
</style>
