const mapCmsPages = new Map();
mapCmsPages.set('cookie-policy', 'cookie-policy');
mapCmsPages.set('privacy-policy', 'privacy-policy');
mapCmsPages.set('termini-e-condizioni', 'terms-and-conditions');
mapCmsPages.set('terms-and-conditions', 'termini-e-condizioni');
mapCmsPages.set('pagamenti-e-rimborsi', 'payments-and-refunds');
mapCmsPages.set('payments-and-refunds', 'pagamenti-e-rimborsi');
mapCmsPages.set('resi', 'returns');
mapCmsPages.set('returns', 'resi');
mapCmsPages.set('spedizioni', 'shipments');
mapCmsPages.set('shipments', 'spedizioni');
mapCmsPages.set('contatti', 'contacts');
mapCmsPages.set('contacts', 'contatti');
mapCmsPages.set('faq', 'faq');
mapCmsPages.set('resi-e-cambio-merce', 'returns-and-items-change');
mapCmsPages.set('returns-and-items-change', 'resi-e-cambio-merce');

export const isCmsPage = (path: string) => mapCmsPages.has(path);

export const getCmsPage = (path: string): string | null => mapCmsPages.get(path);
