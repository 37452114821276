<template>
  <div class="footer-container">
    <SfFooter :column="5" multiple class="footer">
      <SfFooterColumn
        v-for="(menuItem, index) in menu.items"
        :title="menuItem.label"
        :key="`${index}-${menuItem.label}`"
        class="custom-footer"
      >
        <SfList v-if="hasChildren(menuItem)">
          <SfListItem
            v-for="(child, childIndex) in menuItem.children"
            :key="`${childIndex}-${child.label}`"
            :name="childIndex"
          >
            <SfMenuItem
              :link="fixUrlForCurrentLocale(child.link)"
              :label="child.label"
              :key="child.label + childIndex"
            />
          </SfListItem>
        </SfList>
        <div v-else>
          <SfLink :link="fixUrlForCurrentLocale(menuItem.link)">
            {{ menuItem.label }}
          </SfLink>
        </div>
      </SfFooterColumn>
      <Socials />
      <div class="footer__reviews">
        <TrustPilotWidget v-if="isMounted" />
        <nuxt-img
          key="CBI"
          class="footer__social-image footer__social-image-cbi"
          :src="addBasePath('/general/CBI.svg')"
          alt="CBI logo"
          :placeholder="addBasePath('/general/CBI.svg')"
          width="70"
          height="60"
          :loading="`lazy`"
        />
      </div>
      <NewsletterSubscriber />
    </SfFooter>
    <hr />
    <FooterCopyright />
  </div>
</template>

<script>
import {
  SfFooter,
  SfList,
  SfImage,
  SfMenuItem,
  SfLink,
} from '@storefront-ui/vue';
import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import { useMenu } from '@gemini-vsf/composables';
import Socials from './General/icons/Socials.vue';
import NewsletterSubscriber from './General/Footer/NewsletterSubscriber.vue';
import FooterCopyright from './General/Footer/FooterCopyright.vue';
import { isClient } from '@storefront-ui/vue/src/utilities/helpers';
import { useCurrentLocale } from '~/composables/useCurrentLocale';
import TrustPilotWidget from '~/components/General/Footer/TrustPilotWidget.vue';

export default defineComponent({
  components: {
    TrustPilotWidget,
    SfFooter,
    SfList,
    SfImage,
    SfMenuItem,
    SfLink,
    NewsletterSubscriber,
    Socials,
    FooterCopyright,
  },
  setup() {
    const { menu, search: searchMenu } = useMenu('menu');
    const isMounted = ref(false);

    const hasChildren = (menuItem) => Boolean(menuItem?.children?.length);
    onMounted(async () => {
      if (isClient) {
        await searchMenu({ code: 'footermenu' });
        isMounted.value = true;
      }
    });

    const { fixUrlForCurrentLocale } = useCurrentLocale();

    return {
      menu,
      isMounted,
      addBasePath,
      hasChildren,
      fixUrlForCurrentLocale,
    };
  },
});
</script>

<style lang="scss">
.footer-container {
  background-color: var(--c-secondary);
  color: #969696;
  .copyright-container .footer__copyright-text,
  .copyright-container .footer__copyright-links > a,
  .copyright-container .footer__copyright-links__link {
    color: #969696;
  }
  .sf-footer-column__content .sf-menu-item__label {
    color: #969696;
  }
  position: relative;
  bottom: 0;
  width: 100%;
  z-index: 1;
  @include for-desktop {
    padding: 5rem 2.5rem 2rem 2.5rem;
  }
}
.footer {
  &__socials {
    display: flex;
    justify-content: space-between;
    @include for-mobile {
      padding: 1.6875rem 1.25rem;
    }
    @include for-desktop {
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
    }
    &__heading {
      @include paragraph-semibold;
      text-transform: uppercase;
      color: var(--c-white);
      @include for-desktop {
        @include paragraph-small;
        padding-bottom: 1.25rem;
        width: 100%;
      }
    }
  }
  &__reviews {
    display: flex;
    @include for-mobile {
      padding: 0 1.25rem 1rem;
      justify-content: space-between;
    }
    @include for-desktop {
      flex-direction: column;
    }
  }

  &__social-image {
    margin: 0 var(--spacer-2xs) 0 0;
    .sf-image {
      object-fit: fill;
    }
    &-cbi {
      @include for-desktop {
        margin-top: 3.75rem;
      }
    }
  }
}
.sf-footer {
  padding: 0;
  @include for-desktop {
    margin: 0 auto;
    @include desktop-boxed;
    align-items: flex-start;
    &-column {
      padding: 0;
      margin: 0 0 4.375rem 0;
      &__title {
        padding: 0;
        .sf-heading__title {
          @include paragraph-small;
          padding-bottom: 1.25rem;
        }
      }
      &__content {
        .sf-menu-item {
          &__label {
            @include paragraph-small;
            font-size: var(--font-size-13);
            text-transform: uppercase;
            --menu-item-label-color: var(--c-dark-grey);
            padding-bottom: 0.625rem;
            &:hover {
              color: var(--c-white);
            }
          }
        }
      }
    }
  }
  @include for-mobile {
    &-column {
      border-bottom: 0.0625rem groove var(--c-dark-grey);
      &__title {
        padding: 2rem 1.25rem;
      }
      button {
        @include paragraph-semibold;
        --button-color: var(--c-white);
        --button-text-transform: uppercase;
        width: 100%;
        justify-content: space-between;
      }
      &__content {
        --list-padding: 0 1.25rem 1.6875rem;
        .sf-menu-item {
          &__label {
            @include paragraph-small;
            text-transform: uppercase;
            color: var(--c-dark-grey);
            padding-bottom: 0.625rem;
          }
        }
      }
    }
  }
  .newsletter {
    &-input-container {
      display: inline-flex;
      align-items: center;
      justify-items: center;
      gap: 0;
      flex-wrap: nowrap;
    }
  }
}

@include to-mobile-max {
  .footer-container {
    padding-bottom: 90px;
  }
}
</style>
