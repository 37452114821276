/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-secrets/no-secrets */

// The URL of the Google Maps API script
const GOOGLE_MAPS_SCRIPT = 'https://maps.googleapis.com/maps/api/js?libraries=places';

// Importing necessary functions and objects from the @nuxtjs/composition-api module
import { ref, useContext, computed } from '@nuxtjs/composition-api';

// Importing a helper function to load scripts dynamically
import { loadScript } from '@/helpers/util';

// A custom hook that provides functionality for working with Google Maps
const useMaps = () => {
  // Get the Nuxt.js app context
  const { app } = useContext();

  // Create reactive variables for the autocomplete suggestion, the autocomplete object, and the address data
  const suggestion = ref();
  const autocomplete = ref();
  const addressData = ref({ street: '', apartment: '', city: '', postcode: '', country_code: '', region: '' });

  // A computed property that returns true if the Google Maps API is available
  const autocompleteIsAvailable = computed(() => typeof window !== 'undefined' && !!window.google);

  // A function that parses the address data returned by the Google Maps API
  const parsePlaceData = (callback: Function) => {
    const place = autocomplete.value.getPlace();

    const isItaly = place.address_components.some(
      (addressComponent) => addressComponent.short_name === 'IT' && addressComponent.types.includes('country')
    );

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          addressData.value['apartment'] = component.short_name;
          break;
        }

        case 'premise': {
          addressData.value['apartment'] = component['long_name'];
          break;
        }

        case 'sublocality_level_4': {
          addressData.value['apartment'] =
            addressData.value['apartment'] === '' ? component['long_name'] : component['long_name'] + '-' + addressData.value['apartment'];
          break;
        }

        case 'route': {
          addressData.value['street'] = component.long_name;
          break;
        }

        case 'sublocality_level_3': {
          addressData.value['street'] = component['long_name'];
          break;
        }

        case 'locality': {
          if (!isItaly) {
            addressData.value['city'] = component.long_name;
          }
          break;
        }

        case 'postal_code': {
          addressData.value['postcode'] = component.long_name;
          break;
        }

        case 'country': {
          addressData.value['country_code'] = component.short_name;
          break;
        }

        case 'administrative_area_level_1': {
          if (!isItaly) {
            addressData.value['region'] = component.short_name;
          }
          break;
        }

        case 'administrative_area_level_2': {
          if (isItaly) {
            addressData.value['region'] = component.short_name;
          } else {
            addressData.value['city'] = component.long_name;
          }
          break;
        }

        case 'administrative_area_level_3': {
          if (isItaly) {
            addressData.value['city'] = component.long_name;
          }
          break;
        }
      }
    }
    callback();
  };

  // A function that initializes the Google Maps autocomplete functionality
  const initGoogleAutocomplete = async (elementId: string, callback?: Function) => {
    // Get the Google Maps API key from the Nuxt.js app config
    const mapsKey = app.$config.mapsKey;
    if (!mapsKey) {
      console.warn('Google Maps API key is missing');
      return;
    }

    // Load the Google Maps API script dynamically
    await loadScript(`${GOOGLE_MAPS_SCRIPT}&key=${mapsKey}`);

    // Get the address input element and create a new Autocomplete object
    const addressElement = document.querySelector(`#${elementId}`);
    if (!window.google || !addressElement) return;
    autocomplete.value = new window.google.maps.places.Autocomplete(addressElement as HTMLInputElement);

    // Add a listener for the "place_changed" event, which is fired when the user selects a suggestion
    autocomplete.value?.addListener('place_changed', () => parsePlaceData(callback));
  };

  // Return the public API of the useMaps hook
  return { initGoogleAutocomplete, suggestion, addressData, autocompleteIsAvailable };
};

// Export the useMaps hook as the default export of this module
export default useMaps;
