/* eslint-disable @typescript-eslint/no-explicit-any */
import { Context as NuxtContext } from '@nuxt/types';
import handleMarketsAndLocales from '~/helpers/geminiConfig/handleMarketsAndLocales';
import c from 'cookie';
import cookieNames from '~/enums/cookieNameEnum';
import { merge } from 'lodash-es';

export type ApiClientMethod = (...args: any) => Promise<any>;

interface CreateProxiedApiParams {
  givenApi: Record<string, ApiClientMethod>;
  client: any;
  tag: string;
}

export const createProxiedApi = ({ givenApi, client, tag }: CreateProxiedApiParams) =>
  new Proxy(givenApi, {
    get: (target, prop, receiver) => {
      const functionName = String(prop);
      if (Reflect.has(target, functionName)) {
        return Reflect.get(target, prop, receiver);
      }
      return async (...args) => client.post(`/${tag}/${functionName}`, args).then((r) => r.data);
    },
  });

export const getCookies = (context: NuxtContext) => context?.req?.headers?.cookie ?? '';

export const getIntegrationConfig = (context: NuxtContext, configuration: any) => {
  const {
    app: {
      i18n: { locales: markets },
    },
    route,
    req: { headers: { cookie: cookiesFromReq } = {} } = {},
  } = context;
  let cookie = '';
  if (process.client) {
    cookie = getCookies(context);
  } else {
    const { findMarketForLocaleCode, getLocaleFromRoute } = handleMarketsAndLocales();
    const cookiesObject = c.parse(cookiesFromReq ?? '');
    const cleanLocaleFromUrl = getLocaleFromRoute(route);
    if (cleanLocaleFromUrl) {
      const { grn, localeValue, defaultCurrency } = findMarketForLocaleCode(markets, cleanLocaleFromUrl);
      cookiesObject[cookieNames.localeCookieName] = localeValue;
      cookiesObject[cookieNames.storeCookieName] = localeValue;
      cookiesObject[cookieNames.countryCookieName] = localeValue;
      cookiesObject[cookieNames.marketCookieName] = grn;
      cookiesObject[cookieNames.currencyCookieName] = defaultCurrency;

      let newCookieString = '';
      Object.keys(cookiesObject).forEach((key) => {
        newCookieString += `${key}=${cookiesObject[key]}; `;
      });
      cookie = newCookieString.trim().slice(0, -1);
    }
  }
  return merge(
    {
      axios: {
        headers: {
          ...(cookie ? { cookie } : {}),
        },
      },
    },
    configuration
  );
};
