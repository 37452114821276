<template>
  <div class="empty">
    <!-- placeholder -->
  </div>
</template>

<script>
import {
  defineComponent,
  useContext,
  onMounted,
} from '@nuxtjs/composition-api';
import { userGetters } from '@gemini-vsf/composables';

export default defineComponent({
  name: 'CustomerGtm',
  props: {
    customerData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    isLogged: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  setup(props) {
    const { app } = useContext();
    onMounted(() => {
      const cookieConsent = app.$cookies.get('CookieConsent');
      if (
        cookieConsent &&
        cookieConsent.includes('preferences:true') &&
        app.$gtm
      ) {
        if (props.isLogged) {
          app.$gtm.push({
            customerLoggedIn: 1,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            firstname: userGetters.getFirstName(props.customerData.user),
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            lastname: userGetters.getLastName(props.customerData.user),
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            email: userGetters.getEmailAddress(props.customerData.user),
          });
        } else {
          app.$gtm.push({
            customerLoggedIn: 0,
            customerId: 0,
            customerGroupId: '1',
            customerGroupCode: 'GENERAL',
          });
        }
      }
    });
    return {
      app,
    };
  },
});
</script>

<style scoped></style>
