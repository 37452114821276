export const getIdentifier = (cmsArrayElement: any): string => cmsArrayElement.identifier || '';
export const getContent = (cmsArrayElement: any): string => cmsArrayElement.content || '';
export const getMetaTitle = (cmsArrayElement: any): string => cmsArrayElement.meta_title || '';
export const getMetaDescription = (cmsArrayElement: any): string => cmsArrayElement.meta_description || '';
export const getContentHeading = (cmsArrayElement: any): string => cmsArrayElement.content_heading || '';
export const getTitle = (cmsArrayElement: any): string => cmsArrayElement.title || '';

// cms
export const getCmsBlockContent = (items: any): any =>
  items.reduce((cmsObject, cmsArrayElement) => {
    if (cmsArrayElement.identifier && cmsArrayElement.content) {
      try {
        cmsObject[cmsArrayElement.identifier] = JSON.parse(cmsArrayElement.content);
      } catch {
        cmsObject[cmsArrayElement.identifier] = null;
        // remove warn to avoid unnecessary logs
        // console.warn("Couldn't parse CMS JSON", cmsArrayElement);
      }
    }
    // console.warn('No identifier or content found', cmsArrayElement);
    return cmsObject;
  }, {});

const contentGetters = {
  getIdentifier,
  getContent,
  getMetaTitle,
  getMetaDescription,
  getContentHeading,
  getTitle,
  getCmsBlockContent,
};

export default contentGetters;
