import { productGetters } from '@gemini-vsf/composables';
import { Product } from '@gemini-vsf/api-client';

const useUrlHelpers = () => {
  const buildProductUrl = (product: Product): string => {
    const productSlug = productGetters.getSlug(product);
    return `${productSlug}`;
  };

  return {
    buildProductUrl,
  };
};

export default useUrlHelpers;
