<template>
  <div class="layout-default">
    <div>
      <IconSprite />
      <WishlistSidebar />
      <CartSidebar />
      <LoginModal v-if="isLoginModalOpen" />
      <CountryModal />
      <GeoLocatorModal
        v-if="localizedCountry"
        :localized-country="localizedCountry"
      />
      <LazyHydrate when-visible>
        <Notification />
      </LazyHydrate>
      <AppHeader :check-sticky="checkSticky" />
      <div id="layout">
        <nuxt :key="route.fullPath" />
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import {
  useRoute,
  defineComponent,
  ref,
  useFetch,
  onMounted,
  useContext,
  computed,
} from '@nuxtjs/composition-api';
import { useUser } from '@gemini-vsf/composables';
import AppHeader from '~/components/AppHeader.vue';
import CartSidebar from '~/components/CartSidebar.vue';
import LoginModal from '~/components/LoginModal.vue';
import CountryModal from '~/components/CountryModal.vue';
import {
  useCart,
  useConfig,
  useGeoLocation,
  useUiState,
  useWishlist,
  useMarketsComposable,
  useUiNotification,
} from '~/composables';
import WishlistSidebar from '~/components/WishlistSidebar.vue';
import Notification from '~/components/Notification.vue';
import AppFooter from '~/components/AppFooter.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import { useI18n } from '~/helpers/hooks/usei18n';
import { botsRegExp } from '~/helpers/util';
import GeoLocatorModal from '~/components/General/GeoLocatorModal.vue';
import handleMarketsAndLocales from '~/helpers/geminiConfig/handleMarketsAndLocales';

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    LazyHydrate,
    AppHeader,
    WishlistSidebar,
    AppFooter,
    CartSidebar,
    LoginModal,
    Notification,
    CountryModal,
    IconSprite,
    GeoLocatorModal,
  },
  setup() {
    const { isLoginModalOpen } = useUiState();

    const route = useRoute();
    const { loadConfig, siteConfigData } = useConfig();
    const { isAuthenticated } = useUser();
    const { loadWishlist } = useWishlist();
    const { load: loadCart } = useCart();
    const checkSticky = ref(false);
    const { currentMarket } = useMarketsComposable();
    const trans = useI18n();
    const { send: sendNotification } = useUiNotification();

    const {
      $vsf: {
        $gemini: { config },
      },
      app,
      app: {
        $device: { userAgent },
      },
    } = useContext();

    const { updateMarketAndLocaleCookies } = handleMarketsAndLocales();
    const { getLocation, location, error: locatorError } = useGeoLocation();
    const {
      toggleGeoLocatorModal,
      isCountrySidebarOpen,
      toggleCountryModal,
      toggleLoginModal,
    } = useUiState();
    const localizedCountry = ref(null);
    const canUseGeoLocator = computed(
      () =>
        !botsRegExp.test(userAgent) &&
        config?.geoLocator?.enable &&
        process &&
        process.client &&
        localStorage
    );
    const wishlistCustomQueries = computed(() => ({
      customQuery: isAuthenticated.value
        ? {
            customerWishlist: 'customerWishlistCustom',
          }
        : {
            wishlist: 'wishlistCustom',
          },
    }));
    const cookiebot = process && process.browser && window && window.Cookiebot;

    const VSF_LOCALIZED_COUNTRY = 'vsf-localized-country';
    const VSF_COUNTRY_SELECTED = 'vsf-country-selected';

    const scroll = () => {
      window.addEventListener('scroll', () => {
        const st = window.scrollY;
        checkSticky.value = st >= Number.parseInt('160', 10);
      });
    };

    /**
     * Handles the geolocation functionality for the country selection sidebar.
     * If the user's location can be determined and the localized country is different from the current locale,
     * the geolocation modal is toggled to prompt the user to select their country.
     * If the user's location cannot be determined or there is an error with the geolocation API,
     * the country selection sidebar is toggled to allow the user to manually select their country.
     * If the user has previously interacted with the geolocation modal, it is not shown again.
     * @async
     * @function handleGeoLocation
     */
    const handleGeoLocation = async () => {
      let countrySelected = localStorage.getItem(VSF_COUNTRY_SELECTED);
      const parsedCountrySelected = countrySelected
        ? JSON.parse(countrySelected)
        : null;
      if (
        parsedCountrySelected &&
        parsedCountrySelected.value !== currentMarket.value.code
      ) {
        localStorage.setItem(VSF_COUNTRY_SELECTED, null);
        countrySelected = null;
      }
      if (
        canUseGeoLocator.value &&
        (localStorage.getItem(VSF_LOCALIZED_COUNTRY) === null ||
          countrySelected === null ||
          (parsedCountrySelected && !parsedCountrySelected.value))
      ) {
        await getLocation();
        if (locatorError.value) {
          console.error(`Error in GeoLocator API:`, locatorError.value);
          if (
            countrySelected === null ||
            (parsedCountrySelected && !parsedCountrySelected.value)
          ) {
            toggleCountryModal();
          }
        } else {
          localStorage.setItem(
            VSF_LOCALIZED_COUNTRY,
            JSON.stringify({
              label: location.value?.country_name,
              code: location.value?.country_code?.toLowerCase(),
              flag: location.value?.location?.country_flag,
            })
          );
          localizedCountry.value = {
            label: location.value?.country_name,
            code: location.value?.country_code?.toLowerCase(),
            flag: location.value?.location?.country_flag,
          };
          if (
            localizedCountry.value.code &&
            localizedCountry.value.code !== currentMarket.value.urlCode
          ) {
            toggleGeoLocatorModal();
          }
        }
      } else if (
        canUseGeoLocator.value &&
        localStorage.getItem(VSF_LOCALIZED_COUNTRY) !== null
      ) {
        localizedCountry.value = JSON.parse(
          localStorage.getItem(VSF_LOCALIZED_COUNTRY)
        );
        if (
          localizedCountry.value &&
          !localizedCountry.value?.interacted &&
          localizedCountry.value.code &&
          localizedCountry.value.code !== currentMarket.value.urlCode
        ) {
          toggleGeoLocatorModal();
        }
      }
    };

    useFetch(async () => {
      await loadConfig();
    });

    onMounted(async () => {
      updateMarketAndLocaleCookies(app, route.value);
      await loadCart();
      loadWishlist(wishlistCustomQueries.value);
      scroll();
      handleGeoLocation();

      if (route?.value?.query?.register || route?.value?.query?.login) {
        toggleLoginModal();
      }

      if (cookiebot) {
        window.addEventListener(
          'CookiebotOnDecline',
          () => {
            if (window.CookiebotDialog) window.location.reload();
          },
          false
        );
      }

      const workbox = await window?.$workbox;
      if (workbox) {
        workbox.addEventListener('installed', (event) => {
          if (event.isUpdate) {
            sendNotification({
              id: Symbol('refresh_to_update'),
              message: trans.t(
                'A new version of the website is available. Please refresh the page to update.'
              ),
              type: 'success',
              hideCloseIcon: true,
              hideIcon: true,
              persist: true,
              title: trans.t('Refresh to update'),
              action: {
                onClick: () => {
                  window.location.reload();
                },
                text: trans.t('REFRESH'),
              },
            });
          }
        });
      }
    });

    return {
      isLoginModalOpen,
      route,
      loadConfig,
      checkSticky,
      siteConfigData,
      localizedCountry,
      isCountrySidebarOpen,
      toggleGeoLocatorModal,
      isAuthenticated,
    };
  },
});
</script>

<style lang="scss">
@import '../assets/style/default.scss';
@import '../assets/style/variables.scss';
</style>
