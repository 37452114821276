<template>
  <div id="wishlist" class="wishlist-mini">
    <SfSidebar
      :visible="isWishlistSidebarOpen"
      :button="false"
      title=""
      class="sidebar sf-sidebar--right"
      @close="toggleWishlistSidebar"
    >
      <template #circle-icon="{ close }">
        <div class="close-icon" @click="close">
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.414 1.414L21.414 21.414"
              stroke="#0F0F0F"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M21.414 1.414L1.414 21.414"
              stroke="#0F0F0F"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </template>
      <template #content-top>
        <div class="wishlist-head-wrapper">
          <h2>{{ $t('My wishlist') }}</h2>
          <SfProperty
            v-if="totalItems"
            class="sf-property--large cart-summary"
            :name="$t('Total articles')"
            :value="totalItems"
          />
        </div>
        <p v-if="!isAuthenticated" class="wishlist-guest-message">
          {{ $t('Accedi al tuo account per salvare la wishlist') }}
        </p>
      </template>
      <transition name="fade" mode="out-in">
        <div v-if="totalItems" key="my-wishlist" class="my-cart">
          <div class="collected-product-list">
            <div
              v-for="(wishlistItem, i) in wishlistItems"
              :key="i"
              class="cart-item-list"
            >
              <div class="cart-item-media">
                <figure>
                  <SfLink
                    class="cart-item-media"
                    :link="
                      fixUrlForCurrentLocale(
                        buildProductUrl(wishlistItem.product)
                      )
                    "
                  >
                    <nuxt-img
                      :src="`${wishlistGetters.getItemImage(
                        wishlistItem
                      )}w=300`"
                      :alt="wishlistGetters.getItemName(wishlistItem)"
                      :width="140"
                      @error="handleImageError"
                      :height="200"
                      class="sf-collected-product__image"
                      preload
                      loading="lazy"
                    />
                  </SfLink>
                </figure>
              </div>
              <div class="cart-item-details">
                <div class="cart-item-content">
                  <nuxt-link
                    class="cart-item-brand"
                    :to="getProductBrandUrl(wishlistItem.product)"
                    >{{ productBrandName(wishlistItem.product) }}</nuxt-link
                  >
                  <nuxt-link
                    class="cart-item-name"
                    :to="
                      fixUrlForCurrentLocale(
                        buildProductUrl(wishlistItem.product)
                      )
                    "
                  >
                    {{ wishlistGetters.getItemName(wishlistItem) }}
                  </nuxt-link>
                  <div
                    class="cart-item-configuration"
                    v-if="isConfigured(wishlistItem)"
                  >
                    <SfProperty
                      v-for="option in getProductConfiguration(wishlistItem)"
                      :key="option.uid"
                      :name="option.label"
                      :value="option.value"
                    />
                  </div>
                </div>
                <div class="cart-item-button">
                  <div class="sfprice__module">
                    <SfPrice :regular="getWishlistPrice(wishlistItem)" />
                  </div>
                  <div class="cart-item-remove">
                    <a @click="removeItemWishlist(wishlistItem)">
                      {{ $t('Remove') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else key="empty-wishlist" class="empty-wishlist">
          <div class="empty-wishlist__banner">
            <SfHeading
              :title="$t('Your bag is empty')"
              :description="
                $t('Looks like you haven’t added any items to the Wishlist.')
              "
              class="empty-wishlist__label"
            />
          </div>
        </div>
      </transition>
      <SfLoader v-if="isLoaderVisible" :loading="isLoaderVisible">
        <div />
      </SfLoader>
      <template #content-bottom>
        <nuxt-link :to="fixUrlForCurrentLocale('/wishlist')">
          <SfButton
            class="sf-button--full-width color-secondary button-go-to-cart"
            @click="toggleWishlistSidebar"
          >
            {{ $t('Go to Wishlist') }}
          </SfButton>
        </nuxt-link>
      </template>
    </SfSidebar>
  </div>
</template>

<script>
import SfSidebar from '~/components/storefront-ui/organisms/SfSidebar.vue';
import {
  SfHeading,
  SfButton,
  SfIcon,
  SfProperty,
  SfLink,
  SfLoader,
} from '@storefront-ui/vue';
import {
  ref,
  computed,
  defineComponent,
  onMounted,
  useContext,
} from '@nuxtjs/composition-api';
import {
  useUser,
  wishlistGetters,
  productGetters,
} from '@gemini-vsf/composables';
import {
  useUiState,
  useImage,
  useUrlHelpers,
  useWishlist,
} from '~/composables';
import SfPrice from '~/components/storefront-ui/atoms/SfPrice/SfPrice.vue';
import { useCurrentLocale } from '~/composables/useCurrentLocale';
import { productBrandUrl } from '~/helpers/url/brand';

export default defineComponent({
  name: 'WishlistSidebar',
  components: {
    SfSidebar,
    SfButton,
    SfLoader,
    SfHeading,
    SfIcon,
    SfProperty,
    SfLink,
    SfPrice,
  },
  data() {
    return {
      priceClass: 'product-card-detail__price__current',
    };
  },
  setup() {
    const { wishlist, loadWishlist, removeItemFromWishlist, updateItem } =
      useWishlist('sidebar-items');
    const { buildProductUrl } = useUrlHelpers();
    const { isWishlistSidebarOpen, toggleWishlistSidebar } = useUiState();
    const { isAuthenticated } = useUser();
    const wishlistItems = computed(() =>
      wishlistGetters.getItems(wishlist.value)
    );
    const isLoaderVisible = ref(false);
    const totals = computed(() => wishlistGetters.getTotals(wishlist.value));
    const totalItems = computed(() =>
      wishlistGetters.getTotalItems(wishlist.value)
    );
    const { app } = useContext();
    const localeCode = app.i18n.localeProperties.localeValue;
    const getAttributes = (product) =>
      product?.product?.configurable_options || [];

    const { getGeminiImage, imageSizes } = useImage();

    const { fixUrlForCurrentLocale } = useCurrentLocale();

    const changeItemQuantity = (wishlistItem, quantity) => {
      if (quantity > 0) {
        updateItem({
          product: wishlistItem.product,
          quantity,
        });
      } else {
        removeItemFromWishlist(wishlistItem);
      }
    };

    const isConfigurable = (wishlistItem) =>
      wishlistItem.product?.vsf_typename === 'ConfigurableProduct';
    const isConfigured = (wishlistItem) =>
      wishlistItem?.item_grn?.split('/').length > 1;
    const getProductConfiguration = (wishlistItem) => {
      if (!isConfigured(wishlistItem)) {
        return [];
      }
      const productGrn = wishlistItem.item_grn;
      const configurableOptions =
        wishlistItem.product?.configurable_options || [];
      const variant = wishlistItem.product.variants.find(
        (item) => item.product.uid === productGrn
      );

      const optionValues = variant?.option_uids.map((item) =>
        configurableOptions
          .map((option) => {
            const value = option.values.find(
              (optionValue) => optionValue.uid === item
            );
            if (value) {
              return {
                uid: value.uid,
                label: option.label,
                value: value.label,
                swatches: value.swatches,
              };
            }
            return null;
          })
          .find((option) => option?.uid === item)
      );
      return optionValues.length > 0 ? optionValues : [];
    };
    const canAddToCart = (wishlistItem) => {
      if (isConfigurable(wishlistItem)) {
        return (
          (isConfigured(wishlistItem) &&
            wishlistItem.product.configurable_product_options_selection?.variant
              ?.stock_status === 'IN_STOCK') ||
          (isConfigured(wishlistItem) && wishlistItem.product.is_virtual)
        );
      }

      const inStock = wishlistItem.product?.stock_status || '';
      const stockLeft =
        wishlistItem.product?.only_x_left_in_stock === null
          ? true
          : wishlistItem.qty <= wishlistItem.product?.only_x_left_in_stock;
      return inStock && stockLeft;
    };
    const removeItemWishlist = async (product) => {
      isLoaderVisible.value = true;
      const variant = getProductConfiguration(product);
      if (variant.length > 0) {
        const getProduct = product.product.variants.find(
          (x) => x.option_uids[0] === variant[0].uid
        );
        await removeItemFromWishlist({ product: getProduct.product });
      } else {
        await removeItemFromWishlist({ product: product.product });
      }
      isLoaderVisible.value = false;
      loadWishlist({
        pageSize: 48,
        customQuery: isAuthenticated.value
          ? {
              customerWishlist: 'customerWishlistCustom',
            }
          : {
              wishlist: 'wishlistCustom',
            },
      });
    };
    onMounted(() => {
      loadWishlist({
        pageSize: 48,
        customQuery: isAuthenticated.value
          ? {
              customerWishlist: 'customerWishlistCustom',
            }
          : {
              wishlist: 'wishlistCustom',
            },
      });
    });

    return {
      getAttributes,
      isAuthenticated,
      isWishlistSidebarOpen,
      isLoaderVisible,
      wishlistItems,
      removeItemFromWishlist,
      toggleWishlistSidebar,
      totalItems,
      totals,
      wishlistGetters,
      wishlist,
      productGetters,
      getGeminiImage,
      imageSizes,
      localeCode,
      buildProductUrl,
      changeItemQuantity,
      isConfigurable,
      canAddToCart,
      isConfigured,
      getProductConfiguration,
      removeItemWishlist,
      fixUrlForCurrentLocale,
    };
  },
  methods: {
    productBrandName(product) {
      return product?.brand_attribute?.label.toLowerCase() || '';
    },
    getProductBrandUrl(product) {
      return productBrandUrl(product, this.localeCode);
    },
    handleImageError(event) {
      event.target.src = '/icons/placeholder.svg';
    },
    getWishlistPrice(wishlistItem) {
      let price =
        wishlistItem.product?.price_range?.minimum_price?.regular_price
          ?.value || 9999;
      let discountedPrice =
        wishlistItem.product?.price_range?.minimum_price?.final_price?.value ||
        9999;
      if (discountedPrice < price) {
        this.priceCurrent = 'product-card-detail__price__old';
        return discountedPrice;
      } else {
        this.priceCurrent = 'product-card-detail__price__current';
        return price;
      }
    },
    productSKU(wishlistItem) {
      return wishlistItem?.product?.merchant_sku || '';
    },
  },
});
</script>

<style lang="scss" scoped>
#wishlist {
  --sidebar-z-index: 5;
  --overlay-z-index: 3;
  @include for-desktop {
    & > * {
      --sidebar-bottom-padding: var(--spacer-base);
      --sidebar-content-padding: var(--spacer-base);
    }
  }
}
.close-icon {
  cursor: pointer;
}
.notifications {
  position: fixed;
  right: 20px;
  top: 20px;
  margin-left: -350px;
  z-index: 99999;
  .sf-notification {
    padding: 20px;
    .button-wrap {
      margin-top: 15px;
      display: flex;
      column-gap: 15px;
    }
  }
}

.my-cart {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__total-items {
    margin: 0;
  }

  &__total-price {
    --price-font-size: var(--font-size--xl);
    --price-font-weight: var(--font-weight--medium);
    margin: 0 0 var(--spacer-base) 0;
  }
}

.empty-wishlist {
  --heading-description-margin: 0 0 var(--spacer-xl) 0;
  --heading-title-margin: 0 0 var(--spacer-xl) 0;
  --heading-title-color: var(--c-primary);
  --heading-title-font-weight: var(--font-weight--semibold);
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;

  &__banner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  &__heading {
    padding: 0 var(--spacer-base);
  }

  &__image {
    --image-width: 16rem;
    margin: 0 0 var(--spacer-2xl) 7.5rem;
  }

  @include for-desktop {
    --heading-title-font-size: var(--font-size--xl);
    --heading-title-margin: 0 0 var(--spacer-sm) 0;
  }
}
.collected-product-list {
  flex: 1;
}
.collected-product {
  margin: 0 0 var(--spacer-sm) 0;

  &__properties {
    margin: var(--spacer-xs) 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 2;

    &:first-child {
      margin-bottom: 8px;
    }
  }

  &__actions {
    transition: opacity 150ms ease-in-out;
  }

  &__save,
  &__compare {
    --button-padding: 0;

    &:focus {
      --cp-save-opacity: 1;
      --cp-compare-opacity: 1;
    }
  }

  &__save {
    opacity: var(--cp-save-opacity, 0);
  }

  &__compare {
    opacity: var(--cp-compare-opacity, 0);
  }

  &:hover {
    --cp-save-opacity: 1;
    --cp-compare-opacity: 1;
    @include for-desktop {
      .collected-product__properties {
        display: none;
      }
    }
  }
}

.wishlist-mini {
  .wishlist-guest-message {
    font-family: var(--barlow-regular);
    font-size: 18px;
    line-height: 20px;
  }
  .wishlist-head-wrapper {
    display: flex;
  }
  .cart-item-list {
    display: flex;
    padding: 30px 50px;
    border-bottom: 1px solid #ededed;
    margin: 0;
    &::after {
      display: none !important;
    }
    &:last-child {
      margin-bottom: 0;
    }

    .cart-item-media {
      width: 114px;
      min-width: 114px;
    }

    .cart-item-details {
      display: block;
      padding-left: 20px;
      width: 100%;
      @media (max-width: 768px) {
        padding-left: 15px;
      }

      > div,
      > h3 {
        height: max-content;
      }

      .cart-item-sku p {
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        text-transform: uppercase;
      }
      a {
        text-decoration: none;
      }
      .cart-item-brand {
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0;
        line-height: 17px;
        color: #161615;
        text-decoration: none;
        margin: 0 0 5px 0;
        font-family: var(--barlow-semibold);
        text-transform: uppercase;
        display: block;
      }
      .cart-item-name {
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0;
        line-height: 17px;
        color: #161615;
        display: block;
        text-decoration: none;
        margin: 0 0 5px 0;
        font-family: var(--barlow-regular);
        text-transform: uppercase;
      }
      .cart-item-price {
        padding-top: 18px;
        margin-bottom: 15px;
        font-family: 'Teko';
        text-align: right;
        display: flex;
        justify-content: flex-end;

        > p {
          display: inline-block;
          text-align: right;
          font-size: 28px;
          font-weight: normal;
          letter-spacing: 0;
          line-height: 34px;
          color: #3e3e3a;
        }

        .cart-item-price__old {
          text-decoration-line: line-through;
          color: #dd380d;
          font-size: 18px;
          margin-right: 60px;
          order: -1;
          @media (max-width: 768px) {
            margin-right: 20px;
          }
        }

        .cart-item-price__special,
        p:first-child {
          text-decoration-line: none;
        }

        .cart-item-price__special:empty {
          color: red;
          padding-right: 0;
        }
      }

      .cart-item-remove {
        display: flex;
        a {
          color: #9d9d9d;
          font-size: 11px;
          text-transform: uppercase;
          font-family: var(--barlow-regular);
          margin-left: auto;
          cursor: pointer;
          &:hover {
            color: #000;
          }
        }
      }

      .cart-item-button {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  @media (max-width: 1023px) {
    .cart-item-list {
      .cart-item-details {
        .cart-item-sku p {
          font-size: 15px;
          line-height: 16px;
        }

        .cart-item-name {
          font-size: 18px;
          line-height: 24px;
        }

        .cart-item-price {
          padding-top: 5px;
          margin-bottom: 5px;
          > p {
            font-size: 28px;
          }
        }
        .cart-item-button {
          max-width: 300px;
          flex-wrap: nowrap;
          margin-left: auto;
        }
      }
    }
  }
}
.sf-sidebar__aside {
  .sf-notification {
    max-width: 387px;
  }
  .sf-sidebar__top {
    h2 {
      margin: 0;
      color: #0f0f0f;
      font-family: var(--barlow-regular);
      font-size: 30px;
      line-height: 36px;
      text-transform: uppercase;
    }
  }
}
</style>

<style lang="scss">
.wishlist-mini {
  position: relative;
  z-index: 100;
  .sf-loader {
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 11;
    position: absolute;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    .sf-loader__overlay {
      background: none;
    }
  }
  .sf-sidebar-top {
    align-items: baseline;
  }
}

.wishlist-mini .sf-header__logo {
  display: flex;
}

.wishlist-mini .sf-image--wrapper {
  width: 100%;
}

.wishlist-mini .sf-image--wrapper.empty-wishlist__image {
  margin: 0;
}

.wishlist-mini .cart-item-media img {
  height: auto;
  width: 100%;
  max-width: 150px;
  max-height: 150px;
  mix-blend-mode: darken;
}

.wishlist-mini p {
  margin: 0;
}

.wishlist-mini figure {
  display: flex;
  margin: 0;
  width: 100%;
  align-items: center;
}

.wishlist-mini .sf-collected-product__actions {
  display: none;
}

.wishlist-mini {
  padding-left: 0;
  padding-right: 0;
}

.wishlist-mini .sf-sidebar__top {
  display: block;
  padding: 0;
}

.wishlist-mini .cart-summary {
  align-items: center;
}

.wishlist-mini .sf-sidebar__content {
  padding: 0 0;
}

.wishlist-mini .sf-collected-product {
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
  margin-bottom: 24px;
}

.wishlist-mini .sf-collected-product__quantity-wrapper {
  position: static;
  height: auto;
  padding: 0;
  margin-bottom: 30px;
  .sf-quantity-selector {
    background: #f8f8f8;
    height: auto;
    align-items: start;
    input {
      background: #f8f8f8;
      height: auto;
    }
  }
}

.wishlist-mini
  .sf-collected-product__quantity-selector
  .sf-quantity-selector__button {
  font-weight: 300;
  width: 30px;
  padding: 0;
  font-size: 33px;
  line-height: 43px;
  height: auto;
  font-family: 'Teko';
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0f0f0f;
  height: 30px;
}

.wishlist-mini .sf-icon {
  margin: auto;
}

.wishlist-mini .sf-input {
  width: 40px;
  padding: 0 5px;
  font-size: 14px;
  line-height: 17px;
  color: #000;
  font-family: var(--barlow-semibold);
  min-height: inherit;
  height: 30px;
  border: solid 1px #161615;
}

.wishlist-mini ~ .notifications .sf-notification__message {
  color: #fff;
  padding: 0 30px 0 10px;
}

.wishlist-mini .notifications .sf-button {
  padding: 10px 30px;
  height: auto;
  font-family: var(--barlow-semibold);
  background: #161615;
  &:hover {
    background: #2c2c2b;
  }
}

.wishlist-mini .sf-sidebar__bottom .sf-button.color-secondary:hover,
.wishlist-mini .sf-sidebar__bottom .sf-button.color-secondary:active,
.wishlist-mini .sf-sidebar__bottom .sf-button.color-secondary:focus,
.wishlist-mini .sf-sidebar__bottom .sf-button.color-primary:hover,
.wishlist-mini .sf-sidebar__bottom .sf-button.color-primary:active,
.wishlist-mini .sf-sidebar__bottom .sf-button.color-primary:focus,
.wishlist-mini .notifications .sf-button:hover,
.wishlist-mini .notifications .sf-button:active,
.wishlist-mini .notifications .sf-button:focus {
  text-decoration-line: none;
}

.wishlist-mini .cart-item-list .sf-property {
  margin-right: 10px;
  .sf-property__name {
    color: #9d9d9d;
    font-size: 11px;
    line-height: 13px;
    margin-right: 5px;
    font-family: var(--barlow-regular);
    text-transform: uppercase;
  }
  .sf-property__value {
    color: #161615;
    font-size: 11px;
    line-height: 13px;
    font-family: var(--barlow-regular);
    text-transform: uppercase;
  }
}
.wishlist-mini .cart-item-configuration {
  display: flex;
  padding: 10px 0 15px 0;
  font-family: var(--barlow-regular);
}
.wishlist-mini .sf-input input {
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  font-family: var(--barlow-regular);
  color: #000;
}

.wishlist-mini .sf-input__error-message {
  display: block;
  left: -72px;
  min-width: 210px;
  position: absolute;
  width: 100%;
  font-size: 16px;
  @media (max-width: 768px) {
    min-width: inherit;
    left: 0;
    padding: 0 25px;
  }
}

.wishlist-mini .sf-input input:focus-visible {
  outline: 0;
  border: 0;
}
.empty-wishlist__banner {
  padding: 0 30px;
}
.wishlist-mini .empty-wishlist__banner .sf-heading__title.h2 {
  color: #000;
  font-family: var(--barlow-regular);
}
.sf-heading__description {
  font-family: var(--barlow-regular);
  font-size: 18px;
  line-height: 20px;
}

.wishlist-mini .notifications .sf-icon {
  margin-right: 20px;
}

.wishlist-mini .notifications .sf-icon svg {
  --icon-color: #212a24;
}

.wishlist-mini .sf-notification__message,
.wishlist-mini .sf-notification__title {
  color: #fff;
}

.wishlist-mini .sf-sidebar__aside {
  height: 100%;
}

.sf-badge--number {
  background-color: #000;
  height: 15px;
  width: 15px;
  font-size: 9px;
  color: #fff;
  font-weight: 700;
  line-height: 13px;
  position: absolute;
  right: -8px;
  font-family: 'Lato';
  top: -6px;
  bottom: inherit;
  left: inherit;
  font-size: 8px;
  justify-content: center;
  min-height: 15px;
  min-width: 15px;
  align-items: center;
  display: flex;
}

.wishlist-mini .button-go-to-cart {
  margin-top: 15px;
  background: #fff;
  border-color: #000;
  color: #000;
  font-size: 16px;
  padding: 18px 10px;
  height: var(--button-height);
  font-family: var(--barlow-regular);
  &:hover {
    background: #000;
    color: #fff;
  }
}
.wishlist-mini .sf-sidebar__aside {
  padding: 0;
  background-color: #ffff;
  @media (max-width: 768px) {
    padding: 0;
  }
}
.wishlist-mini {
  .sf-sidebar__bottom {
    padding: 50px;
    .sf-btn-blue {
      border: solid 1px #9d9d9d;
      background: #fff;
      color: #161615;
      font-size: 16px;
      font-family: var(--barlow-regular);
      padding: 18px 10px;
      height: auto;
    }
    a {
      text-decoration: none;
      .button-go-to-cart {
        background: #161615;
        color: #fff;
        border: solid 1px #161615;
        span {
          margin-left: 5px;
        }
      }
    }
  }
}
@media (min-width: 1024px) {
  .wishlist-mini .sf-sidebar__aside {
    width: 430px;
  }

  .wishlist-mini .sf-collected-product {
    max-width: 100%;
  }

  .wishlist-mini .sf-collected-product__configuration {
    justify-content: flex-start;
    padding-bottom: 50px;
  }

  .wishlist-mini .header-mini-cart {
    display: none;
  }

  .wishlist-mini .sf-sidebar__bottom .sf-button.color-primary {
    max-width: 439px;
  }
}

@media (max-width: 1023px) {
  .wishlist-mini .cart-item-content div {
    height: max-content;
    line-height: 0;
  }

  .wishlist-mini .sf-collected-product__quantity-selector {
    height: 40px;
  }

  .wishlist-mini .sf-collected-product__quantity-selector button {
    font-size: 30px;
    flex: 30%;
  }

  .wishlist-mini .sf-collected-product__quantity-selector .sf-input {
    flex: 40%;
    min-height: auto;
    height: 100%;
  }

  .wishlist-mini .sf-sidebar__top .cart-summary.desktop-only {
    display: block !important;
  }

  .wishlist-mini .cart-summary {
    border-bottom: 0;
    margin: 0;
  }

  .wishlist-mini .sf-sidebar__top {
    padding-bottom: 0;
  }

  .wishlist-mini .sf-bar__icon {
    display: none;
  }

  .wishlist-mini .sf-bar {
    background: #fff;
    border-bottom: 1px solid #e9ecf1;
    height: 54px;
  }
  .wishlist-mini .sf-input input {
    font-size: 18px;
    height: 100%;
    line-height: 45px;
  }

  .wishlist-mini .sf-sidebar__bottom .sf-button.color-secondary,
  .wishlist-mini .sf-sidebar__bottom .sf-button.color-primary {
    letter-spacing: 0;
    height: var(--button-height);
    line-height: var(--button-height);
  }

  .wishlist-mini .notifications {
    right: 20px;
    margin: 0;
    top: 20px;
    transform: inherit;
  }

  .wishlist-mini .sf-header__item_right {
    display: none;
  }

  .wishlist-mini .header-mini-cart {
    left: 0;
    position: absolute;
    top: -14px;
  }

  .wishlist-mini .header-mini-cart .sf-header-custom_container {
    position: absolute;
    top: -47px;
  }

  .wishlist-mini #overlay_menu,
  .wishlist-mini #mega_menu {
    top: 0;
  }
}

@media (max-width: 767px) {
  .wishlist-mini .header-mini-cart {
    top: -13px;
  }

  .wishlist-mini .my-cart .cart-item-list {
    flex-wrap: nowrap;
  }
}
</style>
