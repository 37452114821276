import { computed } from '@nuxtjs/composition-api';
import axios from 'axios';

export const useOrder = () => {
  const nuxtEndpoint = computed(() => (typeof window === 'undefined' ? 'http://localhost:3000' : window.location.origin));
  const getOrderShippingMethod = async (id: string) => {
    try {
      const response = await axios.get(`${nuxtEndpoint.value}/api/gemini/v2/getOrderShippingMethod`, {
        params: {
          id,
        },
        withCredentials: true,
      });
      return response?.data?.shippingMethod;
    } catch (error) {
      console.error('useOrder ~ getOrderShippingMethod ~ error:', error);
    }
    return null;
  };

  return { getOrderShippingMethod };
};
