import { sharedRef } from '@vue-storefront/core';
import { useWishlist as useWishlistComposable } from '@gemini-vsf/composables';
import { Product, WishlistItem } from '@gemini-vsf/api-client';
import productDetailQuery from '~/helpers/product/productDetailQuery';
import { useCart, useApi } from '~/composables';

const useWishlist = (productList) => {
  const { query } = useApi();
  const { addItem: addProductToCart } = useCart();
  const { wishlist, load: loadWishlist, removeItem, addItem, hasItemsInWishlist, updateItem, isInWishlist } = useWishlistComposable();

  const itemsInWishlist = sharedRef<Record<string, boolean>>({}, 'useWishlist-itemsInWishlist');

  const filterProductSkus = (products?) => {
    const productsToFilter = products || productList.value;
    return productsToFilter
      .filter((product) => !product.isMoreItem)
      .reduce((acc, product) => {
        acc.push(product.sku);

        if (product?.variants) {
          product.variants.forEach((item) => {
            acc.push(item.product?.sku);
          });
        }

        return acc;
      }, []);
  };

  const pushItems = (items) => {
    itemsInWishlist.value = { ...itemsInWishlist.value, ...items };
  };

  const loadItemsFromList = async (initialProductList) => {
    // todo check if we can use some sort of cache here
    pushItems(await hasItemsInWishlist({ items: filterProductSkus(initialProductList) }));
  };

  const toggleWishlist = async (product: Product) => {
    await (itemsInWishlist.value[product.sku] ? removeItem({ product }) : addItem({ product, quantity: 1 }));
    pushItems(await hasItemsInWishlist({ items: filterProductSkus([product]) }));
  };

  const addItemToCart = async (wishlistItem: WishlistItem) => {
    const productGrn = wishlistItem.item_grn;
    const productId = wishlistItem.product?.uid.split('::')?.[1];
    const variant = wishlistItem.product.variants.find((item) => item.product.uid === productGrn);
    const searchQuery = {
      filter: {
        uid: productId,
      },
      configurations: variant.option_uids as string[],
    };
    const { data } = await query(productDetailQuery, searchQuery);
    const product = data['productDetail'];
    return await addProductToCart({ product, quantity: wishlistItem.qty });
  };

  return {
    itemsInWishlist,
    loadItemsFromList,
    toggleWishlist,
    removeItemFromWishlist: removeItem,
    addItemToWishlist: addItem,
    addItemToCart,
    wishlist,
    loadWishlist,
    updateItem,
    isInWishlist,
  };
};

export default useWishlist;
