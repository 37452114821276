import { sharedRef } from '@vue-storefront/core';
import axios from 'axios';

const useSizeCharts = (key = 'size-charts') => {
  const sizeChart = sharedRef<string>('', key);

  const getSizeChart = async (params) =>
    await axios({
      method: 'post',
      url: `/api/get-size-chart`,
      data: params,
    }).then((res) => res.data);

  const loadSizeChart = async (params): Promise<void> => {
    try {
      sizeChart.value = '';
      sizeChart.value = await getSizeChart(params);
    } catch (error) {
      console.warn('Unable to render size chart:', error);
      sizeChart.value = '';
    }
  };

  return {
    loadSizeChart,
    sizeChart,
  };
};

export default useSizeCharts;
