import { useContext, ref, Ref, computed, useFetch } from '@nuxtjs/composition-api';
import { LocaleObject } from 'nuxt-i18n';
import { useI18n } from '~/helpers/hooks/usei18n';

export interface MarketOption {
  label: string;
  value: string;
  code: string;
  marketCode: string;
}

const useMarketsComposable = () => {
  const allAvailableCountries: Ref<MarketOption[] | []> = ref([]);

  const {
    $vsf: {
      $gemini: { config },
    },
    app: {
      $i2c: countryIsoToName,
      i18n: { locales: markets, localeProperties },
    },
  } = useContext();
  const trans = useI18n();

  const currentMarket = computed(() => localeProperties);

  const isUeMarket = computed(() => config['ueMarkets']?.includes(localeProperties?.uid));

  const countryIsoToMarketCode = (countryIso: string): string => {
    return (markets as LocaleObject[]).find((m: LocaleObject) => m.countries.includes(countryIso)).urlCode;
  };

  const loadAllAvailableCountries = () => {
    allAvailableCountries.value = [...new Set(markets.flatMap((market) => market.countries).filter(Boolean))]
      .map((code) => {
        const marketCode = countryIsoToMarketCode(code);
        return {
          label: code === 'CN' ? trans.t('Republic of China') : countryIsoToName(code),
          code: code,
          marketCode: marketCode,
          value: `${marketCode}-${code}`,
        };
      })
      .filter((c) => c.label && c.code && c.marketCode && c.value)
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  const languageMap = new Map(Object.entries({ it: 'Italian', en: 'English' }));

  useFetch(() => {
    if (allAvailableCountries.value?.length === 0) {
      loadAllAvailableCountries();
    }
  });

  return {
    markets,
    isUeMarket,
    languageMap,
    currentMarket,
    allAvailableCountries,
  };
};

export default useMarketsComposable;
