import { ref } from '@nuxtjs/composition-api';
// import type { Currency, StoreConfig } from '~/modules/GraphQL/types';

// import { useApi } from '~/composables/useApi';

// type StoresAndCurrencyQueryResponse = {
//   availableStores: Pick<{ store_code: string }, 'store_code'>[];
//   currency: Pick<{ available_currency_codes: string }, 'available_currency_codes'>;
// };

export const useTopBar = () => {
  // const { query } = useApi();
  const hasStoresToSelect = ref(null);
  const hasCurrencyToSelect = ref(null);

  // onMounted(() => {
  //   query<StoresAndCurrencyQueryResponse>(checkStoresAndCurrencyQuery)
  //     .then((response) => {
  //       // eslint-disable-next-line promise/always-return
  //       hasStoresToSelect.value = response?.data?.availableStores.length > 1 ?? false;
  //       // eslint-disable-next-line promise/always-return
  //       hasCurrencyToSelect.value = response?.data?.currency.available_currency_codes.length > 1 ?? false;
  //     })
  //     .catch(() => {
  //       hasStoresToSelect.value = false;
  //       hasCurrencyToSelect.value = false;
  //     });
  // });

  return {
    hasStoresToSelect,
    hasCurrencyToSelect,
  };
};

export default useTopBar;
