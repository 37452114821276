import { LocaleObject } from 'nuxt-i18n';
import cookieNames from '../../enums/cookieNameEnum';

export interface Market {
  uid: string;
  grn: string;
  name: string;
  description: string;
  countries: string[];
}

const handleMarketsAndLocales = () => {
  const generateLocales = (markets: Market[]) => {
    const localesArray = [];
    markets.forEach((market) => {
      const splittedDescription = market.description?.split('|') || process?.env?.[market.uid]?.split('|') || [];
      const marketUrlCode = splittedDescription[0];
      const marketLangs = splittedDescription[1] ? splittedDescription[1].split(',') : ['it', 'en'];
      const marketDefaultCurrency = splittedDescription[2] || 'EUR';
      if (marketUrlCode && marketLangs.length > 0) {
        marketLangs.forEach((lang) => {
          localesArray.push({
            name: market.name,
            description: market.description,
            code: `${marketUrlCode}-${lang}`,
            file: `${lang}.js`,
            defaultCurrency: marketDefaultCurrency,
            grn: market.grn,
            uid: market.uid,
            urlCode: marketUrlCode,
            localeValue: lang,
            countries: market.countries,
            languages: marketLangs,
            lang,
          });
        });
      } else {
        console.error(`handleMarketsAndLocales.ts ~ ${market.name} (${market.grn}) has not valid description`);
      }
    });
    return localesArray;
  };

  const findMarketForLocaleCode = (markets: string[] | LocaleObject[], localeCode: string): LocaleObject =>
    (markets as never[]).find((m: string | LocaleObject): boolean => typeof m !== 'string' && m.code === localeCode) ?? markets[0];

  const getLocaleFromRoute = (route: { fullPath: string }): string => {
    const localeArray = route.fullPath ? route.fullPath.split('/') : [];
    let localeCodeFromRoute = '';
    if (localeArray?.length > 0) {
      [, localeCodeFromRoute] = localeArray;
      const cleanLocaleWithoutParameters = localeCodeFromRoute.split('?');
      if (cleanLocaleWithoutParameters.length > 0) {
        [localeCodeFromRoute] = cleanLocaleWithoutParameters;
      }
      return localeCodeFromRoute;
    } else if (!localeArray) {
      console.error('handleMarketsAndLocales.ts ~ could not get locale array');
    }
    return localeCodeFromRoute;
  };

  const localeMatchesStateAndCookies = (state, appCookies) => {
    return (
      state.getLocale() === appCookies.get(cookieNames.localeCookieName) &&
      state.getStore() === appCookies.get(cookieNames.storeCookieName) &&
      state.getCountry() === appCookies.get(cookieNames.countryCookieName) &&
      state.getMarket() === appCookies.get(cookieNames.marketCookieName) &&
      state.getCurrency() === appCookies.get(cookieNames.currencyCookieName)
    );
  };

  const updateMarketAndLocaleCookies = (app, route) => {
    const {
      $cookies: appCookies,
      $vsf: {
        $gemini: {
          config: { state },
        },
      },
      i18n: { localeProperties: i18nLocale, locales: markets },
    } = app;

    const localeCodeFromRoute = getLocaleFromRoute(route);
    if (localeCodeFromRoute && localeCodeFromRoute !== '' && markets) {
      const { grn, localeValue, defaultCurrency } = findMarketForLocaleCode(markets, localeCodeFromRoute);
      const stateAndCookiesMatch = localeMatchesStateAndCookies(state, appCookies);
      const marketGrnMatches = appCookies.get(cookieNames.marketCookieName) === grn;
      const marketLocaleValueMatches = appCookies.get(cookieNames.localeCookieName) === localeValue;
      const marketDefaultCurrencyMatches = appCookies.get(cookieNames.currencyCookieName) === defaultCurrency;
      const i18nLocaleMatchesPath = i18nLocale.code === localeCodeFromRoute;
      const storeCookieNeedUpdate = !appCookies.get(cookieNames.storeCookieName) && appCookies.get(cookieNames.localeCookieName);

      const shouldUpdateState =
        !stateAndCookiesMatch ||
        !marketGrnMatches ||
        !marketLocaleValueMatches ||
        !marketDefaultCurrencyMatches ||
        !i18nLocaleMatchesPath ||
        storeCookieNeedUpdate;

      if (shouldUpdateState) {
        state.setStore(localeValue);
        state.setLocale(localeValue);
        state.setCountry(localeValue);
        state.setMarket(grn);
        state.setCurrency(defaultCurrency);
      }
    }
  };

  return {
    generateLocales,
    findMarketForLocaleCode,
    getLocaleFromRoute,
    updateMarketAndLocaleCookies,
  };
};

export default handleMarketsAndLocales;
