import { sharedRef } from '@vue-storefront/core';
import { useCategorySearch as useCategorySearchComposable } from '@gemini-vsf/composables';
import { Category } from '@gemini-vsf/api-client';
import { useContext } from '@nuxtjs/composition-api';
import axios from 'axios';

const useCategorySearch = (key = 'custom-category-search') => {
  const result = sharedRef<Category[]>([], key);

  const {
    app: {
      $vsf: {
        $gemini: { config },
      },
    },
  } = useContext();
  const { result: resultComposable, search: searchComposable } = useCategorySearchComposable(key);

  const getCategoryFromCache = async (data) =>
    await axios({
      method: 'post',
      url: `/api/search-category`,
      data,
    }).then((res) => res.data);

  const getCategory = async (params) => {
    // handle Category.vue case only
    const categoryId = params?.filters?.category_uid?.eq;
    if (process.client && config['redisEnabled'] && categoryId) {
      return await getCategoryFromCache(params);
    }
    await searchComposable(params);
    return resultComposable.value;
  };

  const search = async (params): Promise<void> => {
    try {
      result.value = null;
      result.value = await getCategory(params);
      // tag added by Category.vue
      // addTags([{ prefix: CacheTagPrefix.Category, value: categoryId }]);
    } catch (error) {
      console.warn(`Unable to load category: ${params.identifier || 'unknown'}`);
      console.error(error);
    }
  };

  return {
    ...useCategorySearchComposable(key),
    search,
    result,
  };
};

export default useCategorySearch;
