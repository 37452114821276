<template>
  <transition-group
    tag="div"
    class="notifications"
    name="slide-fade"
    :class="notifications.length > 0 ? 'notifications__open' : ''"
  >
    <NotificationCustom
      v-for="notification in notifications"
      :key="notification.id"
      :message="notification.message"
      :action="notification.action && notification.action.text"
      :type="notification.type"
      visible
      @click:close="notification.dismiss"
      @click:action="
        (e) =>
          notification.action &&
          notification.action.onClick.call(null, e, notification)
      "
    >
      <template #action>
        <CustomButton
          v-if="notification.action"
          @click="notification.action.onClick"
          :link="notification.action.link"
          class="notifications__custom-button"
        >
          {{ notification.action.text }}
        </CustomButton>
      </template>
      <template #close="{ closeHandler }">
        <SfIcon
          v-show="!notification.hideCloseIcon"
          icon="cross"
          color="white"
          @click="closeHandler"
          class="close-icon"
          size="xs"
        />
      </template>
      <template #icon>
        <SfIcon
          v-show="!notification.hideIcon"
          :icon="notification.icon || 'info'"
          color="white"
          class="info-icon"
          size="lg"
        />
      </template>
    </NotificationCustom>
  </transition-group>
</template>

<script>
import { CustomButton } from '~/components/General/FormElements';
import { defineComponent } from '@nuxtjs/composition-api';
import { SfIcon } from '@storefront-ui/vue';
import { useUiNotification } from '~/composables';
import NotificationCustom from '~/components/NotificationCustom.vue';

export default defineComponent({
  name: 'Notification',
  components: {
    SfIcon,
    CustomButton,
    NotificationCustom,
  },
  setup() {
    const { notifications } = useUiNotification();

    return {
      notifications,
    };
  },
});
</script>

<style scoped lang="scss">
.notifications {
  height: auto;
  min-height: 5.625rem;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  &__hidden {
    display: none;
  }
  @include from-tablet-min {
    right: unset;
    left: var(--spacer-base);
    bottom: var(--spacer-base);
    width: 21.875rem;
  }
  .close-icon:hover {
    cursor: pointer;
  }
  @include to-mobile-max {
    bottom: 0.9375rem;
    margin: 0 0.8125rem;
    width: calc(100% - 1.625rem);
  }
  &__custom-button {
    margin: 0.625rem auto 0 auto;
  }
}
.sf-notification {
  height: auto;
  min-height: 5.625rem;
  max-width: 100%;
  --notification-background: #47cc8c;
  --notification-color: var(--c-black);
  &:last-child {
    margin: 0;
  }
  &.color-danger {
    --notification-background: var(--c-red);
  }
  &:first-child {
    margin-top: 0;
  }
  @include for-mobile {
    --notification-border-radius: 0;
    --notification-max-width: 100%;
    --notification-font-size: var(--font-size--sm);
    --notification-font-family: var(--font-family--primary);
    --notification-font-weight: var(--font-weight--normal);
    --notification-padding: var(--spacer-base) var(--spacer-lg);
  }
  @include for-desktop {
    margin: 0 0 var(--spacer-base) 0;
  }
  &.color-success {
    --notification-background: #c1fdcf;

    .sf-icon.color-white {
      --icon-color: #ffffff;
    }

    &__message {
      margin: 0 auto;
      padding: 0;
    }
  }
  &.color-info {
    --notification-background: var(--c-red);
    .sf-icon.color-white {
      --icon-color: #000000;
    }
    &__message {
      color: #000000;
    }
  }
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s;
  @include for-desktop {
    transition: opacity 0.25s linear;
  }
}
.slide-fade-enter {
  transform: translateY(40px);
  @include for-desktop {
    opacity: 0;
  }
}
.slide-fade-leave-to {
  transform: translateY(80px);
  @include for-desktop {
    opacity: 0;
  }
}
</style>

<style lang="scss">
.notifications {
  .sf-notification {
    justify-content: space-between;
    padding: 1.5625rem 1.25rem;
    @include to-tablet-max {
      padding: 1.75rem;
    }
    &__message {
      @include paragraph-regular;
      text-transform: uppercase;
    }
  }
  &.notifications__open {
    z-index: 9999999;
  }
}
</style>
