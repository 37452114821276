import Vue from 'vue';

function nuxtHead() {
  const headObject = {};

  const currentLocaleLang = this.$i18n?.localeProperties?.lang;
  if (currentLocaleLang) {
    headObject.htmlAttrs = {
      ...headObject.htmlAttrs,
      lang: currentLocaleLang,
    };
  }

  return headObject;
}

const plugin = {
  install(Vue) {
    Vue.mixin({
      head() {
        return nuxtHead.call(this);
      },
    });
  },
};

Vue.use(plugin);
