// Function to generate a unique visitor ID
import { ref } from '@nuxtjs/composition-api';

function generateVisitorId(): string {
  return `${Date.now()}-${Math.random().toString(36).slice(2, 11)}`;
}

export default function useVisitor() {
  const visitorIdKey = 'gem-visitor-id';
  const visitorId = ref<string | null>(null);

  // Check if localStorage is available
  function isLocalStorageAvailable(): boolean {
    try {
      return typeof window !== 'undefined' && typeof localStorage !== 'undefined';
    } catch {
      return false;
    }
  }

  // Retrieve the visitor ID from localStorage or generate a new one
  function getVisitorId(): string {
    if (isLocalStorageAvailable()) {
      let id = localStorage.getItem(visitorIdKey);
      if (!id) {
        id = generateVisitorId();
        localStorage.setItem(visitorIdKey, id);
      }
      return id;
    } else {
      // Fallback: generate a new ID if localStorage is not available
      return generateVisitorId();
    }
  }
  // Initialize the visitor ID
  visitorId.value = getVisitorId();

  return {
    visitorId,
  };
}
