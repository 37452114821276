/* eslint-disable no-secrets/no-secrets */
export default `
query productDetailVariantPrice($filter: ProductDetailFilterInput!, $configurableOptionValueUids: [ID!]) {
  productDetail(filter: $filter) {
    configurable_product_options_selection(configurableOptionValueUids: $configurableOptionValueUids) {
      variant {
        price_range {
          minimum_price {
            final_price {
              value
            }
          }
        }
      }
    }
  }
}
`;
