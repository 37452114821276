import { Plugin } from '@nuxt/types';
import formatCurrency from '~/helpers/formatCurrency';

const getLocalizedPrice = (priceToFix, currency): string =>
  currency === 'JPY' ? priceToFix.toString() : priceToFix.toString().replaceAll('.', '##').replaceAll(',', '.').replaceAll('##', ',');

const plugin: Plugin = (context, inject) => {
  inject('fc', (value: number | string, locale?: string, options = {}): string => {
    // locale = locale || context.i18n?.localeProperties?.localeValue;
    locale = 'en-US'; // force locale to have a standard format
    const currency = options['currency'] || context.app.$vsf.$gemini.config.state.getCurrency() || context.i18n?.localeProperties?.defaultCurrency;
    const optionsWithCurrency = { ...options, currency };
    const formattedPrice = formatCurrency(value, locale, optionsWithCurrency);
    return getLocalizedPrice(formattedPrice, currency);
  });
};

export default plugin;
