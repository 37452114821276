/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios from 'axios';

export interface IUrlRewrite {
  tenantId: string;
  id: string;
  context: string;
  requestPath: string;
  targetPath: string;
  redirectType: string;
  linkRel: string;
}

export const getCanonicalTargetPathFromRequestPath = async (requestPath: string): Promise<string | null> => {
  try {
    const geminiData = await axios.post(
      process.env.GEMINI_URL_MANAGER_ENDPOINT_LIST_URL_REWRITES,
      {
        filter: {
          requestPath,
          redirectType: 'RedirectType_OK',
        },
        pageSize: 50,
        tenantId: process.env.TENANT_ID,
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.GEMINI_RESOURCE_JWT}`,
        },
      }
    );

    if (geminiData?.data?.urlRewrites) {
      return geminiData.data.urlRewrites.find((urlRewrite: IUrlRewrite) => urlRewrite.linkRel === 'LinkRel_CANONICAL')?.targetPath || null;
    }
  } catch (error) {
    console.error('hooks/geolocator/geolocation/getCanonicalTargetPathFromRequestPath.ts ~ error:', error);
  }
  return null;
};
