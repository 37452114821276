import { ref, computed } from '@nuxtjs/composition-api';
import type { Ref, ComputedRef } from '@nuxtjs/composition-api';
import axios from 'axios';
import { getExchangeRate } from '~/serverMiddleware/getExchangeRate';

interface ExchangeRateParams {
  base_currency_code: string;
  currency_code: string;
  time: number;
}
interface ExchangeRateResponse {
  exchangeRate: number;
  error: string;
}

const useEuroExchange = () => {
  const baseCurrencyCode = 'EUR';
  const exchangeRate: Ref<number> = ref<number>(null);
  const totalInEuro: Ref<number> = ref<number>(null);
  const loadingEnchangeRate: Ref<boolean> = ref(false);
  const loadingTotalInEuro: Ref<boolean> = ref(false);
  const loading: ComputedRef<boolean> = computed<boolean>(() => loadingEnchangeRate.value || loadingTotalInEuro.value);
  const error: Ref<string | unknown> = ref<string | unknown>(null);

  const loadExchangeRate = async (currency: string) => {
    loadingEnchangeRate.value = true;
    exchangeRate.value = null;
    error.value = null;
    let exchangeData: ExchangeRateResponse = null;
    const params: ExchangeRateParams = {
      base_currency_code: baseCurrencyCode,
      currency_code: currency,
      time: Date.now(),
    };
    try {
      if (process.server) {
        exchangeData = (await getExchangeRate(params)) as ExchangeRateResponse;
      } else {
        const response = await axios.get('/api/get-exchange-rate', {
          params,
        });
        exchangeData = response.data as ExchangeRateResponse;
      }
      if (exchangeData?.error) {
        error.value = exchangeData.error;
      } else if (exchangeData.exchangeRate) {
        exchangeRate.value = exchangeData.exchangeRate;
      }
    } catch (error_) {
      error.value = error_;
    }
    loadingEnchangeRate.value = false;
  };

  const loadTotalInEuro = async (total: number, currency: string) => {
    loadingTotalInEuro.value = true;
    totalInEuro.value = null;
    await loadExchangeRate(currency);
    totalInEuro.value = Number.parseFloat((total / exchangeRate.value).toFixed(2));
    loadingTotalInEuro.value = false;
  };

  return {
    error,
    loading,
    totalInEuro,
    exchangeRate,
    loadTotalInEuro,
    loadExchangeRate,
  };
};

export default useEuroExchange;
