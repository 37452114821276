import { CartItem, useCart } from '@gemini-vsf/composables';
import { useContext } from '@nuxtjs/composition-api';
import { stringIsFreeShipping } from '~/helpers/util';
import { AppliedCoupon, Discount } from '@gemini-vsf/api-client';

interface ExtendedAppliedCoupon extends AppliedCoupon {
  label?: string;
  code: string;
  __typename?: string;
}

interface Discounts {
  coupons: { items: Discount[]; total: number };
  cartRules: { items: Discount[]; total: number };
}

export const cartHelpers = () => {
  const {
    $vsf: {
      $gemini: { config },
    },
  } = useContext();
  const { cart } = useCart();

  const moveGiftToBottomSortFn = (a: CartItem, b: CartItem) => {
    const divoGiftGrn = config['divoGiftGrn'];
    if (a.product.sku === divoGiftGrn) {
      return 1;
    }
    if (b.product.sku === divoGiftGrn) {
      return -1;
    }
    return 0;
  };

  const removeCouponFreeShipping = (discounts: Discount[]): Discount[] => {
    if (cart.value?.shipping_addresses?.length > 0) {
      const newDiscounts = [];
      let discountRemoved = false;
      discounts.forEach((discount) => {
        if (
          !discountRemoved &&
          Math.abs(cart.value?.shipping_addresses?.[0]?.selected_shipping_method?.amount?.value) === Math.abs(discount.amount.value)
        ) {
          discountRemoved = true;
          return;
        }
        newDiscounts.push(discount);
      });
      return newDiscounts;
    }
    return discounts;
  };

  const splitCouponsAndCartRules = (): Discounts => {
    const splittedDiscounts: Discounts = { coupons: { items: [], total: 0 }, cartRules: { items: [], total: 0 } };
    let cartCoupons: ExtendedAppliedCoupon[] = cart.value?.applied_coupons;
    // remove free shipping and discounts with amount equal to 0, we don't need those
    let cleanedCartDiscounts = cart.value?.prices?.discounts?.filter((discountItem) => {
      return !stringIsFreeShipping(discountItem.label) || discountItem?.amount?.value > 0;
    });

    if (cartCoupons?.length > 1) {
      cartCoupons = [cart.value?.applied_coupons?.[0]];
      cleanedCartDiscounts = removeCouponFreeShipping(cleanedCartDiscounts);
    }

    if (cleanedCartDiscounts.length === 0) {
      return splittedDiscounts;
    } else if (cartCoupons.length === 0) {
      splittedDiscounts.cartRules.items = cleanedCartDiscounts;
      splittedDiscounts.cartRules.total = cleanedCartDiscounts.reduce((acc, discount) => {
        acc += discount.amount.value;
        return acc;
      }, 0);
      return splittedDiscounts;
    }
    cleanedCartDiscounts.forEach((discountItem) => {
      if (cartCoupons.some((coupon) => coupon.label === discountItem.label)) {
        splittedDiscounts.coupons.items.push(discountItem);
        splittedDiscounts.coupons.total += discountItem.amount.value;
      } else {
        splittedDiscounts.cartRules.items.push(discountItem);
        splittedDiscounts.cartRules.total += discountItem.amount.value;
      }
    });
    return splittedDiscounts;
  };

  const getDiscountsArraysValues = (itemsTotals, discountItems, itemProductCartId): number[] => {
    const itemDiscounts =
      discountItems.total === 0
        ? []
        : discountItems.items.reduce((acc, item) => {
            if (item.amount.value !== 0) {
              acc.push(item);
            }
            return acc;
          }, []);

    const discountLopp = [];
    itemDiscounts.forEach((item) => {
      let totalsRule = 0;
      item.affected_items?.forEach((itemAffectedDiscount) => {
        itemsTotals.forEach((itemTotal) => {
          if (itemAffectedDiscount.uid === itemTotal.itemCart) {
            totalsRule += itemTotal.total;
          }
        });
      });

      const totalDiscountPercentage = Math.abs(Math.round((item.amount.value / totalsRule) * 100));

      //update the product total with the discount applied
      item.affected_items?.forEach((itemAffectedDiscount) => {
        itemsTotals.forEach((itemTotal) => {
          if (itemAffectedDiscount.uid === itemTotal.itemCart) {
            itemTotal.total -= itemTotal.total * (totalDiscountPercentage / 100);
          }
        });
        if (itemProductCartId === itemAffectedDiscount.uid) {
          discountLopp.push(totalDiscountPercentage);
        }
      });
    });

    return discountLopp;
  };

  const calculateDiscountPercentages = ({ itemProductCartId }) => {
    const { coupons, cartRules } = splitCouponsAndCartRules();

    //get all cart items totals for the real percentage discount
    const itemsTotals = [];
    cart.value?.items.forEach((item) => {
      if (item.product.price_range.minimum_price.final_price.value > 0) {
        itemsTotals.push({
          itemCart: item.uid,
          total: item.product.price_range.minimum_price.final_price.value,
        });
      }
    });

    //cart discount rule discounts
    const cartRuleItemDiscountsPercentage: number[] = getDiscountsArraysValues(itemsTotals, cartRules, itemProductCartId);
    //coupon rule discount
    const couponItemDiscountPercentageArray: any[] = getDiscountsArraysValues(itemsTotals, coupons, itemProductCartId);
    const couponItemDiscountPercentage = couponItemDiscountPercentageArray.length === 0 ? 0 : couponItemDiscountPercentageArray[0];

    return { cartRuleItemDiscountsPercentage, couponItemDiscountPercentage };
  };

  const getDiscountLabel = ({ catalogDiscount, cartRuleItemDiscountsPercentage, couponItemDiscountPercentage }): string => {
    let discountsString = '';

    const getCartRulesLabel = (discountsArray: number[]): string => {
      if (discountsArray.length === 0) {
        return '';
      }
      return discountsArray.reduce((acc: string, item: number, index: number) => {
        if (catalogDiscount) {
          return acc + ` + EXTRA${item}%`;
        }
        return acc + (index === 0 ? `${item}%` : ` +${item}%`);
      }, '');
    };

    const getCouponLabel = (couponPercentage: number): string => {
      if (couponPercentage === 0) {
        return '';
      }
      if (catalogDiscount) {
        return ` + ${couponPercentage}%`;
      }
      if (!catalogDiscount && cartRuleItemDiscountsPercentage > 0) {
        return ` + EXTRA${couponPercentage}%`;
      }
      return `${couponPercentage}%`;
    };

    discountsString = catalogDiscount
      ? `${Math.round(catalogDiscount)}%${getCartRulesLabel(cartRuleItemDiscountsPercentage)}${getCouponLabel(couponItemDiscountPercentage)}`
      : `${getCartRulesLabel(cartRuleItemDiscountsPercentage)}${getCouponLabel(couponItemDiscountPercentage)}`;

    return discountsString;
  };

  return {
    moveGiftToBottomSortFn,
    splitCouponsAndCartRules,
    calculateDiscountPercentages,
    getDiscountLabel,
  };
};
