<template>
  <div
    class="custom-checkmark"
    :class="[{ 'is-selected': selected }, { 'circle-radius': circleRadius }]"
  >
    <span class="custom-checkmark__inner" :class="{ disabled: disabled }" />
  </div>
</template>
<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CustomCheckmark',
  components: {},
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    circleRadius: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
</script>
