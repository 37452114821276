var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer__socials"},[_c('div',{staticClass:"footer__socials__heading"},[_vm._v(_vm._s(_vm.$t('Social')))]),_vm._v(" "),_c('div',{staticClass:"footer_socials_icons"},[_c('SfLink',{attrs:{"link":"https://www.instagram.com/divoboutique/"}},[_c('SfImage',{staticClass:"footer__social-image",attrs:{"placeholder":_vm.addBasePath("/icons/socials/instagram.svg"),"src":_vm.addBasePath(
            ("/icons/socials/" + (!_vm.instagramHovered ? 'instagram' : 'instagramH') + ".svg")
          ),"alt":"instagram","width":"20","height":"20"},on:{"mouseover":function($event){{
            _vm.instagramHovered = true;
          }},"mouseleave":function($event){{
            _vm.instagramHovered = false;
          }}}})],1),_vm._v(" "),_c('span',{staticClass:"divider"},[_vm._v("|")]),_vm._v(" "),_c('SfLink',{attrs:{"link":"https://it-it.facebook.com/boutiquedivo/"}},[_c('SfImage',{staticClass:"footer__social-image",attrs:{"placeholder":_vm.addBasePath("/icons/socials/facebook.svg"),"src":_vm.addBasePath(
            ("/icons/socials/" + (!_vm.facebookHovered ? 'facebook' : 'facebookH') + ".svg")
          ),"alt":"facebook","width":"20","height":"20"},on:{"mouseover":function($event){{
            _vm.facebookHovered = true;
          }},"mouseleave":function($event){{
            _vm.facebookHovered = false;
          }}}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }