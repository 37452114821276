<template>
  <div v-if="apiNotFound">Api not found</div>
  <div v-else class="layout-error">
    <div>
      <IconSprite />
      <WishlistSidebar />
      <CartSidebar />
      <LoginModal />
      <CountryModal />
      <LazyHydrate when-visible>
        <Notification />
      </LazyHydrate>
      <AppHeader :check-sticky="checkSticky" />
      <div id="layout-error">
        <nuxt :key="route.fullPath" />
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import {
  useRoute,
  defineComponent,
  ref,
  useFetch,
  onMounted,
  useContext,
} from '@nuxtjs/composition-api';
import AppHeader from '~/components/AppHeader.vue';
import CartSidebar from '~/components/CartSidebar.vue';
import LoginModal from '~/components/LoginModal.vue';
import CountryModal from '~/components/CountryModal.vue';
import { useConfig, useCart } from '~/composables';
import WishlistSidebar from '~/components/WishlistSidebar.vue';
import Notification from '~/components/Notification.vue';
import AppFooter from '~/components/AppFooter.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import handleMarketsAndLocales from '~/helpers/geminiConfig/handleMarketsAndLocales';

export default defineComponent({
  name: 'ErrorPageLayout',
  components: {
    LazyHydrate,
    AppHeader,
    WishlistSidebar,
    AppFooter,
    CartSidebar,
    LoginModal,
    Notification,
    CountryModal,
    IconSprite,
  },
  setup() {
    const route = useRoute();
    const { loadConfig, siteConfigData } = useConfig();
    const { load: loadCart } = useCart();
    const { app } = useContext();
    const flag = ref(false);
    const checkSticky = ref(false);

    const splittedRoute = route.value?.fullPath?.split('/');
    const apiNotFound =
      splittedRoute?.length > 1 &&
      !splittedRoute[0] &&
      splittedRoute[1] === 'api';

    const scroll = () => {
      if (!apiNotFound) {
        window.addEventListener('scroll', () => {
          const st = window.scrollY;
          checkSticky.value = st >= Number.parseInt('160', 10);
        });
      }
    };

    const { updateMarketAndLocaleCookies } = handleMarketsAndLocales();

    onMounted(() => {
      if (!apiNotFound) {
        updateMarketAndLocaleCookies(app, route.value);
        loadCart();
        scroll();
      }
    });

    useFetch(async () => {
      if (!apiNotFound) {
        await loadConfig();
      }
    });

    return {
      route,
      siteConfigData,
      loadConfig,
      flag,
      checkSticky,
      apiNotFound,
    };
  },
});
</script>

<style lang="scss">
@import '~@storefront-ui/vue/styles';
#layout-error {
  box-sizing: border-box;
  @include for-desktop {
    @include desktop-boxed;
    margin: auto;
    padding: 0 20px;
  }
}
.layout-error {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.no-scroll {
  overflow: hidden;
  height: 100vh;
}
// Reset CSS
html {
  width: auto;
  @include for-mobile {
    overflow-x: hidden;
  }
}
hr {
  border-color: var(--c-dark-grey);
}
h1 {
  @include mobile-h1;
  @include from-desktop-min {
    @include desktop-h1;
  }
}
h2 {
  @include mobile-h2;
  @include from-desktop-min {
    @include desktop-h2;
  }
}
h3 {
  @include desktop-h3;
}
h4 {
  @include desktop-h4;
}
body {
  overflow-x: hidden;
  color: var(--c-text);
  font-size: var(--font-size--base);
  font-family: var(--font-family--primary);
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: var(--c-link);
  &:hover {
    color: var(--c-link-hover);
  }
}
</style>
