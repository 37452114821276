<template>
  <!-- TrustBox widget - Review Collector -->
  <div
    v-if="isClient"
    class="trustpilot-widget"
    :data-locale="trustPilotLanguage"
    data-template-id="56278e9abfbbba0bdcd568bc"
    data-businessunit-id="5d011a478608500001809d58"
    data-style-height="60"
    data-style-width="200"
  >
    <a
      href="https://it.trustpilot.com/review/divoboutique.com"
      target="_blank"
      rel="noopener"
      >Trustpilot
    </a>
  </div>
  <!-- End TrustBox widget -->
  <div v-else />
</template>

<script>
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import { isClient } from '~/helpers/check-environment';

export default defineComponent({
  name: 'TrustPilotWidget',
  components: {},
  setup() {
    const {
      i18n: {
        localeProperties: { localeValue },
      },
    } = useContext();
    const trustPilotLanguage = computed(() =>
      localeValue.toLowerCase().includes('it') ? 'it-IT' : 'en-US'
    );
    return { isClient, trustPilotLanguage };
  },
});
</script>
