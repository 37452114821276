<template>
  <SfModal
    class="custom-modal"
    :visible="isCountryModalOpen"
    :cross="false"
    @close="handleCloseModal"
  >
    <template #modal-bar><span /></template>
    <div class="custom-modal__inside">
      <div class="custom-modal__inside__content">
        <div class="custom-modal__inside__content__content-top">
          <div class="custom-modal__inside__content__content-top__title">
            {{ $t('select country and language') }}
          </div>
          <div
            class="custom-modal__inside__content__content-top__icon"
            @click="handleCloseModal"
          >
            <CrossIcon
              class="custom-modal__inside__content__content-top__close"
            />
          </div>
        </div>
        <div class="custom-modal__inside__content__content-body">
          <div class="custom-modal__inside__content__content-body__country">
            <CustomSelect
              v-model="country"
              :placeholder="$t('select country')"
              select-name="country-name"
              :select-options="allAvailableCountries"
              select-title="Country"
              :full-width="true"
              @selectChange="(c) => setCountryData(c, '')"
            />
          </div>
          <div class="custom-modal__inside__content__content-body__language">
            <CustomSelect
              v-model="currentLanguage"
              :placeholder="$t('select language')"
              select-name="language-name"
              :select-options="languageOptions"
              select-title="Language"
              :full-width="true"
              @selectChange="(l) => setCountryData('', l)"
            />
          </div>
          <div class="custom-modal__inside__content__content-body__cta">
            <CustomButton
              :disabled="!targetUrl"
              class="newsletter-input-button"
              :is-full-width="true"
              :link="targetUrl"
            >
              {{ $t('confirm changes') }}
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  </SfModal>
</template>

<script>
import {
  ref,
  computed,
  useContext,
  defineComponent,
  watch,
  onMounted,
} from '@nuxtjs/composition-api';
import { CrossIcon } from '~/components/General/icons';
import { SfModal } from '@storefront-ui/vue';
import { useUiState, useWindow, useMarketsComposable } from '~/composables';
import CustomButton from './General/FormElements/CustomButton.vue';
import CustomSelect from './General/FormElements/CustomSelect.vue';

export default defineComponent({
  name: 'CustomModal',
  components: {
    CrossIcon,
    SfModal,
    CustomButton,
    CustomSelect,
  },
  setup() {
    const VSF_COUNTRY = 'vsf-country';
    const VSF_COUNTRY_SELECTED = 'vsf-country-selected';

    const { isDesktop } = useWindow();
    const { isCountryModalOpen, toggleCountryModal } = useUiState();
    const { allAvailableCountries, languageMap, markets, currentMarket } =
      useMarketsComposable();
    const {
      app: {
        $config: { storeUrlSecure },
        i18n: trans,
        $cookies: cookies,
        $i2c: countryIsoToName,
      },
    } = useContext();

    const country = ref('');
    const currentLanguage = ref('');
    const countryOld = ref('');
    const currentLanguageOld = ref('');

    watch(isCountryModalOpen, () => {
      if (
        isCountryModalOpen.value &&
        process &&
        process.client &&
        localStorage
      ) {
        const storedCountry = localStorage.getItem(VSF_COUNTRY_SELECTED);
        if (storedCountry) {
          const parsedStoredCountry = JSON.parse(storedCountry);
          country.value = parsedStoredCountry?.countryModalCountry ?? '';
          currentLanguage.value =
            parsedStoredCountry?.countryModalLanguage ??
            cookies.get(VSF_COUNTRY) ??
            '';
          countryOld.value = parsedStoredCountry?.countryModalCountry ?? '';
          currentLanguageOld.value =
            parsedStoredCountry?.countryModalLanguage ??
            cookies.get(VSF_COUNTRY) ??
            '';
        }
      }
    });

    const getCountryConfiguration = (country, markets) => {
      try {
        return (
          country.value &&
          markets.find((m) => m.urlCode === country.value.split('-')?.[0])
        );
      } catch (error) {
        console.error('CountryModal ~ getCountryConfiguration ~ error:', error);
      }
      return null;
    };

    const mapLanguages = (languages, trans, languageMap) => {
      try {
        return (
          languages?.map((l) => ({
            value: l,
            label: trans.t(languageMap.get(l)),
          })) || []
        );
      } catch (error) {
        console.error('CountryModal ~ mapLanguages ~ error:', error);
      }
      return [];
    };

    const languageOptions = computed(() => {
      const currentlyChosenCountryConfiguration = getCountryConfiguration(
        country,
        markets
      );
      const languagesForCurrentlyChosenCountry = mapLanguages(
        currentlyChosenCountryConfiguration?.languages,
        trans,
        languageMap
      );
      const currentlyChosenMarketLanguages = mapLanguages(
        currentMarket.value?.languages,
        trans,
        languageMap
      );

      return currentlyChosenCountryConfiguration
        ? languagesForCurrentlyChosenCountry
        : currentMarket.value?.languages?.length > 0
        ? currentlyChosenMarketLanguages
        : [];
    });

    const targetUrl = computed(() =>
      // check if storeUrlSecure ends with a slash
      storeUrlSecure.charAt(storeUrlSecure.length - 1) === '/'
        ? country.value && currentLanguage.value
          ? `${storeUrlSecure}${country.value.split('-')[0]}-${
              currentLanguage.value
            }`
          : ''
        : country.value && currentLanguage.value
        ? `${storeUrlSecure}/${country.value.split('-')[0]}-${
            currentLanguage.value
          }`
        : ''
    );

    const setCountryData = (c, l) => {
      if (process && process.client && localStorage) {
        let parsedStoredCountry = {};
        const countryToSave = c.length > 0 ? c : country.value;
        const currentLanguageToSave = l.length > 0 ? l : currentLanguage.value;
        const storedCountry = localStorage.getItem(VSF_COUNTRY_SELECTED);
        if (storedCountry) {
          parsedStoredCountry = JSON.parse(storedCountry);
        }
        if (countryToSave) {
          parsedStoredCountry = {
            ...parsedStoredCountry,
            countryModalCountry: countryToSave,
          };
        }
        if (currentLanguageToSave) {
          parsedStoredCountry = {
            ...parsedStoredCountry,
            countryModalLanguage: currentLanguageToSave,
          };
        }
        if (countryToSave && currentLanguageToSave) {
          parsedStoredCountry = {
            ...parsedStoredCountry,
            value: `${countryToSave.split('-')[0]}-${currentLanguageToSave}`,
            countryIso: `${countryToSave.split('-')[1].toLowerCase()}`,
            label: countryIsoToName(
              countryToSave.split('-')[1],
              currentLanguageToSave
            ),
          };
        }
        localStorage.setItem(
          VSF_COUNTRY_SELECTED,
          JSON.stringify(parsedStoredCountry)
        );
      }
    };

    const populateCountryModalFromLocalStorage = () => {
      if (process && process.client && localStorage) {
        const storedCountry = localStorage.getItem(VSF_COUNTRY_SELECTED);
        if (storedCountry) {
          const parsedStoredCountry = JSON.parse(storedCountry);
          countryOld.value = parsedStoredCountry?.countryModalCountry ?? '';
          currentLanguageOld.value =
            parsedStoredCountry?.countryModalLanguage ??
            cookies.get(VSF_COUNTRY) ??
            '';
        }
      }
    };

    onMounted(() => {
      populateCountryModalFromLocalStorage();
    });

    const handleCloseModal = () => {
      setCountryData(countryOld.value, currentLanguageOld.value);
      country.value = countryOld.value;
      currentLanguage.value = currentLanguageOld.value;
      toggleCountryModal();
    };

    return {
      isDesktop,
      isCountryModalOpen,
      allAvailableCountries,
      languageOptions,
      currentLanguage,
      country,
      targetUrl,
      setCountryData,
      handleCloseModal,
      markets,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-modal {
  &__inside {
    overflow-x: hidden;
    &__content {
      width: 100%;
      padding: 1.25rem;
      &__content-top {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1.875rem;
        &__title {
          @include mobile-h3;
          text-transform: uppercase;
        }
        &__icon {
          display: none;
        }
      }
      &__content-body {
        &__cta {
          padding-top: 1.875rem;
        }
      }
    }
  }
  @include from-desktop-min {
    &__inside {
      display: flex;
      &__content {
        padding: 2.5rem;
        min-width: 31.25rem;
        &__content-top {
          padding-bottom: 1.25rem;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.custom-modal.sf-modal {
  .sf-modal {
    &__container {
      width: auto;
    }
    &__content {
      border-radius: 0.625rem;
      padding: 0;
    }
    @include to-tablet-max {
      &__container {
        max-width: 31.25rem;
        width: calc(100% - 2.5rem);
        margin: auto;
        justify-content: center;
      }
    }
  }
  select {
    background: none;
  }
}
</style>
