<template>
  <div class="newsletter">
    <span class="newsletter__heading">
      {{ $t('Subscribe to our newsletter') }}
    </span>
    <br />
    <span class="newsletter__heading">
      {{ $t('Get now 15% discount to apply to your order') }}
    </span>
    <div class="newsletter-input-container">
      <CustomInput
        input-name="newsletter-input"
        input-id="newsletter-input"
        input-label=""
        class="newsletter-input"
        input-type="email"
        :input-placeholder="$t('Insert your email here')"
        v-model="customerEmail"
      />
      <nuxt-link class="pdp-links" :to="fixUrlForCurrentLocale('/newsletter')">
        <CustomButton
          layout="white"
          class="newsletter-input-button"
          @click="$cookies.set('nle', customerEmail, { path: '/' })"
        >
          {{ $t('Subscribe') }}
        </CustomButton>
      </nuxt-link>
    </div>
    <span class="subscribing">
      {{ $t('By subscribing you accept our') }}
      <nuxt-link
        :to="
          fixUrlForCurrentLocale(
            locale.includes('-it')
              ? '/termini-e-condizioni'
              : '/terms-and-conditions'
          )
        "
      >
        {{ $t('Terms and conditions') }}
      </nuxt-link>
      {{ $t('and our policy about the') }}
      <SfLink :link="fixUrlForCurrentLocale('/privacy-policy')">
        Privacy
      </SfLink>
    </span>
    <GroupedPayments />
  </div>
</template>
<script>
import { defineComponent } from '@nuxtjs/composition-api';
import CustomInput from '../FormElements/CustomInput.vue';
import CustomButton from '../FormElements/CustomButton.vue';
import { SfLink, SfImage } from '@storefront-ui/vue';
import GroupedPayments from './GroupedPayments.vue';
import { useI18n } from '~/helpers/hooks/usei18n';
import { useCurrentLocale } from '~/composables/useCurrentLocale';

export default defineComponent({
  name: 'NewsletterSubscriber',
  components: { CustomInput, CustomButton, SfLink, SfImage, GroupedPayments },
  data() {
    return {
      customerEmail: '',
    };
  },
  setup() {
    const { locale } = useI18n();
    const { fixUrlForCurrentLocale } = useCurrentLocale();

    return { locale, fixUrlForCurrentLocale };
  },
});
</script>
<style lang="scss">
.newsletter {
  .newsletter-input {
    --input-background: transparent;
    --input-color: var(--c-dark-grey);
    --input-border-color: var(--c-dark-grey);
    --input-width: 14.0625rem;
    #newsletter-input {
      border: 1px solid #dedede !important;
      color: #9d9d9d !important;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      -webkit-text-fill-color: #fff !important;
    }
    @include for-desktop {
      --input-width: 16.375rem;
    }
    @include for-mobile {
      width: 100%;
    }
    &-container {
      padding: 1.25rem 0;
      @include for-mobile {
        width: 100%;
      }
    }
    &-button {
      @include for-mobile {
        margin-left: -1rem;
      }
      border: none;
    }
  }
  @include for-mobile {
    padding: 1rem 1.25rem;
  }
  &__heading {
    @include paragraph-semibold;
    text-transform: uppercase;
    color: var(--c-white);
    @include for-desktop {
      @include paragraph-small;
    }
  }
  .subscribing {
    text-transform: uppercase;
    color: var(--c-white);
    @include inline-cta;
    --link-color: var(--c-white);

    a {
      @include inline-cta;
      color: var(--c-white);
      text-decoration: underline;
      &:hover {
        --c-link-hover: var(--c-white);
        font-weight: bold;
      }
    }
  }
}
</style>
