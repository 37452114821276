<template>
  <div class="product-card-bullet-icon-wrapper">
    <component
      :is="`${active ? 'full' : 'empty'}BulletIcon`"
      class="product-card-bullet-icon"
    />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { fullBulletIcon, emptyBulletIcon } from '~/components/General/icons';

export default defineComponent({
  name: 'ProductSpecialIcon',
  components: { fullBulletIcon, emptyBulletIcon },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup() {},
});
</script>

<style lang="scss" scoped>
.product-card-bullet-icon-wrapper {
  padding-right: 0.3125rem;
}
</style>
