import { useContext } from '@nuxtjs/composition-api';
import useEuroExchange from '../../composables/useEuroExchange/useEuroExchange';

const gtmHelper = () => {
  const { app, $vsf } = useContext();

  const initGtm = () => {
    // check if gtm is already loaded searching in dataLayer an element with event property equal to 'gtm.load'
    if (window.dataLayer && window.dataLayer.some((el) => el.event === 'gtm.load')) {
      // console.log('GTM already loaded');
      return;
    }

    // console.log('GTM is loading...');
    // console.log(window.dataLayer);
    app.$gtm.init(process.env.GTM_ID);
  };

  // init gtm on page load and after waiting for 5 seconds
  if (process.client) {
    window.addEventListener('load', function () {
      const timer = setTimeout(initGtm, 5000);
      const trigger = () => {
        initGtm();
        clearTimeout(timer);
      };
      const events = ['mouseover', 'keydown', 'touchmove', 'touchstart'];
      events.forEach((e) => window.addEventListener(e, trigger, { passive: true, once: true }));
    });
  }

  const getCategoryForGtm = (product) => {
    const sortedCategoriesForGtm =
      product.categories
        ?.map((c) => ({
          urlArray: c.url_path?.split('/'),
          name: c?.name,
          url: c?.url_path,
        }))
        .reduce((a, b) => (a?.url?.length > b?.url?.length ? a : b)) ?? [];
    return sortedCategoriesForGtm?.urlArray?.length > 1
      ? sortedCategoriesForGtm.urlArray[1]
      : sortedCategoriesForGtm?.urlArray?.length > 0
      ? sortedCategoriesForGtm.urlArray[0]
      : '';
  };

  const getProductDataFromCartForGtm = (product) => ({
    item_id: product?.product?.merchant_sku?.split('_')?.[0] || '',
    item_name: product?.product?.name || '',
    discount:
      product?.product?.price_range?.maximum_price?.regular_price?.value - product?.product?.price_range?.maximum_price?.final_price.value || 0,
    item_brand: product?.product?.brand_attribute?.label || '',
    item_category: product?.product?.gender_attribute?.label || '',
    item_category2: product?.product?.super_group_attribute?.label || '',
    item_category3: product?.product?.group_attribute?.label || '',
    item_variant: product?.product?.merchant_sku || '',
    price: product?.product?.price_range?.minimum_price?.final_price.value || 0,
    quantity: product.quantity,
  });

  const addToCartPush = (product, additionalData = {}) => {
    const currency = additionalData?.currencyCode || product?.price_range?.minimum_price?.final_price.currency || '';
    const productData = {
      item_id: product?.merchant_sku || '',
      item_name: product?.name || '',
      discount: product?.price_range?.maximum_price?.final_price.value - product?.price_range?.minimum_price?.final_price.value || 0,
      item_brand: product?.brand_attribute?.label || '',
      item_category: product?.gender_attribute?.label || '',
      item_category2: product?.super_group_attribute?.label || '',
      item_category3: product?.group_attribute?.label || '',
      item_variant: product?.configurable_product_options_selection?.variant.merchant_sku || '',
      price: product?.price_range?.minimum_price?.final_price.value || '',
      quantity: 1,
    };
    const filteredAdditionalData = { ...additionalData };
    if (filteredAdditionalData.currencyCode) delete filteredAdditionalData.currencyCode;
    app.$gtm.push({
      event: 'add_to_cart',
      ecommerce: {
        currency: currency,
        value: productData?.price,
        items: [{ ...productData, ...additionalData }],
      },
    });
  };

  const removeFromCartPush = (product) => {
    app.$gtm.push({
      event: 'remove_from_cart',
      ecommerce: {
        currency: product?.product?.price_range?.minimum_price?.final_price.currency,
        value: product?.product?.price_range?.minimum_price?.final_price.value || 0,
        items: [getProductDataFromCartForGtm(product)],
      },
    });
  };

  const checkoutPush = (event, cart, products) => {
    const productsData = [];
    products?.forEach((p) => {
      productsData.push(getProductDataFromCartForGtm(p));
    }) || [];

    app.$gtm.push({
      event: event,
      ecommerce: {
        currency: cart.currency || '',
        value: Number.parseFloat(cart.value) || '',
        coupon: cart.coupon || '',
        items: productsData,
      },
    });
  };

  const { totalInEuro, loadTotalInEuro } = useEuroExchange('gtmHelper');
  // eslint-disable-next-line max-params
  const purchasePush = async (order, coupon, tax, currency, products, discountLabel) => {
    let purchaseInEuroValue = order?.total;
    if (currency !== 'EUR') {
      const totalInEuroValue = async () => {
        await loadTotalInEuro(purchaseInEuroValue, currency);
        return totalInEuro.value;
      };
      purchaseInEuroValue = await totalInEuroValue();
    }

    const productsData = [];
    order?.items?.forEach((p) => {
      products.forEach((product) => {
        if (product.product.merchant_sku === p?.product?.sku) {
          productsData.push({
            item_id: p?.product?.sku?.split('_')?.[0] || '',
            item_name: p?.product?.name || '',
            discount: p?.product?.price_range?.minimum_price?.regular_price?.value - p?.product?.price_range?.minimum_price?.final_price.value || 0,
            item_brand: product.product?.brand_attribute?.label || '',
            item_category: product?.product?.gender_attribute?.label || '',
            item_category2: product?.product?.super_group_attribute?.label || '',
            item_category3: product?.product?.group_attribute?.label || '',
            item_variant: p?.product?.sku || '',
            price: p?.product?.price_range?.minimum_price?.final_price.value || '',
            promotion_name: discountLabel || '',
            quantity: p.qtyOrdered,
          });
        }
      });
    });
    app.$gtm.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: order?.order_number,
        value: Number.parseFloat(purchaseInEuroValue),
        tax: tax,
        shipping: Number.parseFloat(order?.total) - Number.parseFloat(order?.total_item),
        currency: 'EUR',
        coupon: coupon,
        items: productsData,
      },
    });
    // ----- stylight tracking -----
    if (order?.order_number && order.total && currency && order.items) {
      const stylightCountryCookieName = 'stylight-country';
      const { stylightTest, stylightPropertyIds } = $vsf.$gemini.config;
      let stylightCountry = localStorage.getItem(stylightCountryCookieName);
      if (!stylightCountry) {
        const selectedCountry = JSON.parse(localStorage.getItem('vsf-country-selected'));
        stylightCountry = selectedCountry?.countryIso?.toLowerCase();
      }
      const stylightPropertyId = stylightCountry && stylightPropertyIds ? stylightPropertyIds[stylightCountry] : '';
      if (stylightPropertyId) {
        app.$gtm.push({
          event: 'stylight-sales-confirmation',
          stylight: {
            test: stylightTest,
            propertyId: stylightPropertyId,
            orderId: order.order_number,
            currency: currency,
            totalAmount: Number.parseFloat(order.total),
            itemCount: order.items.length,
          },
        });
      }
      if (localStorage.getItem(stylightCountryCookieName)) {
        localStorage.removeItem(stylightCountryCookieName);
      }
    }
    // ----- end stylight tracking -----
  };

  return {
    purchasePush,
    checkoutPush,
    addToCartPush,
    getCategoryForGtm,
    removeFromCartPush,
  };
};

export default gtmHelper;
