<template>
  <div class="header-logo-container">
    <nuxt-link
      :to="fixUrlForCurrentLocale('/')"
      class="sf-header__logo"
      :class="headerColor === 'white' ? headerLogos.black : headerLogos.white"
      aria-label="Divo Boutique"
    >
      <svg
        width="162"
        height="21"
        viewBox="0 0 162 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        alt="Divo Boutique"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 20.208H11.2C34.542 20.208 44.812 15.737 44.812 9.91799C44.815 3.44499 33.508 0.200989 9.44 0.200989H0V20.208ZM6.432 1.86399H11.932C36.208 1.86399 37.971 7.08099 37.971 10.205C37.971 15.221 29.153 18.546 13.071 18.546H6.432V1.86399Z"
          fill="#161615"
        />
        <path
          d="M56.611 0.200989H50.179V20.208H56.611V0.200989Z"
          fill="#161615"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M82.638 20.208H88.655L111.477 0.200989H105.045L85.646 17.145L67.808 0.200989H60.961L82.638 20.208Z"
          fill="#161615"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M109.993 10.2C109.993 11.948 109.993 20.4 135.513 20.4C161.033 20.4 161.033 11.944 161.033 10.2C161.033 8.456 161.033 0 135.513 0C109.993 0 109.993 8.456 109.993 10.2ZM116.84 10.2C116.84 8.623 116.84 1.658 135.513 1.658C154.186 1.658 154.186 8.627 154.186 10.2C154.186 11.773 154.186 18.742 135.513 18.742C116.84 18.742 116.84 11.777 116.84 10.2Z"
          fill="#161615"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 20.221H11.2C34.542 20.221 44.812 15.75 44.812 9.93099C44.815 3.45799 33.508 0.213989 9.44 0.213989H0V20.221ZM6.432 1.87699H11.932C36.208 1.87699 37.971 7.09399 37.971 10.218C37.971 15.234 29.153 18.559 13.071 18.559H6.432V1.87699Z"
          fill="#161615"
        />
        <path
          d="M56.611 0.213989H50.179V20.221H56.611V0.213989Z"
          fill="#161615"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M82.638 20.221H88.655L111.477 0.213989H105.045L85.646 17.158L67.808 0.213989H60.961L82.638 20.221Z"
          fill="#161615"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M109.993 10.213C109.993 11.961 109.993 20.413 135.513 20.413C161.033 20.413 161.033 11.957 161.033 10.213C161.033 8.469 161.033 0.0130005 135.513 0.0130005C109.993 0.0130005 109.993 8.469 109.993 10.213ZM116.84 10.213C116.84 8.636 116.84 1.671 135.513 1.671C154.186 1.671 154.186 8.64 154.186 10.213C154.186 11.786 154.186 18.755 135.513 18.755C116.84 18.755 116.84 11.79 116.84 10.213Z"
          fill="#161615"
        />
      </svg>
    </nuxt-link>
  </div>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { useCurrentLocale } from '~/composables/useCurrentLocale';

export default defineComponent({
  name: 'HeaderLogo',
  components: {},
  props: {
    headerColor: {
      type: String,
      default: 'white',
    },
  },
  setup() {
    const headerLogos = ref({
      black: 'black',
      white: 'white',
    });

    const { fixUrlForCurrentLocale } = useCurrentLocale();

    return {
      headerLogos,
      fixUrlForCurrentLocale,
    };
  },
});
</script>
<style lang="scss" scoped>
.header-logo-container {
  .header__logo {
    svg {
      max-width: 6.9375rem;
      max-height: 0.875rem;

      @include from-desktop-min {
        max-width: 10.0625rem;
        max-height: 1.3125rem;
      }
    }
  }
  .white {
    svg {
      path {
        fill: #fff;
      }
    }
  }
  .black {
    svg {
      path {
        fill: #161615;
      }
    }
  }
  @include to-tablet-max {
    height: 0.9375rem;
  }
}
</style>
