var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"will-change",rawName:"v-will-change",value:('font-size'),expression:"'font-size'"}],staticClass:"sf-input",class:{
    'has-text': !!_vm.value,
    invalid: !_vm.valid,
  },attrs:{"data-testid":_vm.nameWithoutWhitespace}},[_c('span',_vm._b({staticClass:"sf-input__label",class:{ 'display-none': !_vm.label },attrs:{"name":"label"}},'span',{ label: _vm.label },false),[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"sf-input__wrapper"},[_c('label',{staticClass:"sf-input__label will-change",class:{ 'display-none': true },attrs:{"for":_vm.nameWithoutWhitespace}}),_vm._v(" "),_c('span',{staticClass:"sf-input__bar"}),_vm._v(" "),_c('input',_vm._g(_vm._b({directives:[{name:"focus",rawName:"v-focus"}],class:{
        'sf-input--is-password': _vm.isPassword,
        'sf-input--disabled': _vm.disabled,
      },attrs:{"id":_vm.nameWithoutWhitespace,"placeholder":_vm.placeholder,"required":_vm.required,"disabled":_vm.disabled,"name":_vm.name,"type":_vm.inputType,"aria-invalid":!_vm.valid,"aria-required":_vm.required,"aria-describedby":_vm.errorMessage ? (_vm.nameWithoutWhitespace + "-error") : null},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners)),_vm._v(" "),_vm._t("icon",function(){return [_c('SfButton',{staticClass:"sf-input__button sf-button--pure",class:{ 'display-none': !_vm.icon.icon || _vm.isPassword },on:{"click":function($event){return _vm.$emit('click:icon')}}},[_c('SfIcon',{staticClass:"sf-input__icon",attrs:{"color":_vm.icon.color,"size":_vm.icon.size,"icon":_vm.icon.icon}})],1),_vm._v(" "),_c('SfButton',{staticClass:"sf-input__password-button",class:{ 'display-none': !_vm.isPassword },attrs:{"type":"button","aria-label":'switch-visibility-password',"aria-pressed":_vm.isPasswordVisible.toString()},on:{"click":_vm.switchVisibilityPassword}},[_c('SfIcon',{staticClass:"sf-input__password-icon",class:{
            hidden: !_vm.isPasswordVisible,
          },attrs:{"icon":"show_password","size":"18px","color":"black"}})],1)]},null,{
        icon: _vm.icon,
        isPasswordVisible: _vm.isPasswordVisible,
        switchVisibilityPassword: _vm.switchVisibilityPassword,
      })],2),_vm._v(" "),_c('div',{staticClass:"sf-input__error-message"},[_c('transition',{attrs:{"name":"sf-fade"}},[_vm._t("error-message",function(){return [_c('div',{class:{ 'display-none': _vm.valid },attrs:{"id":(_vm.nameWithoutWhitespace + "-error"),"aria-live":"assertive"}},[_vm._v("\n          "+_vm._s(_vm.errorMessage)+"\n        ")])]},null,{ errorMessage: _vm.errorMessage })],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }