<template>
  <section class="sf-modal" :class="[staticClass, className]">
    <SfOverlay
      v-if="overlay"
      class="sf-modal__overlay"
      :transition="transitionOverlay"
      :visible="visible"
    >
    </SfOverlay>
    <transition :name="transitionModal">
      <div
        v-if="visible"
        v-focus-trap
        v-click-outside="checkPersistence"
        class="sf-modal__container"
      >
        <SfButton
          :class="{ 'display-none': !cross }"
          class="sf-button--pure sf-modal__close"
          aria-label="Close modal"
          type="button"
          data-testid="close-button"
          @click="close"
        >
          <slot name="close">
            <SfIcon icon="cross" size="0.875rem" color="gray-secondary" />
          </slot>
        </SfButton>
        <div ref="content" class="sf-modal__content">
          <slot />
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import { SfOverlay, SfIcon, SfButton } from '@storefront-ui/vue';
import {
  focusTrap,
  clickOutside,
} from '@storefront-ui/vue/src/utilities/directives';
import { isClient } from '@storefront-ui/vue/src/utilities/helpers';

export default {
  name: 'SfModal',
  directives: { focusTrap, clickOutside },
  components: {
    SfOverlay,
    SfIcon,
    SfButton,
  },
  model: {
    prop: 'visible',
    event: 'close',
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    cross: {
      type: Boolean,
      default: true,
    },
    overlay: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    transitionOverlay: {
      type: String,
      default: 'sf-fade',
    },
    transitionModal: {
      type: String,
      default: 'sf-fade',
    },
  },
  data() {
    return {
      staticClass: null,
      className: null,
    };
  },
  watch: {
    visible: {
      handler: function (value) {
        if (!isClient) return;
        if (value) {
          this.$nextTick(() => {});
          document.addEventListener('keydown', this.keydownHandler);
          document.body.classList.add('bodylock');
        } else {
          document.removeEventListener('keydown', this.keydownHandler);
          document.body.classList.remove('bodylock');
        }
      },
      immediate: true,
    },
  },
  methods: {
    close() {
      this.$emit('close', false);
    },
    checkPersistence() {
      if (!this.persistent) {
        this.close();
      }
    },
    keydownHandler(e) {
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        this.close();
      }
    },
    classHandler() {
      if (this.staticClass !== this.$vnode.data.staticClass) {
        this.staticClass = this.$vnode.data.staticClass;
      }
      if (this.className !== this.$vnode.data.class) {
        this.className = this.$vnode.data.class;
      }
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/css/SfModal.scss';
.bodylock {
  overflow: hidden;
}
</style>
