<template>
  <div class="login-popup">
    <SfModal
      v-e2e="'login-modal'"
      :visible="isLoginModalOpen"
      class="modal"
      @close="closeModal"
    >
      <transition name="sf-fade" mode="out-in">
        <div v-if="isLogin">
          <div class="login-top">
            <h2>
              {{ $t('CUSTOMER LOGIN') }}
            </h2>
            <p>
              {{
                $t('IF YOU HAVE AN ACCOUNT, SIGN IN WITH YOUR EMAIL ADDRESS.')
              }}
            </p>
          </div>
          <ValidationObserver v-slot="{ handleSubmit }" key="log-in">
            <form class="form" @submit.prevent="handleSubmit(onLoginSubmit)">
              <ValidationProvider v-slot="{ errors }" rules="required|email">
                <SfInput
                  v-model="form.username"
                  v-e2e="'login-modal-email'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="email"
                  :label="$t('Your email')"
                  class="form__element"
                />
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" rules="required">
                <SfInput
                  v-model="form.password"
                  v-e2e="'login-modal-password'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="password"
                  :label="$t('Password')"
                  type="password"
                  has-show-password
                  class="form__element"
                />
              </ValidationProvider>
              <div v-if="error.login">
                {{ $t(error.login) }}
              </div>
              <SfButton
                v-e2e="'login-modal-submit'"
                type="submit"
                class="sf-button--full-width form__button"
                :disabled="loading"
              >
                <SfLoader :class="{ loader: loading }" :loading="loading">
                  <div>{{ $t('Login') }}</div>
                </SfLoader>
              </SfButton>
            </form>
          </ValidationObserver>
          <div class="forgotten-links" @click="setIsForgottenValue(true)">
            {{ $t('Forgotten password?') }}
          </div>
          <div class="bottom">
            <p class="bottom__paragraph">
              {{ $t('No account') }}
            </p>
            <div class="register-links" @click="setIsLoginValue(false)">
              {{ $t('Register here') }}
            </div>
          </div>
        </div>
        <div v-else-if="isForgotten">
          <div class="login-top">
            <h2>
              {{ $t('Forgot Password') }}
            </h2>
            <br />
          </div>
          <ValidationObserver v-slot="{ handleSubmit }" key="log-in">
            <form class="form" @submit.prevent="handleSubmit(onForgotSubmit)">
              <ValidationProvider v-slot="{ errors }" rules="required|email">
                <SfInput
                  v-model="form.username"
                  v-e2e="'forgot-modal-email'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="email"
                  :label="$t('Forgot Password Modal Email')"
                  class="form__element"
                />
              </ValidationProvider>
              <SfButton
                v-e2e="'forgot-modal-submit'"
                type="submit"
                class="sf-button--full-width form__button"
                :disabled="forgotPasswordLoading"
              >
                <SfLoader
                  :class="{ loader: forgotPasswordLoading }"
                  :loading="forgotPasswordLoading"
                >
                  <div>{{ $t('Reset Password') }}</div>
                </SfLoader>
              </SfButton>
            </form>
          </ValidationObserver>
          <p class="thank-you__label">
            {{
              $t('You will receive an email with a link to reset your password')
            }}
          </p>
          <p v-if="forgotPasswordError.request" class="thank-you__error">
            {{
              $t(
                'It was not possible to request a new password, please check the entered email address.'
              )
            }}
          </p>
        </div>
        <div v-else-if="isThankYouAfterForgotten" class="thank-you">
          <i18n tag="p" class="thank-you__p" path="forgotPasswordConfirmation">
            <span class="thank-you__paragraph--bold">{{ userEmail }}</span>
          </i18n>
        </div>
        <div v-else class="form">
          <div class="login-top">
            <h2>
              {{ $t('CREATE A NEW ACCOUNT') }}
            </h2>
            <p>
              {{
                $t(
                  'YOU WILL BE ABLE TO ACCESS EXCLUSIVE PROMOS, MANAGE YOUR ORDERS, SAVE YOUR WISHLIST AND MUCH MORE.'
                )
              }}
            </p>
            <p>
              {{ $t('ALREADY REGISTERED?') }}
              <SfButton
                v-e2e="'login-modal-login-to-your-account'"
                class="sf-button--text"
                @click="setIsLoginValue(true)"
              >
                {{ $t('LOG IN HERE') }}
              </SfButton>
            </p>
          </div>
          <ValidationObserver v-slot="{ handleSubmit, invalid }" key="sign-up">
            <form
              class="form"
              autocomplete="off"
              @submit.prevent="handleSubmit(onRegisterSubmit)"
            >
              <ValidationProvider v-slot="{ errors }" rules="required|email">
                <SfInput
                  v-model="form.email"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="email"
                  :label="$t('Your email')"
                  class="form__element"
                />
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" rules="required">
                <SfInput
                  v-model="form.firstName"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="first-name"
                  :label="$t('First Name')"
                  class="form__element"
                />
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" rules="required">
                <SfInput
                  v-model="form.lastName"
                  v-e2e="'login-modal-lastName'"
                  :valid="!errors[0]"
                  name="last-name"
                  :label="$t('Last Name')"
                  class="form__element"
                />
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|password"
                vid="password"
              >
                <SfInput
                  v-model="form.password"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="password"
                  :label="$t('Password')"
                  type="password"
                  has-show-password
                  class="form__element"
                />
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|confirmed:password"
              >
                <SfInput
                  v-model="form.repassword"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="re-password"
                  :label="$t('Re-Password')"
                  type="password"
                  has-show-password
                  class="form__element repassword"
                />
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                :rules="{ required: { allowFalse: false } }"
              >
                <div class="sf-checkbox-row">
                  <SfCheckbox
                    v-model="createAccount"
                    v-e2e="'login-modal-create-account'"
                    :valid="!errors[0]"
                    :error-message="$t(errors[0])"
                    name="create-account"
                    class="form__element"
                  />
                  {{ $t('I HAVE READ AND UNDERSTOOD THE INFORMATION ON') }}
                  <SfLink :link="fixUrlForCurrentLocale('/privacy-policy')">
                    {{ $t('Privacy') }}
                  </SfLink>
                  {{ $t('AND THE POLITICS ABOUT IT') }}
                  <SfLink :link="fixUrlForCurrentLocale('/cookie-policy')">
                    {{ $t('COOKIE') }}
                  </SfLink>
                </div>
              </ValidationProvider>
              <SfCheckbox
                v-model="isSubscribed"
                v-e2e="'sign-up-newsletter'"
                :label="$t('Sign Up for Newsletter')"
                name="signupNewsletter"
                class="form__element"
              />
              <div v-if="error.register">
                {{ $t(error.register) }}
              </div>
              <SfButton
                v-e2e="'login-modal-submit'"
                type="submit"
                class="sf-button--full-width form__button register-btn"
                :disabled="loading || !createAccount || invalid"
              >
                <SfLoader :class="{ loader: loading }" :loading="loading">
                  <div>{{ $t('Create an account') }}</div>
                </SfLoader>
              </SfButton>
            </form>
          </ValidationObserver>
        </div>
      </transition>
    </SfModal>
    <CustomerGtm
      v-if="hasLoggedIn"
      :is-logged="isLoggedIn"
      :customer-data="{ user }"
    />
  </div>
</template>
<script>
import {
  ref,
  watch,
  reactive,
  defineComponent,
  computed,
  useContext,
  useRouter,
  useRoute,
  onMounted,
} from '@nuxtjs/composition-api';
import {
  SfInput,
  SfButton,
  SfCheckbox,
  SfLink,
  SfLoader,
  SfBar,
} from '@storefront-ui/vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, confirmed } from 'vee-validate/dist/rules';
import SfModal from '~/components/storefront-ui/molecules/SfModal/SfModal.vue';
import { useUser, useForgotPassword } from '@gemini-vsf/composables';
import { useUiState, useUiNotification, useCart } from '~/composables';
import {
  customerPasswordRegExp,
  invalidPasswordMsg,
} from '~/helpers/customer/regex';
import CustomerGtm from '~/components/Gtm/CustomerGtm.vue';
import { removeItem } from '~/helpers/asyncLocalStorage';
import { useI18n } from '~/helpers/hooks/usei18n';
import { useCurrentLocale } from '~/composables/useCurrentLocale';

export default defineComponent({
  name: 'LoginModal',
  components: {
    SfModal,
    SfLink,
    SfInput,
    SfButton,
    SfCheckbox,
    SfLoader,
    ValidationProvider,
    ValidationObserver,
    SfBar,
    CustomerGtm,
  },
  setup() {
    const trans = useI18n();
    const { isLoginModalOpen, toggleLoginModal } = useUiState();
    const { send: sendNotification } = useUiNotification();
    const isSubscribed = ref(true);
    const form = ref({});
    const isLogin = ref(true);
    const createAccount = ref(false);
    const isForgotten = ref(false);
    const isThankYouAfterForgotten = ref(false);
    const userEmail = ref('');
    const MY_ACCOUNT = '/my-account';
    const route = useRoute();
    const { load: loadCart } = useCart();

    extend('email', {
      ...email,
      message: trans.t('Invalid email'),
    });

    extend('required', {
      ...required,
      message: trans.t('This field is required'),
    });

    extend('confirmed', {
      ...confirmed,
      message: trans.t('Please make sure your passwords match'),
    });

    extend('password', {
      message: invalidPasswordMsg,
      validate: (value) => customerPasswordRegExp.test(value),
    });

    const { app } = useContext();
    const router = useRouter();

    const { register, login, loading, user, error: userError } = useUser();
    const {
      request,
      error: forgotPasswordError,
      loading: forgotPasswordLoading,
    } = useForgotPassword();
    const isLoggedIn = ref(false);

    const error = reactive({
      login: null,
      register: null,
    });

    const resetErrorValues = () => {
      error.login = null;
      error.register = null;
    };

    onMounted(() => {
      const hasForgottenQuery =
        typeof window !== 'undefined' &&
        new URLSearchParams(window?.location?.search)?.get('forgotten');
      if (route?.value?.query?.register) {
        isLogin.value = false;
        history.pushState({}, null, route?.value?.fullPath);
      }
      if (hasForgottenQuery) {
        isLogin.value = false;
        isForgotten.value = true;
        history.pushState({}, null, route?.value?.fullPath);
      }
    });

    watch(isLoginModalOpen, () => {
      if (isLoginModalOpen) {
        form.value = {};
        resetErrorValues();
      }

      if (isSubscribed.value === false) {
        isSubscribed.value = true;
      }
    });

    const setIsLoginValue = (value) => {
      resetErrorValues();
      isLogin.value = value;
    };

    const setIsForgottenValue = (value) => {
      resetErrorValues();
      isForgotten.value = value;
      isLogin.value = !value;
    };

    const closeModal = () => {
      setIsForgottenValue(false);
      setIsLoginValue(true);
      toggleLoginModal();
    };

    const hasLoggedIn = computed(() => {
      if (isLoggedIn && isLoggedIn.value) {
        return isLoggedIn.value;
      }
      return false;
    });

    const { fixUrlForCurrentLocale } = useCurrentLocale();

    watch(isLoggedIn, () => {
      if (isLoggedIn.value) {
        hasLoggedIn.value = true;
        return hasLoggedIn.value;
      }
      return false;
    });

    const showError = (message) => {
      sendNotification({
        id: Symbol('login_recaptcha_validation_failed'),
        message: `${trans.t(message)}`,
        type: 'danger',
        icon: '',
        persist: false,
        title: `${trans.t('Error')}`,
      });
    };

    const onForgotSubmit = async () => {
      try {
        userEmail.value = form.value.username;
        await request({ email: userEmail.value });
        if (!forgotPasswordError.value.request) {
          isThankYouAfterForgotten.value = true;
          isForgotten.value = false;
        }
      } catch (error) {
        showError(error);
      }
    };

    const onLoginSubmit = async () => {
      try {
        if (
          form.value.username.charAt(0) ===
          form.value.username.charAt(0).toLowerCase()
        ) {
          await login({
            user: {
              username: form.value.username,
              password: form.value.password,
              disableMergeCarts: true,
            },
          });
          if (userError.value.login) {
            await login({
              user: {
                username: form.value.username.replace(/^\w/, (char) =>
                  char.toUpperCase()
                ),
                password: form.value.password,
                disableMergeCarts: true,
              },
            });
          }
        } else {
          await login({
            user: {
              username: form.value.username,
              password: form.value.password,
              disableMergeCarts: true,
            },
          });
          if (userError.value.login) {
            await login({
              user: {
                username: form.value.username.toLowerCase(),
                password: form.value.password,
                disableMergeCarts: true,
              },
            });
          }
        }

        if (userError.value.login) {
          throw new Error(userError.value.login);
        }
        await removeItem('checkout');
        await router.push(app.localePath(MY_ACCOUNT));
        await loadCart();
        closeModal();
      } catch (error_) {
        console.error(error_);
        sendNotification({
          id: Symbol('login_failed'),
          message: trans.t(
            'An error occurred while logging you in, are your credentials correct?'
          ),
          type: 'danger',
          icon: '',
          persist: false,
          title: trans.t('Error'),
        });
      }
    };

    const onRegisterSubmit = async () => {
      try {
        await register({
          user: {
            email: form.value.email.toLowerCase(),
            firstName: form.value.firstName,
            lastName: form.value.lastName,
            certified_email: form.value.certified_email,
            tax_code: form.value.tax_code,
            password: form.value.password,
            is_subscribed: isSubscribed.value,
          },
        });
        const hasUserErrors = userError.value.register || userError.value.login;
        if (hasUserErrors) {
          throw new Error(
            userError.value.register?.message ||
              userError.value.login?.message ||
              'Unexpected error'
          );
        }
        await removeItem('checkout');
        await router.push(app.localePath(MY_ACCOUNT));
        closeModal();
      } catch (error) {
        const { message = '' } = error;
        sendNotification({
          id: Symbol('register_failed'),
          message: message.includes('exists')
            ? trans.t('An user with this email already exists.')
            : trans.t(
                'An error occurred while registering. Please contact our customer support.'
              ),
          type: 'danger',
          icon: '',
          persist: false,
          title: trans.t('Error'),
        });
      }
    };

    return {
      closeModal,
      createAccount,
      error,
      forgotPasswordError,
      forgotPasswordLoading,
      form,
      onForgotSubmit,
      showError,
      isForgotten,
      isLogin,
      isLoginModalOpen,
      isSubscribed,
      isThankYouAfterForgotten,
      loading,
      setIsForgottenValue,
      setIsLoginValue,
      userEmail,
      userError,
      hasLoggedIn,
      isLoggedIn,
      login,
      register,
      request,
      app,
      sendNotification,
      user,
      router,
      trans,
      onLoginSubmit,
      onRegisterSubmit,
      fixUrlForCurrentLocale,
    };
  },
  async mounted() {
    try {
      await this.$recaptcha.init();
    } catch (error) {
      console.error(error);
    }
  },
  beforeUnmount() {
    this.$recaptcha.destroy();
  },
});
</script>

<style lang="scss">
.login-popup {
  text-transform: uppercase;
  font-family: var(--barlow-medium);
  .sf-modal__close {
    position: absolute;
    right: 50px;
    top: 55px;
    height: auto;
    display: none;
    @media (max-width: 768px) {
      right: 12px;
      top: 12px;
      display: block;
    }
    .sf-icon.color-gray-secondary {
      width: 28px;
      height: auto;
      @media (max-width: 768px) {
        width: 20px;
      }
      svg {
        path {
          fill: #000;
        }
      }
    }
  }
  .bottom {
    text-align: center;
    .bottom__paragraph {
      font-family: var(--barlow-medium);
      color: #161615;
      font-size: 16px;
      line-height: 20px;
      margin: 20px 0 0 0;
    }
  }
  .forgotten-links,
  .register-links {
    color: #161615;
    font-family: var(--barlow-regular);
    font-size: 13px;
    line-height: 16px;
    margin-top: 20px;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
  }
  .login-top {
    text-transform: uppercase;
    .sf-button {
      color: #161615;
      font-family: var(--barlow-semibold);
      text-decoration: none;
      font-size: 16px;
      line-height: 20px;
      height: auto;
      padding: 0;
    }
    h2 {
      font-family: var(--barlow-medium);
      color: #0f0f0f;
      margin: 0 0 8px 0;
      font-size: 30px;
      line-height: 36px;
      font-weight: normal;
      text-transform: uppercase;
      padding-right: 45px;
    }
    p {
      font-family: var(--barlow-regular);
      color: #0f0f0f;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 25px;
    }
  }
  .sf-overlay {
    background: rgba(0, 0, 0, 0.2);
  }
  .modal {
    --modal-index: 50;
    --overlay-z-index: 49;
    .sf-modal__container {
      z-index: 99;
      background: rgba(255, 255, 255, 0.97);
      width: 500px;
      right: var(--modal-right, 0);
      top: var(--modal-top, 0);
      transform: var(--modal-transform);
      @media (max-width: 768px) {
        background: #fff;
      }
      .sf-modal__content {
        padding: 50px;
        @media (max-width: 768px) {
          padding: 20px;
        }
      }
    }
  }
  .sf-input,
  .sf-select {
    position: relative;
    height: auto;
    .sf-input__wrapper,
    .sf-select__wrapper {
      margin: 0;
      display: flex;
      height: auto;
      flex-direction: column;
      > input {
        border: 1px solid #cbcaca;
        height: 50px;
        padding: 10px 15px;
        font-family: var(--barlow-regular);
        font-size: 18px;
        color: #0f0f0f;
        outline: none !important;
        background: transparent;
      }
    }
    .sf-input__error-message,
    .sf-select__error-message {
      height: auto;
      min-height: auto;
      font-family: var(--barlow-regular);
      font-size: 12px;
      line-height: 14px;
      > span,
      > div {
        padding: 5px 0 10px 0;
        display: block;
        &:empty {
          padding: 0;
        }
      }
    }
    .sf-input__label,
    .sf-select__label {
      position: static;
      order: -1;
      color: #161615;
      font-family: var(--barlow-regular);
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 10px;
      transform: none;
      padding-left: 20px;
    }
    > select {
      border: solid 1px #dedede;
      height: 50px;
      padding: 10px 15px;
      font-family: var(--barlow-regular);
      font-size: 18px;
      color: #0f0f0f;
      margin: 0;
    }
  }
  .form {
    .sf-input {
      width: 100%;
      font-family: var(--barlow-regular);
    }
    .form__element {
      margin: 0;
      display: block;
      height: auto;
      padding: 0 0 0px 0;
      width: 100%;
    }
    .sf-checkbox {
      margin-bottom: 15px;
      width: auto;
      .sf-checkbox__label {
        color: #9d9d9d;
        font-family: var(--barlow-regular);
        font-size: 14px;
        line-height: 17px;
      }
    }
    .sf-checkbox-row {
      display: flex;
      color: #9d9d9d;
      font-family: var(--barlow-regular);
      font-size: 14px;
      line-height: 17px;
      align-items: flex-start;
      flex-wrap: wrap;
      position: relative;
      padding-left: 25px;
      margin: 20px 0 15px 0;
      .sf-checkbox {
        width: auto;
        position: absolute;
        left: 0;
        top: 0;
      }
      a {
        color: #161615;
        text-decoration: underline;
        font-family: var(--barlow-regular);
        font-size: 14px;
        line-height: 17px;
        display: flex;
        margin: 0 4px;
      }
    }
  }
  .sf-input__password-button {
    transform: none;
    top: inherit;
    bottom: 1px;
    .sf-input__password-icon {
      svg {
        display: none;
      }
      &::after {
        display: none;
      }
      width: 23px;
      height: 19px;
      background: url(/icons/password.svg) no-repeat;
      &.hidden {
        background: url(/icons/password1.svg) no-repeat;
      }
    }
  }
  .sf-input__bar::before,
  .sf-input__bar::after {
    display: none;
  }
  .form__button,
  .action-button,
  .no-orders__button {
    background: #161615;
    height: 50px;
    font-size: 16px;
    font-family: var(--barlow-regular);
    line-height: 20px;
    padding: 15px 25px;
    margin-top: 10px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .register-btn {
    margin-top: 30px;
  }
  .social-row {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    button {
      min-width: 50px;
      background: none;
      padding: 0;
      margin: 0 5px;
      border: solid 1px #161615;
      height: auto;
      padding: 10px;
      svg {
        width: auto;
        height: 25px;
      }
    }
  }
  .thank-you {
    &__label {
      color: var(--c-black);
      font-family: var(--barlow-regular);
      font-size: 12px;
      text-align: center;
    }
    &__error {
      color: var(--c-red);
      font-family: var(--barlow-regular);
      font-size: 12px;
      text-align: center;
    }
    &__p {
      font-family: var(--barlow-regular);
      color: var(--c-black);
      font-size: 16px;
      line-height: 20px;
    }
  }
}
</style>
