var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wishlist-mini",attrs:{"id":"wishlist"}},[_c('SfSidebar',{staticClass:"sidebar sf-sidebar--right",attrs:{"visible":_vm.isWishlistSidebarOpen,"button":false,"title":""},on:{"close":_vm.toggleWishlistSidebar},scopedSlots:_vm._u([{key:"circle-icon",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"close-icon",on:{"click":close}},[_c('svg',{attrs:{"width":"23","height":"23","viewBox":"0 0 23 23","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.414 1.414L21.414 21.414","stroke":"#0F0F0F","stroke-width":"2","stroke-linecap":"round"}}),_vm._v(" "),_c('path',{attrs:{"d":"M21.414 1.414L1.414 21.414","stroke":"#0F0F0F","stroke-width":"2","stroke-linecap":"round"}})])])]}},{key:"content-top",fn:function(){return [_c('div',{staticClass:"wishlist-head-wrapper"},[_c('h2',[_vm._v(_vm._s(_vm.$t('My wishlist')))]),_vm._v(" "),(_vm.totalItems)?_c('SfProperty',{staticClass:"sf-property--large cart-summary",attrs:{"name":_vm.$t('Total articles'),"value":_vm.totalItems}}):_vm._e()],1),_vm._v(" "),(!_vm.isAuthenticated)?_c('p',{staticClass:"wishlist-guest-message"},[_vm._v("\n        "+_vm._s(_vm.$t('Accedi al tuo account per salvare la wishlist'))+"\n      ")]):_vm._e()]},proxy:true},{key:"content-bottom",fn:function(){return [_c('nuxt-link',{attrs:{"to":_vm.fixUrlForCurrentLocale('/wishlist')}},[_c('SfButton',{staticClass:"sf-button--full-width color-secondary button-go-to-cart",on:{"click":_vm.toggleWishlistSidebar}},[_vm._v("\n          "+_vm._s(_vm.$t('Go to Wishlist'))+"\n        ")])],1)]},proxy:true}])},[_vm._v(" "),_vm._v(" "),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.totalItems)?_c('div',{key:"my-wishlist",staticClass:"my-cart"},[_c('div',{staticClass:"collected-product-list"},_vm._l((_vm.wishlistItems),function(wishlistItem,i){return _c('div',{key:i,staticClass:"cart-item-list"},[_c('div',{staticClass:"cart-item-media"},[_c('figure',[_c('SfLink',{staticClass:"cart-item-media",attrs:{"link":_vm.fixUrlForCurrentLocale(
                      _vm.buildProductUrl(wishlistItem.product)
                    )}},[_c('nuxt-img',{staticClass:"sf-collected-product__image",attrs:{"src":((_vm.wishlistGetters.getItemImage(
                      wishlistItem
                    )) + "w=300"),"alt":_vm.wishlistGetters.getItemName(wishlistItem),"width":140,"height":200,"preload":"","loading":"lazy"},on:{"error":_vm.handleImageError}})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"cart-item-details"},[_c('div',{staticClass:"cart-item-content"},[_c('nuxt-link',{staticClass:"cart-item-brand",attrs:{"to":_vm.getProductBrandUrl(wishlistItem.product)}},[_vm._v(_vm._s(_vm.productBrandName(wishlistItem.product)))]),_vm._v(" "),_c('nuxt-link',{staticClass:"cart-item-name",attrs:{"to":_vm.fixUrlForCurrentLocale(
                      _vm.buildProductUrl(wishlistItem.product)
                    )}},[_vm._v("\n                  "+_vm._s(_vm.wishlistGetters.getItemName(wishlistItem))+"\n                ")]),_vm._v(" "),(_vm.isConfigured(wishlistItem))?_c('div',{staticClass:"cart-item-configuration"},_vm._l((_vm.getProductConfiguration(wishlistItem)),function(option){return _c('SfProperty',{key:option.uid,attrs:{"name":option.label,"value":option.value}})}),1):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"cart-item-button"},[_c('div',{staticClass:"sfprice__module"},[_c('SfPrice',{attrs:{"regular":_vm.getWishlistPrice(wishlistItem)}})],1),_vm._v(" "),_c('div',{staticClass:"cart-item-remove"},[_c('a',{on:{"click":function($event){return _vm.removeItemWishlist(wishlistItem)}}},[_vm._v("\n                    "+_vm._s(_vm.$t('Remove'))+"\n                  ")])])])])])}),0)]):_c('div',{key:"empty-wishlist",staticClass:"empty-wishlist"},[_c('div',{staticClass:"empty-wishlist__banner"},[_c('SfHeading',{staticClass:"empty-wishlist__label",attrs:{"title":_vm.$t('Your bag is empty'),"description":_vm.$t('Looks like you haven’t added any items to the Wishlist.')}})],1)])]),_vm._v(" "),(_vm.isLoaderVisible)?_c('SfLoader',{attrs:{"loading":_vm.isLoaderVisible}},[_c('div')]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }