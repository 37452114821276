export default (country_code, region) => ({
  firstname: 'default',
  lastname: 'default',
  street: 'default',
  apartment: 'default',
  city: 'default',
  region,
  country_code,
  postcode: 'default',
  telephone: 'default',
});
