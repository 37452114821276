<template>
  <div
    v-if="hasChildren(menuItem)"
    data-testid="navigation-subcategories"
    class="header-navigation__subcategories"
  >
    <div class="header-navigation__subcategories-inner">
      <div
        v-for="(itemLvl1, idxLvl1) in getValidItems(menuItem.children)"
        :key="idxLvl1"
        class="header-navigation__subcategory"
        aria-haspopup="true"
      >
        <nuxt-link
          :to="fixUrlForCurrentLocale(itemLvl1.link)"
          :data-children="itemLvl1.children.length"
          @click.native="$emit('hideSubcategories')"
        >
          <div class="header-navigation__linkCat">{{ itemLvl1.label }}</div>
        </nuxt-link>
        <SfList v-if="hasChildren(itemLvl1)">
          <SfListItem
            v-for="(itemLvl2, idxLvl2) in getChildrenItems(itemLvl1)"
            :key="idxLvl2"
          >
            <nuxt-link
              :to="fixUrlForCurrentLocale(itemLvl2.link)"
              @click.native="$emit('hideSubcategories')"
            >
              <div
                :class="
                  'header-navigation__linkSubcat' +
                  (itemLvl2.isActive ? ' active' : '')
                "
              >
                {{ itemLvl2.label }}
              </div>
            </nuxt-link>
          </SfListItem>
        </SfList>
      </div>
      <div
        v-if="
          menuImagesContent &&
          menuImagesContent.active == true &&
          menuImagesContent.data
        "
      >
        <div
          v-for="(category, index) in menuImagesContent.data"
          :key="`menu-image-category-${index}`"
        >
          <div
            class="header-navigation__subcategories-inner__images"
            v-if="
              index == 'woman' &&
              (menuItem.label == 'Woman' ||
                menuItem.label == 'Women' ||
                menuItem.label == 'Donna') &&
              menuImagesContent.data.woman &&
              menuImagesContent.data.woman.active == true &&
              menuImagesContent.data.woman.data
            "
          >
            <div
              v-for="(image, index) in getValidImages(
                menuImagesContent.data.woman.data
              )"
              :key="`menu-image-${index}`"
              class="menu-image__items"
            >
              <div class="menu-image__items__item">
                <nuxt-link :to="fixUrlForCurrentLocale(image.link.url)">
                  <nuxt-img
                    class="menu-image__items__item__image"
                    :src="image.image.desktop"
                    :loading="`lazy`"
                    width="180"
                    height="180"
                  />
                  <div class="menu-image__items__item__text">
                    <div class="on-title">{{ image.title }}</div>
                    <div class="on-hover">{{ $t('Find out more') }}</div>
                  </div>
                </nuxt-link>
              </div>
            </div>
          </div>
          <div
            class="header-navigation__subcategories-inner__images"
            v-else-if="
              index == 'man' &&
              (menuItem.label == 'Man' ||
                menuItem.label == 'Men' ||
                menuItem.label == 'Uomo') &&
              menuImagesContent.data.man &&
              menuImagesContent.data.man.active == true &&
              menuImagesContent.data.man.data
            "
          >
            <div
              v-for="(image, index) in getValidImages(
                menuImagesContent.data.man.data
              )"
              :key="`menu-image-${index}`"
              class="menu-image__items"
            >
              <div class="menu-image__items__item">
                <nuxt-link :to="fixUrlForCurrentLocale(image.link.url)">
                  <nuxt-img
                    class="menu-image__items__item__image"
                    :src="image.image.desktop"
                    :loading="`lazy`"
                    width="180"
                    height="180"
                  />
                  <div class="menu-image__items__item__text">
                    <div class="on-title">{{ image.title }}</div>
                    <div class="on-hover">{{ $t('Find out more') }}</div>
                  </div>
                </nuxt-link>
              </div>
            </div>
          </div>
          <div
            class="header-navigation__subcategories-inner__images"
            v-else-if="
              index == 'kids' &&
              (menuItem.label == 'Kids' || menuItem.label == 'Bambino') &&
              menuImagesContent.data.kids &&
              menuImagesContent.data.kids.active == true &&
              menuImagesContent.data.kids.data
            "
          >
            <div
              v-for="(image, index) in getValidImages(
                menuImagesContent.data.kids.data
              )"
              :key="`menu-image-${index}`"
              class="menu-image__items"
            >
              <div class="menu-image__items__item">
                <nuxt-link :to="fixUrlForCurrentLocale(image.link.url)">
                  <nuxt-img
                    class="menu-image__items__item__image"
                    :src="image.image.desktop"
                    :loading="`lazy`"
                    width="180"
                    height="180"
                  />
                  <div class="menu-image__items__item__text">
                    <div class="on-title">{{ image.title }}</div>
                    <div class="on-hover">{{ $t('Find out more') }}</div>
                  </div>
                </nuxt-link>
              </div>
            </div>
          </div>
          <div
            class="header-navigation__subcategories-inner__images"
            v-else-if="
              index == 'home' &&
              (menuItem.label == 'Home' || menuItem.label == 'Casa') &&
              menuImagesContent.data.home &&
              menuImagesContent.data.home.active == true &&
              menuImagesContent.data.home.data
            "
          >
            <div
              v-for="(image, index) in getValidImages(
                menuImagesContent.data.home.data
              )"
              :key="`menu-image-${index}`"
              class="menu-image__items"
            >
              <div class="menu-image__items__item">
                <nuxt-link :to="fixUrlForCurrentLocale(image.link.url)">
                  <nuxt-img
                    class="menu-image__items__item__image"
                    :src="image.image.desktop"
                    :loading="`lazy`"
                    width="180"
                    height="180"
                  />
                  <div class="menu-image__items__item__text">
                    <div class="on-title">{{ image.title }}</div>
                    <div class="on-hover">{{ $t('Find out more') }}</div>
                  </div>
                </nuxt-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SfList } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';
import { useI18n } from '~/helpers/hooks/usei18n';
import { useCurrentLocale } from '~/composables/useCurrentLocale';

export default defineComponent({
  name: 'HeaderNavigationSubcategories',
  components: {
    SfList,
  },
  props: {
    menuItem: {
      type: Object,
      default: () => null,
    },
    menuImagesContent: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const trans = useI18n();
    const { fixUrlForCurrentLocale } = useCurrentLocale();

    const hasChildren = (menuItem) => Boolean(menuItem?.children.length > 0);

    const getValidImages = (images) =>
      images.filter(
        (image) => image?.active && image.link?.url && image.image?.desktop
      );

    const getValidItems = (items) =>
      items.filter((item) => item?.link && item.label);

    const getChildrenItems = (menuItem) => {
      if (menuItem.children.length < 12) {
        return menuItem.children;
      }
      const cutChildren = menuItem.children.slice(0, 10);
      cutChildren.push({
        isActive: true,
        label: trans.t('View All'),
        link: menuItem.link,
      });
      return getValidItems(cutChildren);
    };

    return {
      hasChildren,
      getValidItems,
      getValidImages,
      getChildrenItems,
      fixUrlForCurrentLocale,
    };
  },
});
</script>

<style lang="scss" scoped>
.header-navigation {
  &__subcategories {
    position: absolute;
    background: rgba(255, 255, 255, 0.97);
    left: 0;
    right: 0;
    top: calc(var(--header-height) + var(--topbar-height));
    transition: top 200ms ease-out;
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
    padding: 3.125rem;
    border-bottom: 1px solid var(--c-super-light-grey);
    box-shadow: 0 15px 10px -15px rgba(0, 0, 0, 0.5);
    &-inner {
      display: flex;
      justify-content: flex-start;
      gap: 3.75rem;
      flex-wrap: wrap;
      width: 100%;
      @include desktop-boxed;
      margin: var(--space-l) auto 0.938rem;
      padding-left: 0.625rem;
      padding-right: 0.625rem;
    }
  }
  &__subcategory {
    flex: 1 1 0;
  }
  &__linkFeat {
    margin-bottom: 1.063rem;
    &__label {
      margin-left: 0.375rem;
      color: var(--c-grey-2);
    }
  }
  &__linkCat {
    color: var(--c-black);
    margin-bottom: 1.25rem;
    padding-bottom: 1.25rem;
    border-bottom: 1px solid var(--c-grey);
    @include link-small;
    letter-spacing: 0.0437rem;
    text-transform: uppercase;
    font-weight: bold;
  }
  &__linkSubcat {
    color: var(--c-black);
    @include paragraph-extra-small;
    margin-bottom: 0.938rem;
    text-transform: uppercase;
    text-decoration: none;
    &:active,
    &:hover {
      font-weight: bold;
      text-decoration: none;
    }
  }
}
.sf-header-custom_container.header--sticky {
  .header-navigation__subcategories {
    top: calc(var(--header-height) + var(--topbar-height));
  }
}
</style>

<style lang="scss">
.header-navigation__subcategories-inner {
  .sf-link {
    text-decoration: none;
    .header-navigation__linkCat {
      text-decoration: none;
    }
    .header-navigation__linkSubcat {
      text-decoration: none;
    }
    &:hover {
      .header-navigation__linkFeat {
        &__main {
          text-decoration: none;
        }
        &__label {
          text-decoration: none;
        }
      }
      .header-navigation__linkCat {
        text-decoration: none;
      }
      .header-navigation__linkSubcat {
        text-decoration: none;
      }
    }
  }
  &__images {
    display: none;
    @media (min-width: 90rem) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 5px;
      grid-row-gap: 5px;
    }
  }
  .menu-image__items {
    height: 11.25rem;
    position: relative;
    overflow: hidden;
    &__item {
      .on-title {
        text-transform: uppercase;
        color: #ffffff;
        transition: transform 0.3s;
        @include desktop-h4;
      }
      .on-hover {
        text-transform: uppercase;
        color: #ffffff;
        @include paragraph-extra-small;
        height: 0;
        opacity: 0;
        transition: transform 0.3s, font-size 0.3s, opacity 0.3s;
      }
      &:hover {
        transition: all ease-in-out 0.3s;
        transform: scale(1.2);
        filter: brightness(80%);
        .on-hover {
          height: unset;
          opacity: 1;
          font-size: 11px;
          transform: translateY(5px);
        }
        .on-title {
          transform: translateY(-5px);
        }
      }
      &:not(:hover) {
        transition: all ease-in-out 0.3s;
        transform: scale(1);
      }
      &__text {
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
