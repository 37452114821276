/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios from 'axios';
import { IUrlRewrite } from './getCanonicalTargetPathFromRequestPath';

export const getUrlRewritesFromTargetPath = async (targetPath: string): Promise<IUrlRewrite[] | null> => {
  try {
    const geminiData = await axios.post(
      process.env.GEMINI_URL_MANAGER_ENDPOINT,
      {
        targetPaths: [targetPath],
        tenantId: process.env.TENANT_ID,
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.GEMINI_RESOURCE_JWT}`,
        },
      }
    );
    return geminiData?.data?.urlRewrites || null;
  } catch (error) {
    console.error('hooks/geolocator/geolocation/getUrlRewritesFromTargetPath.ts ~ error:', error);
  }
  return null;
};
