import { useUrlResolver as urlResolver } from '@gemini-vsf/composables';
import { useRoute } from '@nuxtjs/composition-api';

export const useUrlResolver = () => {
  const route = useRoute();

  const {
    params: { path },
    query,
  } = route.value;

  let correctPath = path;
  if (Object.keys(query).length === 1 && query.categoria_filtro) {
    correctPath = `${path}?categoria_filtro=${query.categoria_filtro}`;
  }

  const { search, result, loading } = urlResolver(`router:${correctPath}`);

  return {
    path,
    search: async () => {
      await search({ url: correctPath });
    },
    result,
    loading,
  };
};
