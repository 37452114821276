<template>
  <div class="checkout-header">
    <div class="checkout-header--come-back">
      <nuxt-link
        class="checkout-header--back"
        :to="fixUrlForCurrentLocale('/cart')"
      >
        {{ $t('Go back to your Shopping Bag') }}
      </nuxt-link>
    </div>
    <HeaderLogo class="checkout-header--logo" />
    <div>
      <a
        class="checkout-header--contact"
        href="mailto:customercare@divoboutique.com"
      >
        {{ $t('Contact us: customercare@divoboutique.com') }}
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import HeaderLogo from '~/components/Header/HeaderLogo.vue';
import { SfLink } from '@storefront-ui/vue';
import { useCurrentLocale } from '~/composables/useCurrentLocale';

export default defineComponent({
  name: 'CheckoutHeader',
  components: { HeaderLogo, SfLink },
  setup() {
    const { fixUrlForCurrentLocale } = useCurrentLocale();
    return { fixUrlForCurrentLocale };
  },
});
</script>

<style lang="scss" scoped>
.checkout-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 3.75rem;
  margin: 0 auto;
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: white;
  border-bottom: var(--grey-border);
  &--back,
  &--contact {
    display: none;
  }
}
@include for-desktop {
  .checkout-header {
    height: 5.875rem;
    justify-content: space-between;
    &--come-back {
      min-width: 274px;
    }
    &--back,
    &--contact {
      display: block;
      font-family: var(--barlow-regular);
      text-transform: uppercase;
      margin: 0 2.5rem;
    }
  }
}
</style>

<style lang="scss">
.checkout-header {
  &--back {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
