import { useRoute, ref } from '@nuxtjs/composition-api';
// use useContent from gemini composables because of request entity too large error
import { useContent } from '@gemini-vsf/composables';
import { contentGetters } from '~/composables';

const useProductPageBlocks = () => {
  const route = useRoute();
  const { blocks, loading, loadBlocks: loadBlocksComposable } = useContent();
  const blockData = ref();

  const brandBlockSelector = `pdp-designer-${route?.value?.fullPath?.split('/')?.[1].split('-')?.[1] || 'it'}`;

  const loadBlocks = async () => {
    try {
      await loadBlocksComposable({ identifiers: [brandBlockSelector] });
      blockData.value = contentGetters.getCmsBlockContent(blocks.value);
    } catch (error) {
      console.error('Unable to fetch product page blocks', error);
    }
  };

  return {
    blocks,
    loadBlocks,
    loading,
    blockData,
  };
};

export default useProductPageBlocks;
