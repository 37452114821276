var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasChildren(_vm.menuItem))?_c('div',{staticClass:"header-navigation__subcategories",attrs:{"data-testid":"navigation-subcategories"}},[_c('div',{staticClass:"header-navigation__subcategories-inner"},[_vm._l((_vm.getValidItems(_vm.menuItem.children)),function(itemLvl1,idxLvl1){return _c('div',{key:idxLvl1,staticClass:"header-navigation__subcategory",attrs:{"aria-haspopup":"true"}},[_c('nuxt-link',{attrs:{"to":_vm.fixUrlForCurrentLocale(itemLvl1.link),"data-children":itemLvl1.children.length},nativeOn:{"click":function($event){return _vm.$emit('hideSubcategories')}}},[_c('div',{staticClass:"header-navigation__linkCat"},[_vm._v(_vm._s(itemLvl1.label))])]),_vm._v(" "),(_vm.hasChildren(itemLvl1))?_c('SfList',_vm._l((_vm.getChildrenItems(itemLvl1)),function(itemLvl2,idxLvl2){return _c('SfListItem',{key:idxLvl2},[_c('nuxt-link',{attrs:{"to":_vm.fixUrlForCurrentLocale(itemLvl2.link)},nativeOn:{"click":function($event){return _vm.$emit('hideSubcategories')}}},[_c('div',{class:'header-navigation__linkSubcat' +
                (itemLvl2.isActive ? ' active' : '')},[_vm._v("\n              "+_vm._s(itemLvl2.label)+"\n            ")])])],1)}),1):_vm._e()],1)}),_vm._v(" "),(
        _vm.menuImagesContent &&
        _vm.menuImagesContent.active == true &&
        _vm.menuImagesContent.data
      )?_c('div',_vm._l((_vm.menuImagesContent.data),function(category,index){return _c('div',{key:("menu-image-category-" + index)},[(
            index == 'woman' &&
            (_vm.menuItem.label == 'Woman' ||
              _vm.menuItem.label == 'Women' ||
              _vm.menuItem.label == 'Donna') &&
            _vm.menuImagesContent.data.woman &&
            _vm.menuImagesContent.data.woman.active == true &&
            _vm.menuImagesContent.data.woman.data
          )?_c('div',{staticClass:"header-navigation__subcategories-inner__images"},_vm._l((_vm.getValidImages(
              _vm.menuImagesContent.data.woman.data
            )),function(image,index){return _c('div',{key:("menu-image-" + index),staticClass:"menu-image__items"},[_c('div',{staticClass:"menu-image__items__item"},[_c('nuxt-link',{attrs:{"to":_vm.fixUrlForCurrentLocale(image.link.url)}},[_c('nuxt-img',{staticClass:"menu-image__items__item__image",attrs:{"src":image.image.desktop,"loading":"lazy","width":"180","height":"180"}}),_vm._v(" "),_c('div',{staticClass:"menu-image__items__item__text"},[_c('div',{staticClass:"on-title"},[_vm._v(_vm._s(image.title))]),_vm._v(" "),_c('div',{staticClass:"on-hover"},[_vm._v(_vm._s(_vm.$t('Find out more')))])])],1)],1)])}),0):(
            index == 'man' &&
            (_vm.menuItem.label == 'Man' ||
              _vm.menuItem.label == 'Men' ||
              _vm.menuItem.label == 'Uomo') &&
            _vm.menuImagesContent.data.man &&
            _vm.menuImagesContent.data.man.active == true &&
            _vm.menuImagesContent.data.man.data
          )?_c('div',{staticClass:"header-navigation__subcategories-inner__images"},_vm._l((_vm.getValidImages(
              _vm.menuImagesContent.data.man.data
            )),function(image,index){return _c('div',{key:("menu-image-" + index),staticClass:"menu-image__items"},[_c('div',{staticClass:"menu-image__items__item"},[_c('nuxt-link',{attrs:{"to":_vm.fixUrlForCurrentLocale(image.link.url)}},[_c('nuxt-img',{staticClass:"menu-image__items__item__image",attrs:{"src":image.image.desktop,"loading":"lazy","width":"180","height":"180"}}),_vm._v(" "),_c('div',{staticClass:"menu-image__items__item__text"},[_c('div',{staticClass:"on-title"},[_vm._v(_vm._s(image.title))]),_vm._v(" "),_c('div',{staticClass:"on-hover"},[_vm._v(_vm._s(_vm.$t('Find out more')))])])],1)],1)])}),0):(
            index == 'kids' &&
            (_vm.menuItem.label == 'Kids' || _vm.menuItem.label == 'Bambino') &&
            _vm.menuImagesContent.data.kids &&
            _vm.menuImagesContent.data.kids.active == true &&
            _vm.menuImagesContent.data.kids.data
          )?_c('div',{staticClass:"header-navigation__subcategories-inner__images"},_vm._l((_vm.getValidImages(
              _vm.menuImagesContent.data.kids.data
            )),function(image,index){return _c('div',{key:("menu-image-" + index),staticClass:"menu-image__items"},[_c('div',{staticClass:"menu-image__items__item"},[_c('nuxt-link',{attrs:{"to":_vm.fixUrlForCurrentLocale(image.link.url)}},[_c('nuxt-img',{staticClass:"menu-image__items__item__image",attrs:{"src":image.image.desktop,"loading":"lazy","width":"180","height":"180"}}),_vm._v(" "),_c('div',{staticClass:"menu-image__items__item__text"},[_c('div',{staticClass:"on-title"},[_vm._v(_vm._s(image.title))]),_vm._v(" "),_c('div',{staticClass:"on-hover"},[_vm._v(_vm._s(_vm.$t('Find out more')))])])],1)],1)])}),0):(
            index == 'home' &&
            (_vm.menuItem.label == 'Home' || _vm.menuItem.label == 'Casa') &&
            _vm.menuImagesContent.data.home &&
            _vm.menuImagesContent.data.home.active == true &&
            _vm.menuImagesContent.data.home.data
          )?_c('div',{staticClass:"header-navigation__subcategories-inner__images"},_vm._l((_vm.getValidImages(
              _vm.menuImagesContent.data.home.data
            )),function(image,index){return _c('div',{key:("menu-image-" + index),staticClass:"menu-image__items"},[_c('div',{staticClass:"menu-image__items__item"},[_c('nuxt-link',{attrs:{"to":_vm.fixUrlForCurrentLocale(image.link.url)}},[_c('nuxt-img',{staticClass:"menu-image__items__item__image",attrs:{"src":image.image.desktop,"loading":"lazy","width":"180","height":"180"}}),_vm._v(" "),_c('div',{staticClass:"menu-image__items__item__text"},[_c('div',{staticClass:"on-title"},[_vm._v(_vm._s(image.title))]),_vm._v(" "),_c('div',{staticClass:"on-hover"},[_vm._v(_vm._s(_vm.$t('Find out more')))])])],1)],1)])}),0):_vm._e()])}),0):_vm._e()],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }