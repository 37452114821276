import { useContext, computed } from '@nuxtjs/composition-api';

export const useSeason = () => {
  const {
    route: {
      value: { path },
    },
    app: {
      i18n: trans,
      $config: { SEASON_MAP },
    },
  } = useContext();
  const currentLocale = computed(() => path?.split('/')?.[1].split('-')?.[1]);

  const isValidSeasonString = (str: string): boolean => /^(?:(SS|PE|FW|AI)\d{2}|\d{2}(SS|PE|FW|AI))$/.test(str);

  const extractSeasonCodeAndYear = (season: string) => {
    const firstTwoChars = season.slice(0, 2);
    const lastTwoChars = season.slice(-2);
    let seasonCode: string, year: string;

    if (SEASON_MAP.en[firstTwoChars] || SEASON_MAP.it[firstTwoChars]) {
      seasonCode = firstTwoChars;
      year = season.slice(2);
    } else {
      seasonCode = lastTwoChars;
      year = season.slice(0, 2);
    }

    return { seasonCode, year };
  };

  const formatSeasonLabel = (seasonCode: string, year: string) => {
    if (!currentLocale.value || !seasonCode || !year) return `${seasonCode}${year}`;

    const seasonLabel = SEASON_MAP[currentLocale.value][seasonCode];
    if (seasonLabel) {
      if (seasonLabel === 'FW' || seasonLabel === 'AI') {
        const nextYear = (Number.parseInt(year) + 1).toString().padStart(2, '0');
        return `${seasonLabel} ${year}-${nextYear}`;
      } else {
        return `${seasonLabel} ${year}`;
      }
    } else {
      return `${seasonCode}${year}`;
    }
  };

  const formatSeason = (season: string, isNewSeason = false) => {
    try {
      if (isNewSeason) return trans.t('New Arrivals');

      if (season && isValidSeasonString(season)) {
        const { seasonCode, year } = extractSeasonCodeAndYear(season);
        return formatSeasonLabel(seasonCode, year);
      }
    } catch (error) {
      console.error('useSeason ~ formatSeason ~ error:', error);
    }
    return season;
  };

  return { formatSeason };
};

export default useSeason;
