export type geoLangElement = {
  locale: string;
  lang: string;
  defaultCountry: string;
};

export const geoLangMapping: geoLangElement[] = [
  { locale: 'it-it', lang: 'it', defaultCountry: 'IT' },
  { locale: 'it-it', lang: 'it', defaultCountry: 'SM' },
  { locale: 'it-it', lang: 'it', defaultCountry: 'VA' },
  { locale: 'it-en', lang: 'en', defaultCountry: 'IT' },
  { locale: 'it-en', lang: 'en', defaultCountry: 'SM' },
  { locale: 'it-en', lang: 'en', defaultCountry: 'VA' },
  { locale: 'sg-en', lang: 'en', defaultCountry: 'SG' },
  { locale: 'eue-en', lang: 'en', defaultCountry: 'AL' },
  { locale: 'eue-en', lang: 'en', defaultCountry: 'AD' },
  { locale: 'eue-en', lang: 'en', defaultCountry: 'BA' },
  { locale: 'eue-en', lang: 'en', defaultCountry: 'GE' },
  { locale: 'eue-en', lang: 'en', defaultCountry: 'GL' },
  { locale: 'eue-en', lang: 'en', defaultCountry: 'IS' },
  { locale: 'eue-en', lang: 'en', defaultCountry: 'LI' },
  { locale: 'eue-en', lang: 'en', defaultCountry: 'MK' },
  { locale: 'eue-en', lang: 'en', defaultCountry: 'MD' },
  { locale: 'eue-en', lang: 'en', defaultCountry: 'ME' },
  { locale: 'eue-en', lang: 'en', defaultCountry: 'NO' },
  { locale: 'eue-en', lang: 'en', defaultCountry: 'RS' },
  { locale: 'eue-en', lang: 'en', defaultCountry: 'SJ' },
  { locale: 'eue-en', lang: 'en', defaultCountry: 'FO' },
  { locale: 'jp-en', lang: 'en', defaultCountry: 'JP' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'KY' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'AI' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'DZ' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'AS' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'AF' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'AM' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'AZ' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'BD' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'BT' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'BN' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'KH' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'IN' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'ID' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'IR' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'IQ' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'IL' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'JO' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'KG' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'LA' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'LB' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'MY' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'MV' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'MN' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'MM' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'NP' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'OM' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'PK' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'PH' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'SA' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'LK' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'SY' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'TJ' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'TR' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'TM' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'UZ' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'VN' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'YE' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'CK' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'FJ' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'PF' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'KI' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'MH' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'MQ' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'FM' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'NR' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'NC' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'NU' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'NF' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'MP' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'PW' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'PG' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'PN' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'KN' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'WS' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'SB' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'TK' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'TO' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'TV' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'VU' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'WF' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'AO' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'BJ' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'BW' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'BF' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'BI' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'CM' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'CV' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'CF' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'TD' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'KM' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'CG' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'CD' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'CI' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'DJ' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'EG' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'ER' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'ET' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'GA' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'GM' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'GH' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'GI' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'GN' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'GW' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'KE' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'LS' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'LR' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'LY' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'MG' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'MW' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'ML' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'MR' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'MU' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'MA' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'MZ' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'NA' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'NE' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'NG' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'RE' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'RW' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'SH' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'ST' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'SN' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'SC' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'SL' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'SO' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'ZA' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'SD' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'SZ' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'TH' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'TZ' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'TG' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'TN' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'UG' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'EH' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'ZM' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'ZW' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'AG' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'AR' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'AW' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'BS' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'BB' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'BZ' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'BM' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'BO' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'CL' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'CO' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'CR' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'CW' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'DM' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'EC' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'SV' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'GQ' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'FK' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'GF' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'GD' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'GP' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'GU' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'GY' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'HT' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'HN' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'JM' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'MX' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'MS' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'NI' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'PA' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'PY' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'PE' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'PR' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'LC' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'VC' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'SR' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'TT' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'TC' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'UY' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'VE' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'VG' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'VI' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'CU' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'DO' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'NZ' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'GT' },
  { locale: 'row-en', lang: 'en', defaultCountry: 'PM' },
  { locale: 'gb-it', lang: 'it', defaultCountry: 'GB' },
  { locale: 'gb-en', lang: 'en', defaultCountry: 'GB' },
  { locale: 'hk-en', lang: 'en', defaultCountry: 'HK' },
  { locale: 'kw-en', lang: 'en', defaultCountry: 'KW' },
  { locale: 'us-it', lang: 'it', defaultCountry: 'US' },
  { locale: 'us-en', lang: 'en', defaultCountry: 'US' },
  { locale: 'uee-en', lang: 'en', defaultCountry: 'BG' },
  { locale: 'uee-en', lang: 'en', defaultCountry: 'HR' },
  { locale: 'uee-en', lang: 'en', defaultCountry: 'CY' },
  { locale: 'uee-en', lang: 'en', defaultCountry: 'EE' },
  { locale: 'uee-en', lang: 'en', defaultCountry: 'GR' },
  { locale: 'uee-en', lang: 'en', defaultCountry: 'LV' },
  { locale: 'uee-en', lang: 'en', defaultCountry: 'LT' },
  { locale: 'uee-en', lang: 'en', defaultCountry: 'CZ' },
  { locale: 'uee-en', lang: 'en', defaultCountry: 'RO' },
  { locale: 'uee-en', lang: 'en', defaultCountry: 'SK' },
  { locale: 'uee-en', lang: 'en', defaultCountry: 'SI' },
  { locale: 'uee-en', lang: 'en', defaultCountry: 'HU' },
  { locale: 'au-it', lang: 'it', defaultCountry: 'AU' },
  { locale: 'au-en', lang: 'en', defaultCountry: 'AU' },
  { locale: 'mo-en', lang: 'en', defaultCountry: 'MO' },
  { locale: 'ch-it', lang: 'it', defaultCountry: 'CH' },
  { locale: 'ch-en', lang: 'en', defaultCountry: 'CH' },
  { locale: 'qa-en', lang: 'en', defaultCountry: 'QA' },
  { locale: 'bh-en', lang: 'en', defaultCountry: 'BH' },
  { locale: 'ca-it', lang: 'it', defaultCountry: 'CA' },
  { locale: 'ca-en', lang: 'en', defaultCountry: 'CA' },
  { locale: 'ae-it', lang: 'it', defaultCountry: 'AE' },
  { locale: 'ae-en', lang: 'en', defaultCountry: 'AE' },
  { locale: 'ue-en', lang: 'en', defaultCountry: 'AT' },
  { locale: 'ue-en', lang: 'en', defaultCountry: 'BE' },
  { locale: 'ue-en', lang: 'en', defaultCountry: 'DK' },
  { locale: 'ue-en', lang: 'en', defaultCountry: 'FI' },
  { locale: 'ue-en', lang: 'en', defaultCountry: 'IE' },
  { locale: 'ue-en', lang: 'en', defaultCountry: 'LU' },
  { locale: 'ue-en', lang: 'en', defaultCountry: 'MT' },
  { locale: 'ue-en', lang: 'en', defaultCountry: 'NL' },
  { locale: 'ue-en', lang: 'en', defaultCountry: 'PL' },
  { locale: 'ue-en', lang: 'en', defaultCountry: 'PT' },
  { locale: 'ue-en', lang: 'en', defaultCountry: 'ES' },
  { locale: 'ue-en', lang: 'en', defaultCountry: 'SE' },
  { locale: 'ue-en', lang: 'en', defaultCountry: 'FR' },
  { locale: 'ue-en', lang: 'en', defaultCountry: 'MC' },
  { locale: 'kr-en', lang: 'en', defaultCountry: 'KR' },
  { locale: 'cn-en', lang: 'en', defaultCountry: 'CN' },
  { locale: 'cn-en', lang: 'en', defaultCountry: 'TW' },
  { locale: 'de-en', lang: 'en', defaultCountry: 'DE' },
  { locale: 'de-it', lang: 'it', defaultCountry: 'DE' },
];
