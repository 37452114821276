import { Context } from '@nuxt/types';
import handleMarketsAndLocales from '~/helpers/geminiConfig/handleMarketsAndLocales';

export default ({ app, route }: Context) =>
  app.$vsf.$gemini.client.interceptors.request.use((request) => {
    const { updateMarketAndLocaleCookies } = handleMarketsAndLocales();
    if (process.client) {
      updateMarketAndLocaleCookies(app, route);
    }
    return request;
  });
