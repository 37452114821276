<template>
  <transition :name="transitionName">
    <div
      v-show="visible"
      class="sf-mega-menu custom-mega-menu"
      :class="[{ 'is-active': getActive }, { 'is-absolute': isAbsolute }]"
      :style="megaMenuCustomStyle()"
    >
      <div class="custom-mega-menu__container">
        <SfBar
          v-if="getActive"
          :title="getTitle"
          :back="true"
          class="sf-mega-menu__bar"
          @click:back="back"
        >
          <template #title>
            <nuxt-link
              class="custom-mega-menu__bar-title"
              :to="fixUrlForCurrentLocale(getLink)"
            >
              {{ getTitle }}
            </nuxt-link>
          </template>
        </SfBar>
        <div class="sf-mega-menu__content">
          <div class="sf-mega-menu__menu">
            <slot />
          </div>
        </div>
        <div v-if="!getActive" class="custom-mega-menu__container__footer">
          <div
            class="custom-mega-menu__container__footer__language"
            @click="
              toggleCountryModal();
              openMenuLink();
            "
          >
            <div class="icon">
              <component :is="'LanguageIcon'" />
            </div>
            {{ $t('Country and language') }}:
            <span class="lang">{{ marketDetails }} </span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';
import { SfBar, SfLink } from '@storefront-ui/vue';
import { useI18n } from '~/helpers/hooks/usei18n';
import { useUiState, useWindow } from '~/composables';
import { defineComponent, computed } from '@nuxtjs/composition-api';
import CustomMegaMenuColumn from './CustomMegaMenuColumn.vue';
import { LanguageIcon } from '~/components/General/icons';
import { useCurrentLocale } from '~/composables/useCurrentLocale';

Vue.component('CustomMegaMenuColumn', CustomMegaMenuColumn);

export default defineComponent({
  name: 'CustomMegaMenu',
  components: {
    SfBar,
    SfLink,
    LanguageIcon,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isAbsolute: {
      type: Boolean,
      default: false,
    },
    transitionName: {
      type: String,
      default: 'sf-fade',
    },
  },
  setup() {
    const { toggleCountryModal, toggleMobileMenu } = useUiState();
    const { locale } = useI18n();
    const VSF_COUNTRY_SELECTED = 'vsf-country-selected';

    const { handleHtmlClass } = useWindow();

    const openMenuLink = () => {
      handleHtmlClass('removeClass', 'open_menu_html');
      toggleMobileMenu();
    };

    const marketDetails = computed(() => {
      const storedCountry = localStorage.getItem(VSF_COUNTRY_SELECTED);
      let parsedStoredCountryLabel;
      if (storedCountry) {
        const parsedStoredCountryData = JSON.parse(storedCountry);
        parsedStoredCountryLabel = parsedStoredCountryData?.label;
      }
      return locale.includes('en')
        ? parsedStoredCountryLabel
          ? `${parsedStoredCountryLabel} - English`
          : `${locale.toUpperCase()} - English`
        : parsedStoredCountryLabel
        ? `${parsedStoredCountryLabel} - Italiano`
        : `IT - Italiano`;
    });

    const { fixUrlForCurrentLocale } = useCurrentLocale();

    return {
      toggleCountryModal,
      marketDetails,
      openMenuLink,
      fixUrlForCurrentLocale,
    };
  },
  data() {
    return {
      active: [],
      link: [],
    };
  },
  provide() {
    const customMegaMenu = {};
    Object.defineProperty(customMegaMenu, 'active', {
      get: () => this.active,
    });
    Object.defineProperty(customMegaMenu, 'changeActive', {
      value: this.changeActive,
    });
    return { customMegaMenu };
  },
  computed: {
    getTitle() {
      return this.active?.length ? this.active[this.active.length - 1] : '';
    },
    getLink() {
      return this.link?.length ? this.link[this.link.length - 1] : '';
    },
    getActive() {
      return this.active?.includes(this.getTitle);
    },
  },
  methods: {
    changeActive(payload, link) {
      this.active.push(payload);
      this.link.push(link);
      this.$emit('change', payload);
    },
    back() {
      if (!this.active) {
        this.$emit('close');
      }
      this.active.pop();
      this.link.pop();
    },
    megaMenuCustomStyle() {
      const header = document.querySelectorAll(
        '.sf-header-custom_container'
      )[0];
      const headerHeight = header
        ? Number.parseInt(header.offsetHeight, 10)
        : 0;
      const headerFromTop = header
        ? Number.parseInt(header.getBoundingClientRect().top, 10)
        : 0;
      return {
        '--mega-menu-content-transform': `translateX(-${
          this.active?.length || '0'
        }00%)`,
        'min-height': `calc(100vh - ${headerHeight + headerFromTop}px)`,
      };
    },
  },
});
</script>

<style lang="scss">
@import '@storefront-ui/shared/styles/components/organisms/SfMegaMenu.scss';
</style>

<style lang="scss" scoped>
.custom-mega-menu {
  padding: 0;
  height: auto !important;
  &__bar-title {
    @include desktop-h4;
    text-transform: uppercase;
    text-decoration: none;
  }
  &__container {
    overflow-y: scroll;
    overflow-x: hidden;
    //padding-top: 1.875rem;
    &__footer {
      padding-top: 1.875rem;
      padding-left: 0.625rem;
      padding-right: 0.625rem;
      padding-bottom: 8rem;
      margin-left: 0.625rem;
      margin-right: 0.625rem;
      border-top: 1px solid var(--c-grey);
      &__language {
        display: flex;
        gap: 0.625rem;
        min-width: 1.0625rem;
        @include link-small;
        text-transform: uppercase;
        color: var(--c-black);
        font-weight: 500;
        .icon {
          width: 17px;
          height: 18px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.custom-mega-menu {
  &__container {
    .sf-mega-menu__bar > div:nth-child(3) {
      display: none;
    }
  }
}
</style>
