<template>
  <ValidationProvider
    v-if="validateRule"
    :rules="validateWithRequired"
    v-slot="{ errors, changed }"
  >
    <SfCheckbox
      :name="name"
      :required="validateRule && validateRule.includes('required')"
      :error-message="errorMessage"
      hint-message=""
      :disabled="disabled"
      :selected="selected"
      :valid="changed ? selected === true : errors.length === 0"
      @change="handleChange"
      class="custom-checkbox"
    >
      <template #label>
        <span class="custom-checkbox__label" :class="{ disabled: disabled }">
          {{ $t(label) }}
          <SfLink
            v-if="name === 'privacy-policy-checkbox'"
            link="/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </SfLink>
        </span>
      </template>
      <template #show-error-message>
        <div
          class="custom-checkbox__error-message"
          v-if="errors.length > 0 || (changed && !selected)"
        >
          <span class="custom-checkbox__error-text">
            {{ $t(errorMessage) }}
          </span>
        </div>
        <div v-else />
      </template>
      <template #checkmark>
        <CustomCheckmark :selected="selected" :disabled="disabled" />
      </template>
    </SfCheckbox>
  </ValidationProvider>
  <SfCheckbox
    v-else
    :name="name"
    hint-message=""
    :disabled="disabled"
    :selected="selected"
    @change="handleChange"
    class="custom-checkbox"
  >
    <template #label>
      <span class="custom-checkbox__label" :class="{ disabled: disabled }">
        {{ $t(label) }}
        <SfLink
          v-if="name === 'privacy-policy-checkbox'"
          :link="localePath('/privacy-policy')"
        >
          Privacy Policy
        </SfLink>
      </span>
    </template>
    <template #checkmark>
      <CustomCheckmark :selected="selected" :disabled="disabled" />
    </template>
  </SfCheckbox>
</template>
<script>
import { defineComponent, computed, ref } from '@nuxtjs/composition-api';
import CustomCheckmark from './CustomCheckmark.vue';
import { SfCheckbox, SfLink } from '@storefront-ui/vue';
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { useI18n } from '~/helpers/hooks/usei18n';

export default defineComponent({
  name: 'CustomCheckbox',
  components: {
    CustomCheckmark,
    SfCheckbox,
    SfLink,
    ValidationProvider,
  },
  props: {
    label: {
      type: String,
      default: 'I have read and agree to the ',
    },
    name: {
      type: String,
      default: 'privacy-policy-checkbox',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: 'Required field',
    },
    validateRule: {
      type: String,
      default: '',
    },
    selectedValue: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const internalCheckState = ref(false);
    const selected = computed(
      () => props.selectedValue || internalCheckState.value
    );
    const trans = useI18n();

    extend('required', {
      ...required,
      message: trans.t('Required field'),
    });

    const validateWithRequired = computed(() => {
      if (props.validateRule.includes('required')) {
        const validationObject = {};
        props.validateRule.split('|').forEach((e) => {
          if (e === 'required') {
            validationObject[e] = { allowFalse: false };
          } else if (e === '') {
            validationObject[e] = { allowFalse: true };
          } else {
            validationObject[e] = true;
          }
        });
        return validationObject;
      }
      return props.validateRule;
    });

    const handleChange = (e) => {
      internalCheckState.value = e;
      emit('checkboxChange', e);
      emit('input', e);
    };

    return {
      selected,
      handleChange,
      validateWithRequired,
    };
  },
});
</script>
