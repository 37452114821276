import { useUser } from '@gemini-vsf/composables';

export const menuHelpers = () => {
  const { isAuthenticated } = useUser();

  const checkMarkets = (marketsString: string, currentMarket: string): boolean => {
    const marketsArray: string[] = marketsString.split(',');
    return marketsArray.some((market) => market?.trim()?.toLowerCase() === currentMarket?.trim()?.toLowerCase());
  };

  const filterMenuCallbackFn = (childLabel: string | undefined, currentMarket: string | undefined): boolean => {
    if (!childLabel) {
      return false;
    }
    const labelArray: string[] = childLabel.split('---');
    const marketsString: string = labelArray[labelArray[1]?.toLowerCase()?.includes('logged') ? 2 : 1];
    switch (labelArray?.length) {
      case 1: {
        return true;
      }
      case 2: {
        if (labelArray[1].toLowerCase()?.includes('logged')) {
          return isAuthenticated.value;
        }
        return checkMarkets(marketsString, currentMarket);
      }
      case 3: {
        return isAuthenticated.value && checkMarkets(marketsString, currentMarket);
      }
      default: {
        return false;
      }
    }
  };

  return {
    filterMenuCallbackFn,
  };
};
