<template>
  <div
    v-if="optionsArray"
    class="custom-select"
    :class="[fullWidth && 'custom-select-full-width']"
  >
    <ValidationProvider
      :rules="isRequired ? 'required' : ''"
      v-slot="{ errors }"
      class="custom-select__container"
      :class="isDisabled && 'custom-select__container__disabled'"
    >
      <span class="select-title"> {{ $t(selectTitle) }}</span>
      <SfSelect
        class="custom-select__select"
        :required="isRequired"
        :valid="isValid"
        :disabled="isDisabled"
        :placeholder="$t(placeholder)"
        @input="handleChange"
        :value="selectValue || (defaultValue && defaultValue.value)"
      >
        <SfSelectOption
          v-for="(option, index) in optionsArray"
          :value="option.value"
          :key="option.value + index"
        >
          {{ option.label }}
        </SfSelectOption>
      </SfSelect>
      <component
        v-if="selectIcon"
        class="custom-select__icon"
        :is="selectIcon"
      />
      <span v-if="errors[0]" class="custom-select__error">
        {{ $t(errors[0]) }}
      </span>
    </ValidationProvider>
  </div>
</template>
<script>
import { defineComponent, ref, computed, watch } from '@nuxtjs/composition-api';
import { DropdownIcon } from '../icons';
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { SfSelect } from '@storefront-ui/vue';
import { useI18n } from '~/helpers/hooks/usei18n';

export default defineComponent({
  name: 'CustomSelect',
  components: {
    ValidationProvider,
    SfSelect,
    DropdownIcon,
  },
  model: {
    prop: 'selectedValue',
  },
  props: {
    selectName: {
      type: String,
      required: true,
    },
    selectOptions: {
      type: Array,
      required: true,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    selectIcon: {
      type: [Boolean, String],
      default: 'DropdownIcon',
    },
    selectedValue: {
      type: [String, null],
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    selectTitle: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Select option',
    },
  },
  setup(props, { emit }) {
    const selectValue = ref(props.selectedValue);
    const defaultValue = ref();
    const trans = useI18n();
    extend('required', {
      ...required,
      message: trans.t('Required field'),
    });
    const optionsArray = computed(() =>
      props.selectOptions && props.selectOptions.length > 0
        ? props.selectOptions
            .map((e) => {
              // eslint-disable-next-line no-prototype-builtins
              if (!e.hasOwnProperty('label')) {
                e.label = e.value;
              }
              // eslint-disable-next-line no-prototype-builtins
              if (e.hasOwnProperty('default')) {
                defaultValue.value = e;
                return null;
              }
              return e;
            })
            .filter(Boolean)
        : []
    );

    const handleChange = (value) => {
      selectValue.value = value;
      emit('selectChange', value);
      emit('input', value);
    };

    watch(optionsArray, (newOptionsArray) => {
      if (newOptionsArray.length === 1) {
        selectValue.value = newOptionsArray[0].value.toString();
        handleChange(selectValue.value);
      }
    });

    watch(
      () => props.selectedValue,
      () => {
        selectValue.value = props.selectedValue;
        emit('input', props.selectedValue);
      }
    );

    return {
      optionsArray,
      selectValue,
      handleChange,
      defaultValue,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-select {
  width: var(--select-width);
  &-full-width {
    width: 100%;
  }
  &__container {
    position: relative;
    display: block;
    &__disabled {
      pointer-events: none;
      opacity: 0.5;
    }
    .select-title {
      padding-left: 1.25rem;
      @include input-label;
      text-transform: uppercase;
    }
    .sf-select {
      border: 0.0625rem groove var(--c-grey);
      padding: 1rem 1.25rem;
    }
  }
  &__error {
    padding: var(--select-error-padding);
    color: var(--c-red-error);
  }
  &__icon {
    position: absolute;
    right: 1.25rem;
    top: 2.8rem;
  }
}
</style>

<style lang="scss">
.custom-select__select {
  display: flex;
  align-items: center;
  .sf-select {
    &__dropdown {
      margin: 0;
      @include paragraph-regular;
      text-transform: uppercase;
      appearance: none;
      display: flex;
      align-items: center;
      &:focus-visible {
        outline: none;
      }
      &:focus ~ .sf-select__label {
        color: var(--c-dark-grey);
      }
    }
    &__label {
      @include paragraph-regular;
    }
    &__error-message {
      display: none;
    }
  }
  &.is-selected {
    .sf-select__dropdown {
      color: var(--c-black);
    }
    .sf-select__label {
      color: var(--c-dark-grey);
    }
  }
}
.custom-select-full-width {
  .custom-select__select.sf-select {
    width: 100%;
  }
}
</style>
