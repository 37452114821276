var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-popup"},[_c('SfModal',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal'),expression:"'login-modal'"}],staticClass:"modal",attrs:{"visible":_vm.isLoginModalOpen},on:{"close":_vm.closeModal}},[_c('transition',{attrs:{"name":"sf-fade","mode":"out-in"}},[(_vm.isLogin)?_c('div',[_c('div',{staticClass:"login-top"},[_c('h2',[_vm._v("\n            "+_vm._s(_vm.$t('CUSTOMER LOGIN'))+"\n          ")]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t('IF YOU HAVE AN ACCOUNT, SIGN IN WITH YOUR EMAIL ADDRESS.'))+"\n          ")])]),_vm._v(" "),_c('ValidationObserver',{key:"log-in",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onLoginSubmit)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-email'),expression:"'login-modal-email'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","label":_vm.$t('Your email')},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-password'),expression:"'login-modal-password'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"password","label":_vm.$t('Password'),"type":"password","has-show-password":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_vm._v(" "),(_vm.error.login)?_c('div',[_vm._v("\n              "+_vm._s(_vm.$t(_vm.error.login))+"\n            ")]):_vm._e(),_vm._v(" "),_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-submit'),expression:"'login-modal-submit'"}],staticClass:"sf-button--full-width form__button",attrs:{"type":"submit","disabled":_vm.loading}},[_c('SfLoader',{class:{ loader: _vm.loading },attrs:{"loading":_vm.loading}},[_c('div',[_vm._v(_vm._s(_vm.$t('Login')))])])],1)],1)]}}],null,false,1558096153)}),_vm._v(" "),_c('div',{staticClass:"forgotten-links",on:{"click":function($event){return _vm.setIsForgottenValue(true)}}},[_vm._v("\n          "+_vm._s(_vm.$t('Forgotten password?'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"bottom"},[_c('p',{staticClass:"bottom__paragraph"},[_vm._v("\n            "+_vm._s(_vm.$t('No account'))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"register-links",on:{"click":function($event){return _vm.setIsLoginValue(false)}}},[_vm._v("\n            "+_vm._s(_vm.$t('Register here'))+"\n          ")])])],1):(_vm.isForgotten)?_c('div',[_c('div',{staticClass:"login-top"},[_c('h2',[_vm._v("\n            "+_vm._s(_vm.$t('Forgot Password'))+"\n          ")]),_vm._v(" "),_c('br')]),_vm._v(" "),_c('ValidationObserver',{key:"log-in",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onForgotSubmit)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('forgot-modal-email'),expression:"'forgot-modal-email'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","label":_vm.$t('Forgot Password Modal Email')},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})]}}],null,true)}),_vm._v(" "),_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('forgot-modal-submit'),expression:"'forgot-modal-submit'"}],staticClass:"sf-button--full-width form__button",attrs:{"type":"submit","disabled":_vm.forgotPasswordLoading}},[_c('SfLoader',{class:{ loader: _vm.forgotPasswordLoading },attrs:{"loading":_vm.forgotPasswordLoading}},[_c('div',[_vm._v(_vm._s(_vm.$t('Reset Password')))])])],1)],1)]}}])}),_vm._v(" "),_c('p',{staticClass:"thank-you__label"},[_vm._v("\n          "+_vm._s(_vm.$t('You will receive an email with a link to reset your password'))+"\n        ")]),_vm._v(" "),(_vm.forgotPasswordError.request)?_c('p',{staticClass:"thank-you__error"},[_vm._v("\n          "+_vm._s(_vm.$t(
              'It was not possible to request a new password, please check the entered email address.'
            ))+"\n        ")]):_vm._e()],1):(_vm.isThankYouAfterForgotten)?_c('div',{staticClass:"thank-you"},[_c('i18n',{staticClass:"thank-you__p",attrs:{"tag":"p","path":"forgotPasswordConfirmation"}},[_c('span',{staticClass:"thank-you__paragraph--bold"},[_vm._v(_vm._s(_vm.userEmail))])])],1):_c('div',{staticClass:"form"},[_c('div',{staticClass:"login-top"},[_c('h2',[_vm._v("\n            "+_vm._s(_vm.$t('CREATE A NEW ACCOUNT'))+"\n          ")]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t(
                'YOU WILL BE ABLE TO ACCESS EXCLUSIVE PROMOS, MANAGE YOUR ORDERS, SAVE YOUR WISHLIST AND MUCH MORE.'
              ))+"\n          ")]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t('ALREADY REGISTERED?'))+"\n            "),_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-login-to-your-account'),expression:"'login-modal-login-to-your-account'"}],staticClass:"sf-button--text",on:{"click":function($event){return _vm.setIsLoginValue(true)}}},[_vm._v("\n              "+_vm._s(_vm.$t('LOG IN HERE'))+"\n            ")])],1)]),_vm._v(" "),_c('ValidationObserver',{key:"sign-up",scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var handleSubmit = ref.handleSubmit;
              var invalid = ref.invalid;
return [_c('form',{staticClass:"form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onRegisterSubmit)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","label":_vm.$t('Your email')},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"first-name","label":_vm.$t('First Name')},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-lastName'),expression:"'login-modal-lastName'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"name":"last-name","label":_vm.$t('Last Name')},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"password","label":_vm.$t('Password'),"type":"password","has-show-password":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('SfInput',{staticClass:"form__element repassword",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"re-password","label":_vm.$t('Re-Password'),"type":"password","has-show-password":""},model:{value:(_vm.form.repassword),callback:function ($$v) {_vm.$set(_vm.form, "repassword", $$v)},expression:"form.repassword"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('div',{staticClass:"sf-checkbox-row"},[_c('SfCheckbox',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-create-account'),expression:"'login-modal-create-account'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"create-account"},model:{value:(_vm.createAccount),callback:function ($$v) {_vm.createAccount=$$v},expression:"createAccount"}}),_vm._v("\n                "+_vm._s(_vm.$t('I HAVE READ AND UNDERSTOOD THE INFORMATION ON'))+"\n                "),_c('SfLink',{attrs:{"link":_vm.fixUrlForCurrentLocale('/privacy-policy')}},[_vm._v("\n                  "+_vm._s(_vm.$t('Privacy'))+"\n                ")]),_vm._v("\n                "+_vm._s(_vm.$t('AND THE POLITICS ABOUT IT'))+"\n                "),_c('SfLink',{attrs:{"link":_vm.fixUrlForCurrentLocale('/cookie-policy')}},[_vm._v("\n                  "+_vm._s(_vm.$t('COOKIE'))+"\n                ")])],1)]}}],null,true)}),_vm._v(" "),_c('SfCheckbox',{directives:[{name:"e2e",rawName:"v-e2e",value:('sign-up-newsletter'),expression:"'sign-up-newsletter'"}],staticClass:"form__element",attrs:{"label":_vm.$t('Sign Up for Newsletter'),"name":"signupNewsletter"},model:{value:(_vm.isSubscribed),callback:function ($$v) {_vm.isSubscribed=$$v},expression:"isSubscribed"}}),_vm._v(" "),(_vm.error.register)?_c('div',[_vm._v("\n              "+_vm._s(_vm.$t(_vm.error.register))+"\n            ")]):_vm._e(),_vm._v(" "),_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-submit'),expression:"'login-modal-submit'"}],staticClass:"sf-button--full-width form__button register-btn",attrs:{"type":"submit","disabled":_vm.loading || !_vm.createAccount || invalid}},[_c('SfLoader',{class:{ loader: _vm.loading },attrs:{"loading":_vm.loading}},[_c('div',[_vm._v(_vm._s(_vm.$t('Create an account')))])])],1)],1)]}}])})],1)])],1),_vm._v(" "),(_vm.hasLoggedIn)?_c('CustomerGtm',{attrs:{"is-logged":_vm.isLoggedIn,"customer-data":{ user: _vm.user }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }