/* eslint-disable no-console */
import { Route } from 'vue-router';
import { getCanonicalTargetPathFromRequestPath } from './getCanonicalTargetPathFromRequestPath';
import { getUrlRewritesFromTargetPath } from './getUrlRewritesFromTargetPath';
import { getCmsPage } from '../cms/cmsData';

const restoreQueryParameters = (queryParams: Record<string, string | string[]>): string => {
  try {
    const queryParamsArray = Object.entries(queryParams).flatMap(([key, value]) =>
      Array.isArray(value) ? value.map((v) => [key, v]) : [[key, value]]
    );
    return new URLSearchParams(queryParamsArray).toString();
  } catch (error) {
    console.error('helpers/urlManager/getEnglishUrlFromItalianUrl.ts ~ restoreQueryParameters ~ error:', error);
  }
  return '';
};

/**
 * Converts an Italian URL to its corresponding English URL.
 * If something goes wrong, defaults to the BH English homepage.
 *
 * @param {Route} route - The route object containing the Italian URL and query parameters.
 * @returns {Promise<string>} - The English URL with the same query parameters.
 */
export const getEnglishUrlFromItalianUrl = async (route: Route): Promise<string> => {
  try {
    const queryParams = route.query;
    const restoredQueryParametersString = restoreQueryParameters(queryParams);

    const isHomePage = route.path === '/bh-it/' || route.path === '/bh-it';
    if (isHomePage) {
      return restoredQueryParametersString ? `?${restoredQueryParametersString}` : '';
    }
    const pathWithoutLocale = route.path.replace('/bh-it/', '');

    const cmsPageUrl = getCmsPage(pathWithoutLocale);
    if (cmsPageUrl) {
      return restoredQueryParametersString ? `${cmsPageUrl}?${restoredQueryParametersString}` : cmsPageUrl;
    }

    const canonicalTargetPath = await getCanonicalTargetPathFromRequestPath(pathWithoutLocale);
    if (!canonicalTargetPath) {
      console.error(`No canonical target path found for request path: ${pathWithoutLocale}`);
      return '';
    }

    // Attempt to get URL rewrites based on the canonical target path
    const urlRewrites = await getUrlRewritesFromTargetPath(canonicalTargetPath);
    if (!urlRewrites || !Array.isArray(urlRewrites) || urlRewrites.length === 0) {
      console.error(`No URL rewrites found for target path: ${canonicalTargetPath}`);
      return '';
    }

    // Find the English request path from the URL rewrites
    const englishRequestPath = urlRewrites.find((urlRewrite) => urlRewrite.context === 'en_US')?.requestPath;
    if (!englishRequestPath) {
      console.error(`No English request path found for target path: ${canonicalTargetPath}`);
      return '';
    }

    // Re-add the query parameters to the English request path
    return restoredQueryParametersString ? `${englishRequestPath}?${restoredQueryParametersString}` : englishRequestPath;
  } catch (error) {
    console.error('helpers/urlManager/getEnglishUrlFromItalianUrl.ts ~ error:', error);
  }
  return '';
};
