// General
export { default as BlackLogoSvg } from '~/static/logoB.svg';
export { default as WhiteLogoSvg } from '~/static/logoW.svg';
export { default as BlackBagIcon } from '~/static/icons/general/bagB.svg';
export { default as WhiteBagIcon } from '~/static/icons/general/bagW.svg';
export { default as BlackLangSelectorIcon } from '~/static/icons/general/langSelectorB.svg';
export { default as WhiteLangSelectorIcon } from '~/static/icons/general/langSelectorW.svg';
export { default as BlackSearchIcon } from '~/static/icons/general/searchButtonB.svg';
export { default as WhiteSearchIcon } from '~/static/icons/general/searchButtonW.svg';
export { default as BlackUserIcon } from '~/static/icons/general/userIconB.svg';
export { default as WhiteUserIcon } from '~/static/icons/general/userIconW.svg';
export { default as BlackWishlistIcon } from '~/static/icons/general/wishlistHeartB.svg';
export { default as WhiteWishlistIcon } from '~/static/icons/general/wishlistHeartW.svg';
export { default as SeparatorIcon } from '~/static/icons/general/separator.svg';
export { default as CrossIcon } from '~/static/icons/general/crossIcon.svg';
export { default as HideIcon } from '~/static/icons/general/hideIcon.svg';
export { default as FailedCrossIcon } from '~/static/icons/general/failedCrossIcon.svg';
export { default as LeafIcon } from '~/static/icons/general/leafIcon.svg';
export { default as StarIcon } from '~/static/icons/general/starIcon.svg';
export { default as SquareIcon } from '~/static/icons/general/squareIcon.svg';
export { default as SuccessTickIcon } from '~/static/icons/general/successTickIcon.svg';
export { default as BlackHamburger } from '~/static/icons/general/hamburgerB.svg';
export { default as WhiteHamburger } from '~/static/icons/general/hamburgerW.svg';
export { default as DropdownIcon } from '~/static/icons/general/dropdownIcon.svg';
export { default as fullHeartIcon } from '~/static/icons/general/fullHeart.svg';
export { default as emptyHeartIcon } from '~/static/icons/general/emptyHeart.svg';
export { default as fullBulletIcon } from '~/static/icons/general/fullBullet.svg';
export { default as emptyBulletIcon } from '~/static/icons/general/emptyBullet.svg';
export { default as arrowLeftIcon } from '~/static/icons/general/arrowLeft.svg';
export { default as arrowRightIcon } from '~/static/icons/general/arrowRight.svg';
export { default as arrowRightBrand } from '~/static/icons/general/arrowRightBrand.svg';
export { default as ProductCardLeftIcon } from '~/static/icons/general/productCardArrowLeft.svg';
export { default as ProductCardRightIcon } from '~/static/icons/general/productCardArrowRight.svg';
// Socials
export { default as FacebookIcon } from '~/static/icons/socials/facebook.svg';
export { default as FacebookHoverIcon } from '~/static/icons/socials/facebookH.svg';
export { default as InstagramIcon } from '~/static/icons/socials/instagram.svg';
export { default as InstagramHoverIcon } from '~/static/icons/socials/instagramH.svg';
export { default as TumblrIcon } from '~/static/icons/socials/tumblr.svg';
export { default as TumblrHoverIcon } from '~/static/icons/socials/tumblrH.svg';
// Payments
export { default as AmericanExpressIcon } from '~/static/assets/AmericanExpress-logo.svg';
export { default as KlarnaIcon } from '~/static/assets/Klarna-logo.svg';
export { default as MastercardIcon } from '~/static/assets/Mastercard-logo.svg';
export { default as PaypalIcon } from '~/static/assets/Paypal-logo.svg';
export { default as GroupedPaymentsLogo } from '~/static/assets/groupedPayments.svg';
export { default as ScalapayProductDetailPage } from '~/static/icons/payment_methods/scalapay_pdp.svg';

// Mobile Menu
export { default as MyAccountIcon } from '~/static/icons/general/mobileMenu/account.svg';
export { default as BagIcon } from '~/static/icons/general/mobileMenu/bag.svg';
export { default as HearthIcon } from '~/static/icons/general/mobileMenu/hearth.svg';
export { default as LanguageIcon } from '~/static/icons/general/mobileMenu/language.svg';
export { default as PhoneIcon } from '~/static/icons/general/mobileMenu/phone.svg';
