<template>
  <div>
    <IconSprite />
    <LazyHydrate when-visible>
      <Notification />
    </LazyHydrate>
    <CheckoutHeader />

    <div id="layout">
      <nuxt :key="route.fullPath" />
    </div>
  </div>
</template>

<script>
import {
  useRoute,
  defineComponent,
  ref,
  onMounted,
} from '@nuxtjs/composition-api';
import { useCart, useUser } from '@gemini-vsf/composables';
import Notification from '~/components/Notification.vue';
import CheckoutHeader from '~/components/Checkout/CheckoutHeader.vue';
import LazyHydrate from 'vue-lazy-hydration';
import IconSprite from '~/components/General/IconSprite.vue';

export default defineComponent({
  name: 'CheckoutLayout',

  components: {
    Notification,
    CheckoutHeader,
    LazyHydrate,
    IconSprite,
  },
  setup() {
    const route = useRoute();
    const { isAuthenticated, user, load: loadUser } = useUser();
    const { load: loadCart, cart } = useCart();
    const flag = ref(false);

    onMounted(async () => {
      await loadUser();
      if (!cart.value && typeof window !== 'undefined') {
        await loadCart({
          customQuery: isAuthenticated.value
            ? {
                customerCart: 'customerCartCustom',
              }
            : {
                cart: 'cartCustom',
              },
        });
      }
    });

    return {
      route,
      isAuthenticated,
      flag,
      user,
    };
  },
  data() {
    const checkSticky = false;

    return {
      checkSticky,
    };
  },
  mounted() {
    this.scroll();
  },
  methods: {
    scroll() {
      window.addEventListener('scroll', () => {
        const st = window.scrollY;
        this.checkSticky = st >= Number.parseInt('30', 10);
      });
    },
  },
});
</script>

<style lang="scss">
@import '~@storefront-ui/vue/styles';

#layout {
  box-sizing: border-box;
  max-width: 90rem;
  margin: 0 auto;
}

.no-scroll {
  overflow: hidden;
  height: 100vh;
}

// Reset CSS
html {
  width: auto;
  @media (max-width: var(--tablet-max)) {
    overflow-x: hidden;
  }
}

*:focus,
*:focus-visible {
  outline: none;
}

body {
  overflow-x: hidden;
  color: var(--c-text);
  font-size: var(--font-size--base);
  font-family: var(--font-family--primary);
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: var(--c-link);

  &:hover {
    color: var(--c-link-hover);
  }
}

h1 {
  font-family: var(--font-family--secondary);
  font-size: var(--h1-font-size);
  line-height: 1.6;
  margin: 0;
}

h2 {
  font-family: var(--font-family--secondary);
  font-size: var(--h2-font-size);
  line-height: 1.6;
  margin: 0;
}

h3 {
  font-family: var(--font-family--secondary);
  font-size: var(--h3-font-size);
  line-height: 1.6;
  margin: 0;
}

h4 {
  font-family: var(--font-family--secondary);
  font-size: var(--h4-font-size);
  line-height: 1.6;
  margin: 0;
}

.sf-sidebar {
  .sf-overlay {
    z-index: 4;
  }
  &__aside {
    z-index: 5;
  }
}
</style>
