import { sharedRef } from '@vue-storefront/core';
import { useContent, contentGetters } from '~/composables';

const useConfig = (key = 'site-config-data') => {
  const siteConfigData = sharedRef({}, key);
  const { blocks, loadBlocks } = useContent();

  const loadConfig = async () => {
    try {
      await loadBlocks({
        identifiers: ['config'],
      });
      const blockData = await contentGetters.getCmsBlockContent(blocks.value);
      siteConfigData.value = {
        ...blockData,
        star: JSON.parse(blockData?.config?.card_icons?.star || '{}'),
        starAdditionalPromos: blockData?.config?.card_icons?.data?.star?.filter((element) => element.active),
        square: JSON.parse(blockData?.config?.card_icons?.square || '{}'),
        leaf: JSON.parse(blockData?.config?.card_icons?.leaf || '{}'),
      };
    } catch (error) {
      console.error(error);
      console.warn('Could not get Config block content');
    }
  };
  return {
    loadConfig,
    siteConfigData,
  };
};

export default useConfig;
