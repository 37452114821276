import { ref, Ref, computed, ComputedRef, useFetch, useContext } from '@nuxtjs/composition-api';
import { getItem, mergeItem } from '~/helpers/asyncLocalStorage';
import { useCart, useUiNotification } from '~/composables';

interface InvoiceRequestObject {
  type: string;
  company: string;
  vat_number: string;
  tax_id_code: string;
  sdi_code: string;
}

const useNotesOnCart = () => {
  const { load: loadCart, cart, addNoteOnCart } = useCart();
  const { send: sendNotification } = useUiNotification();
  const {
    app: { i18n: trans },
  } = useContext();

  const emptyInvoiceRequestData = { type: '', company: '', vat_number: '', tax_id_code: '', sdi_code: '' };

  const loadingGiftWrapping: Ref<boolean> = ref(false);
  const loadingShippingNotes: Ref<boolean> = ref(false);
  const loadingInvoiceRequest: Ref<boolean> = ref(false);
  const loading: ComputedRef<boolean> = computed<boolean>(
    () => loadingGiftWrapping.value || loadingShippingNotes.value || loadingInvoiceRequest.value
  );

  const getNotesFromCart = async () => {
    if (cart.value?.notes?.length > 0) {
      return cart.value.notes;
    }
    const localStorage = await getItem('checkout');
    if (localStorage?.['orderNotes']) {
      return localStorage.orderNotes;
    }
    return '';
  };

  const getXmlNotes = (message: string, shippingNotesMessage: string, data: InvoiceRequestObject) => {
    const messageToXml = message ? `<GIFT_WRAPPING><MESSAGE><![CDATA[${message}]]></MESSAGE></GIFT_WRAPPING>` : '';
    const shippingNotesToXml = shippingNotesMessage ? `<SHIPPING_NOTES><![CDATA[${shippingNotesMessage}]]></SHIPPING_NOTES>` : '';
    const typeToXml = data?.type ? `<TYPE><![CDATA[${data.type}]]></TYPE>` : '';
    const companyToXml = data?.company ? `<COMPANY><![CDATA[${data.company}]]></COMPANY>` : '';
    const vatNumberToXml = data?.vat_number ? `<VAT_NUMBER><![CDATA[${data.vat_number}]]></VAT_NUMBER>` : '';
    const taxIdCodeToXml = data?.tax_id_code ? `<TAX_ID_CODE><![CDATA[${data.tax_id_code}]]></TAX_ID_CODE>` : '';
    const sdiCodeToXml = data?.sdi_code ? `<SDI_CODE><![CDATA[${data.sdi_code}]]></SDI_CODE>` : '';
    const dataToXml =
      typeToXml || companyToXml || vatNumberToXml || taxIdCodeToXml || sdiCodeToXml
        ? `<INVOICE_DATA>${typeToXml}${companyToXml}${vatNumberToXml}${taxIdCodeToXml}${sdiCodeToXml}</INVOICE_DATA>`
        : '';
    return messageToXml || dataToXml || shippingNotesToXml ? `<DATA>${messageToXml}${dataToXml}${shippingNotesToXml}</DATA>` : ' ';
  };

  const getGiftWrappingMessageFromXmlNotes = (xmlNotes: string) => {
    const domParser = new DOMParser();
    const doc = domParser.parseFromString(xmlNotes, 'text/xml');
    return doc?.querySelector('MESSAGE')?.textContent ?? '';
  };

  const getShippingNotesFromXmlNotes = (xmlNotes: string) => {
    const domParser = new DOMParser();
    const doc = domParser.parseFromString(xmlNotes, 'text/xml');
    return doc?.querySelector('SHIPPING_NOTES')?.textContent ?? '';
  };

  const getInvoiceRequestDataFromXmlNotes = (xmlNotes: string) => {
    const domParser = new DOMParser();
    const doc = domParser.parseFromString(xmlNotes, 'text/xml');
    return doc?.querySelector('INVOICE_DATA')
      ? {
          type: doc?.querySelector('TYPE')?.textContent ?? '',
          company: doc?.querySelector('COMPANY')?.textContent ?? '',
          vat_number: doc?.querySelector('VAT_NUMBER')?.textContent ?? '',
          tax_id_code: doc?.querySelector('TAX_ID_CODE')?.textContent ?? '',
          sdi_code: doc?.querySelector('SDI_CODE')?.textContent ?? '',
        }
      : null;
  };

  const getGiftWrappingMessage = async () => {
    const notesFromCart = await getNotesFromCart();
    return notesFromCart ? getGiftWrappingMessageFromXmlNotes(notesFromCart) : '';
  };

  const getShippingNotes = async () => {
    const notesFromCart = await getNotesFromCart();
    return notesFromCart ? getShippingNotesFromXmlNotes(notesFromCart) : '';
  };

  const getInvoiceRequestData = async () => {
    const notesFromCart = await getNotesFromCart();
    return notesFromCart ? getInvoiceRequestDataFromXmlNotes(notesFromCart) : null;
  };

  const saveNotes = async (xmlNotes: string, notificationMessage: string) => {
    await addNoteOnCart({
      note: xmlNotes,
    });
    await mergeItem('checkout', { orderNotes: xmlNotes });
    if (xmlNotes && xmlNotes !== ' ' && notificationMessage) {
      sendNotification({
        id: Symbol('save_notes_success'),
        message: notificationMessage,
        type: 'success',
        persist: false,
        icon: '',
      });
    }
  };

  const saveGiftWrappingMessage = async (message = '') => {
    loadingGiftWrapping.value = true;
    await saveNotes(
      getXmlNotes(message, await getShippingNotes(), (await getInvoiceRequestData()) ?? emptyInvoiceRequestData),
      message ? `${trans.t('Gift wrapping saved successfully.')}` : ''
    );
    loadingGiftWrapping.value = false;
  };

  const saveShippingNotes = async (shippingNotesMessage = '') => {
    loadingShippingNotes.value = true;
    await saveNotes(
      getXmlNotes(await getGiftWrappingMessage(), shippingNotesMessage, (await getInvoiceRequestData()) ?? emptyInvoiceRequestData),
      ''
    );
    loadingShippingNotes.value = false;
  };

  const saveInvoiceRequestData = async (data = emptyInvoiceRequestData) => {
    loadingInvoiceRequest.value = true;
    await saveNotes(
      getXmlNotes(await getGiftWrappingMessage(), await getShippingNotes(), data),
      data === emptyInvoiceRequestData ? '' : `${trans.t('Invoice request saved successfully.')}`
    );
    loadingInvoiceRequest.value = false;
  };

  useFetch(async () => {
    await loadCart();
  });

  return {
    loading,
    getInvoiceRequestData,
    saveInvoiceRequestData,
    getGiftWrappingMessage,
    saveGiftWrappingMessage,
    getShippingNotes,
    saveShippingNotes,
  };
};

export default useNotesOnCart;
